import React, { useState, useEffect } from 'react';
import "./ContentDeliveryArea.css";
import service, { Assessment_Delivery, DMS, DMS_URL, Frontend_UI, URI } from '../../../../services/service';
import axios from 'axios';
import CryptoJS from "crypto-js";
import ProgressBar from '@ramonak/react-progress-bar';
import { Circles } from 'react-loader-spinner';
import ViewPdf from '../../../instructor/ViewPdf';
import ReactPlayer from 'react-player';
import MediaContentView from '../../components/MediaContentView';
import { AssignmentDelivery } from '../Assignment/AssignmentDelivery';
// import { AssignmentDelivery } from '../Assignment/AssignmentDelivery';
import { saveAs } from 'file-saver';
import swal from "sweetalert";
import CourseFeedbackList from '../../../account/CourseFeedbackList';

export const QuizModule = ({ isRedirectLoading, isQuizRefreshLoading, refreshQuizProgress, url, tenantId }) => {
  return (
    <div className="main-container">
      <div className="blur-container">
        <div className="button-wrapper">
          <button className="download-button" onClick={() => { window.location.replace(URI.SERVER?._ASSESSMENT_DELIVERY + "/assessment/delivery/dashboard/conductor/quiz/ready/" + url + `/${tenantId}` ) }}>
            {isRedirectLoading ? (
              <>
                <i className="fa fa-spinner fa-spin mr-1 fa-lg" aria-hidden="true"></i>
                <span className="pl-1">Redirecting...</span>
              </>
            ) : (
              <>
                <i className="fa fa-external-link fa-lg" aria-hidden="true"></i>
                <span className="pl-1">Attempt Quiz</span>
              </>
            )}
          </button>
          <button className="download-button" onClick={() => { refreshQuizProgress(URI.SERVER?._FRONTEND_UI + `/delivery/get/quizStatusSync/${url}`) }}>
            {isQuizRefreshLoading ? (
              <>
                <i className="fa fa-spinner fa-spin mr-1 fa-lg" aria-hidden="true"></i>
                <span className="pl-1">Updating...</span>
              </>
            ) : (
              <>
                <i className="fa fa-refresh fa-lg" aria-hidden="true"></i>
                <span className="pl-1">Refresh Progress</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}


const ContentDeliveryArea = ({
  mergedCourseStructure,
  selectedContent,
  sideBarOpen,
  userId,
  courseId,
  sessionId,
  url,
  showContent,
  activityProgress,
  videoEncodeStatus,
  contentLoader,
  tenantId,
  getContentProgressByActivityId
}) => {

  const [externalLinkOpened, setExternalLinkOpened] = useState(false);
  const [getContentUrl, setContentUrl] = useState('#');
  const [isZipLoading, setIsZipLoading] = useState(false);
  const [isQuizRefreshLoading, setIsQuizRefreshZipLoading] = useState(false);
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);




  useEffect(() => {
    setExternalLinkOpened(false);
  }, [selectedContent]);

  useEffect(() => {
    if (selectedContent?.nodetype === "externalLink" && url && showContent && selectedContent?.activityProgressforDependency === 100 && !externalLinkOpened) {
      setExternalLinkOpened(true);
      window.open(url, "_blank");
    }
  }, [selectedContent, url, showContent, externalLinkOpened]);

  // for generate the token url for image and mp4
  useEffect(() => { }, [getContentUrl])
  useEffect(() => {
    const fetchContent = async () => {
      if (url && selectedContent?.activityProgressforDependency === 100) {
        setContentUrl("#")

        switch (selectedContent?.nodetype) {
          case 'scorm':
            // --------- working -------------
            setContentUrl(`${URI.SERVER?._DMS_URL}/scorm/${url}`);

            // -------------- Not Working -------------------
            // let scormRes = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`)                        
            // const scormhtmlblob = new Blob([scormRes.data], { type: 'text/html' });
            // const scormhtmlblobUrl = URL.createObjectURL(scormhtmlblob);
            // setContentUrl(scormhtmlblobUrl);
            break;
          case 'html':
            let res = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`)
            //  res.data contains HTML content
            const htmlblob = new Blob([res.data], { type: 'text/html' });
            const htmlblobUrl = URL.createObjectURL(htmlblob);
            setContentUrl(htmlblobUrl);
            // setContentUrl(res.data)
            break;
          case 'zip':
            setContentUrl(`${URI.SERVER?._DMS_URL}/${url}`)
            break;
          case 'mp4':
          case 'png':
          case 'jpeg':
          case 'jpg':
            try {
              let response = await axios.get(`${URI.SERVER?._DMS_URL}/${url}`, {
                responseType: 'blob'
              });

              let blobUrl = URL.createObjectURL(response.data);
              setContentUrl(blobUrl);
              // setContentType(nodeType);

            } catch (error) {
              
              setContentUrl('#');
            }
            break;
          default: break;

        }

      }
    };

    fetchContent();

    // Cleanup function
    return () => {
      if (getContentUrl !== '#') {
        URL.revokeObjectURL(getContentUrl);
      }
    };
    // }, []);

  }, [url, selectedContent?.activityProgressforDependency]);

  const downloadZip = async (url) => {
    try {
      setIsZipLoading(true)
      let response = await axios.get(url, {
        responseType: 'blob'
      });
      //console.log(response.data)
      // alert("dsfaf")
      let blobUrl = URL.createObjectURL(response.data);
      setIsZipLoading(false)
      saveAs(blobUrl)
      setContentUrl(blobUrl);

      // setContentType(nodeType);

    } catch (error) {
      
      setContentUrl('#');
    }
  }
  const refreshQuizProgress = (filepath) => {
    //console.log("file path", filepath + "/" + userId)
    setIsQuizRefreshZipLoading(true)
    axios.get(filepath + "/" + userId).then((res) => {
      if (res.data.status === 200) {
        getContentProgressByActivityId()
        // swal("success", "Status updated successfully!", "success");
        setIsQuizRefreshZipLoading(false)
      } else {
        swal(
          `Error`,
          `${res.data.message}`,
          "error"
        );
        setIsQuizRefreshZipLoading(false)
      }
    }).catch((err) => {
      swal(
        `Error`,
        `Try after sometime!`,
        "error"
      );
      setIsQuizRefreshZipLoading(false)
    })
    // alert("Dfadff")
  }
  const ContentDisplay = () => {

    if (!showContent) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Circles
              height={40}
              width={40}
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> */}
          </div>
        </div>
      )
    }
    else if (selectedContent?.nodetype === "VLabs" && showContent) {

      return (
        (selectedContent?.activityProgressforDependency === 100) ? (
          <>
            <div>
              Check next tab
            </div>
          </>
        ) : (
          <>
            <h6>
              Complete previous contents
            </h6>
          </>
        ));
    }
    else if (selectedContent?.nodetype === "mp4" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}

            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (

            videoEncodeStatus === "success" && url != "" ? (
              <div>
                <MediaContentView cmsUrl={url} />
              </div>
            ) : (
              <div>
                <video key={getContentUrl} width="100%" height="100%" controls autoPlay>
                  <source
                    // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                    src={getContentUrl}
                    type="video/mp4"
                  />
                </video>
              </div>
            )
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}
        </>
      );
    } else if ((selectedContent?.nodetype === "png" || selectedContent?.nodetype === "jpg" || selectedContent?.nodetype === "jpeg") && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>

          {(selectedContent?.activityProgressforDependency === 100) ? (
            <div>
              <figure>
                <img
                  // src={selectedContent?.activityProgressforDependency === 100 ? `${URI.SERVER?._DMS_URL}/${url}` : "#"}
                  key={getContentUrl}
                  src={getContentUrl}
                  width="100%"
                  height="100%"
                  alt='#'
                />
              </figure>
            </div>
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}

        </>
      );
    } else if ((selectedContent?.nodetype === "scorm" || selectedContent?.nodetype === "html") && url && showContent) {
      return (

        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe title='html' width="100%" height="800" src={getContentUrl} key={getContentUrl}></iframe>
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "zip" && url && showContent) {
      return (

        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            {/* <iframe
              src={selectedContent?.activityProgressforDependency === 100 ? `${getContentUrl}` : "#"}
              key={getContentUrl}
              title='zip'
              width="100%"
              height="700px"
              allowFullScreen={true}
              style={{ minHeight: "700px", overflow: "auto" }}
            /> */}
            {/* <GradientBackground /> */}
            <div className="main-container">
              <div className="blur-container">
                <div className="button-wrapper">
                  <button onClick={() => { downloadZip(getContentUrl) }} className="download-button" >
                    {isZipLoading ? (
                      <> <i className="fa fa-spinner fa-spin mr-2 fa-lg" aria-hidden="true"></i><i className='pl-2'>Downloading Zip File...</i></>
                    ) : (
                      <i className="fa fa-cloud-download mr-2 fa-lg" aria-hidden="true"><span className='pl-2'>Download Zip File</span></i>
                    )}

                  </button>
                </div>
              </div>
            </div>
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>


      );
    }

    else if ((selectedContent?.nodetype === "practiceQuiz" || selectedContent?.nodetype === "pretestQuiz" || selectedContent?.nodetype === "finalQuiz") && url && showContent) {
      // alert("in content delivery area")
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ?
            <>
              <QuizModule isRedirectLoading={isRedirectLoading} isQuizRefreshLoading={isQuizRefreshLoading} refreshQuizProgress={refreshQuizProgress} url={url} tenantId={tenantId} />

            </>
            : <>
              <div>
                <h6>
                  Complete previous contents
                </h6>
              </div>
            </>
          }
        </>
      );
    }

    else if (selectedContent?.nodetype === "pdf" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ViewPdf
              height={"1200px"}
              pdfUrl={`${URI.SERVER?._DOMAIN_URL}/pdfViewer/${url}`}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>
      );
    }
    else if (selectedContent?.nodetype === "youtube" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ReactPlayer
              url={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="700px"
              controls="true"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 }
                }
              }}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "streaming" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe
              title="Embedded HTML Page"
              src={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="800"
              allowFullScreen
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>
      );
    }
    else if (selectedContent?.nodetype === "externalLink" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {selectedContent?.activityProgressforDependency === 100 ? (
            <h6>Link will open in new tab...</h6>
          ) : (
            <h6>Complete previous contents</h6>
          )}
        </>
      );
    }
    else if (selectedContent?.nodetype === "assignment" && url && showContent) {
      return <AssignmentDelivery assignmentId={url} userId={userId} userRole={`learner`} />
    }
    else if (selectedContent?.nodetype === "feedback" && url && showContent) {
      return <CourseFeedbackList typeid={3} cid={courseId} feedbackId={url} />  //// TYPE 3 FOR MODULE LEVEL FEEDBACK
    }
    else if (selectedContent?.nodetype === "finalfeedback" && url && showContent) {


      return <CourseFeedbackList typeid={1} cid={courseId} feedbackId={url} />  //// TYPE 1 FOR COURSE LEVEL FEEDBACK
    }
    else {
      return (
        <div></div>
      )
    }
  };

  useEffect(() => {
    ContentDisplay();

  }, [url])


  return (
    <div className='content-delivery-area-main'>
      <div className={`content-display-area ${sideBarOpen ? '' : 'open'}`}>

        {Object.keys(selectedContent).length == 0 && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            < h3 > Please Select a Content</h3>
          </div>
        )}
        {
          selectedContent && (
            <div className='content-display'>
              {ContentDisplay()}
            </div>
          )}
      </div>

    </div >
  )
}

export default ContentDeliveryArea