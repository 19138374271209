import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import StickyMenu from '../../components/common/StickyMenu';
import adminServices from '../../services/adminServices';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import useNonce from '../../helper/Nonce';
import service from '../../services/service';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]


const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900px',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500px',
            //paddingLeft: '0 8px',
            //marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};

export default function ManageOrganization() {

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        UserService.generateToken();
    }, []);
    const fetchAndSetNonce = useNonce();
    useEffect(() => {
        getAllOrganizations();
    }, [])


    const [organization, setOrganization] = useState();
    const [organizationName, setOrganizationName] = useState("");
    const [organizationCode, setOrganizationCode] = useState("");

    const getAllOrganizations = () => {
        service.getAllOrganizations().then((resp) => {
            setOrganization(resp.data);
        }).catch((err) => {

        })
    }


    const [getDeleteId, setDeleteId] = useState("");
    const [getEditId, setEditId] = useState("");

    const [deleteLoading, setDeleteLoading] = useState({
        isLoading: false
    })

    const [editLoading, setEditLoading] = useState({
        isLoading: false
    })


    const onClickDeleteButton = (id) => {
        setDeleteId(id);
        setDeleteLoading({ isLoading: true });

        adminServices.deleteOrganization(id)
            .then((resp) => {
                setDeleteLoading({ isLoading: false });
                if (resp.status === 200) {
                    swal(`${t('success')}`, `${t('organization_delete')}`, 'success');
                    getAllOrganizations();
                } else if (resp.status === 409) {
                    swal(`${t('sorry')}`, `${t('organization_associated_records')}`, 'warning');
                } else {
                    swal(`${t('error')}`, `${t('cannot_be_deleted')}`, 'error');
                }
            })
            .catch((err) => {
                setDeleteLoading({ isLoading: false });
                swal(`${t('error')}`, `${t('something_went_wrong')}`, 'error');
            });
    };

    const [id, setId] = useState();

    const onClickUpdateButton = () => {
        const isValid = validate();
        if (isValid) {
            const organization = {
                orgId: id,
                orgName: organizationName,
                orgCode: organizationCode,
            };

            adminServices.updateOrganization(organization)
                .then((resp) => {
                    if (resp.status === 200) {
                        swal(`${t('success')}`, `${t('organization_updated')}`, 'success');
                        getAllOrganizations();
                        setAddModalShow(false);
                    } else {
                        swal(`${t('error')}`, `${t('update_failed')}`, 'error');
                    }
                })
                .catch(() => {
                    swal(`${t('error')}`, `${t('something_went_wrong')}`, 'error');
                });
        }
    };

    const onClickEditButton = (id) => {

        setEditId(id);
        setEditLoading({ isLoading: true });

        adminServices.getOrganizationById(id).then((resp) => {
            setEditLoading({ isLoading: false });
            setOrganizationName(resp.data.orgName)
            setOrganizationCode(resp.data.orgCode)
            setId(id);
            setAddModalShow(true);
        }).then((err) => {
            setEditLoading({ isLoading: false });

        })

    }


    const OrganizationColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            wrap: true,
            width: "100px"
        },
        {
            name: "Code",
            selector: row => row.orgCode,
            wrap: true,
            width: "150px"
        },
        {
            name: "Organization Name",
            selector: row => row.orgName,
            wrap: true,
            width: "90vh"
        },
        {
            name: "Action",
            selector: row => <>
                <Button
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                        onClickEditButton(row.orgId)
                    }}
                >
                    {editLoading.isLoading ? getEditId === row.orgId ?
                        (
                            <> {t('loading')}</>
                        ) :
                        (
                            <>  {t('edit')} </>
                        ) : (
                        <> {t('edit')} </>
                    )}
                </Button>
                <Button
                    className="btn btn-danger"
                    onClick={() => {
                        onClickDeleteButton(row.orgId)
                    }}
                >
                    {deleteLoading.isLoading ?
                        getDeleteId === row.orgId ?
                            (
                                <>{t('loading')}</>
                            ) : (
                                <>  {t('delete')}  </>
                            ) : (
                            <> {t('delete')}  </>
                        )}
                </Button>
            </>,
        }
    ];

    const [addModalShow, setAddModalShow] = useState(false);
    const onClickAdd = () => {
        setId();
        setOrganizationName();
        setAddModalShow(true);
    }

    const onNameChangeHandler = (e) => {
        setOrganizationName(e.target.value)
    }

    const onCodeChangeHandler = (e) => {
        setOrganizationCode(e.target.value)
    }

    const [organizationNameErr, setOrganizationNameErr] = useState();
    const [organizationCodeErr, setOrganizationCodeErr] = useState();

    const validate = () => {
        const nameRegex = /^[A-Za-z0-9.,' \-]{2,100}$/; // Allows only alphabets and spaces, between 2 and 100 characters and some special characters
        const codeRegex = /^[0-9]{3,10}$/; // Allows only numbers, between 3 and 10 characters

        if (!organizationName) {
            setOrganizationNameErr(t('please_enter_organization_name'));
            return false;
        }

        if (!nameRegex.test(organizationName)) {
            setOrganizationNameErr(t('do_not_use_special_charater'));
            return false;
        }

        setOrganizationNameErr("");

        if (!organizationCode) {
            setOrganizationCodeErr(t('please_enter_organization_code'));
            return false;
        }

        if (!codeRegex.test(organizationCode)) {
            setOrganizationCodeErr(t('organization_code_must_be_between_3_and_10_numbers'));
            return false;
        }

        setOrganizationCodeErr("");
        return true;
    };

    const onClickAddOrganizationButton = async () => {
        await fetchAndSetNonce();
        const isValid = validate();
        if (isValid) {
            const organization = {
                orgName: organizationName,
                orgCode: organizationCode,
            };
    
            adminServices.addOrganization(organization)
                .then((resp) => {
                    if (resp.status === 201) {
                        swal(`${t('success')}`, `${t('organization_add')}`, 'success');
                        getAllOrganizations();
                        setAddModalShow(false);
                    } else {
                        swal(`${t('error')}`, `${t('add_failed')}`, 'error');
                    }
                })
                .catch(() => {
                    swal(`${t('error')}`, `${t('something_went_wrong')}`, 'error');
                });
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">{t('manage_organization')}</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('registration_details')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('manage_organization')}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div className='card-body'>
                                        <br></br>
                                        <div className="col-md-12 text-right w-100 p-0">
                                            <Button className='btn' onClick={onClickAdd} style={{ width: "60px" }}> {t('add')} </Button>
                                        </div>
                                        <div className="row pb-5">
                                            <div className="col-md-12 text-center mb-3">
                                                <h4>{t('organization_list')}</h4>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <DataTable
                                                columns={OrganizationColumns}
                                                data={organization}
                                                pagination
                                                customStyles={customStyles}
                                            />
                                        </div>

                                        <br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                centered
                show={addModalShow}
                onHide={() => { setAddModalShow(false) }}
                backdrop="static"

            >
                <Modal.Header
                    closeButton
                    style={{ backgroundColor: "#f8f9fa", borderBottom: "1px solid #dee2e6" }}
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
                    >
                        {t('add_organization')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "20px" }}>
                    <div className="mb-3 mt-3">
                        <label
                            htmlFor="name"
                            style={{ display: "block", fontWeight: "500", marginBottom: "8px" }}
                        >
                            {t('organization_name')} : *
                        </label>
                        <input
                            type="text"
                            minLength={2}
                            maxLength={100}
                            className="form-control"
                            placeholder={t('please_enter_organization_name')}
                            onChange={onNameChangeHandler}
                            value={organizationName}
                            style={{ padding: "10px", fontSize: "1rem" }}
                        />
                        {organizationNameErr && (
                            <div
                                className="alert alert-danger mt-2"
                                style={{
                                    fontSize: "0.875rem",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                {organizationNameErr}
                            </div>
                        )}
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="code"
                            style={{ display: "block", fontWeight: "500", marginBottom: "8px" }}
                        >
                            {t('organization_code')} : *
                        </label>
                        <input
                            type="text"
                            minLength={3}
                            maxLength={10}
                            className="form-control"
                            placeholder={t('please_enter_organization_code')}
                            onChange={onCodeChangeHandler}
                            value={organizationCode}
                            style={{ padding: "10px", fontSize: "1rem" }}
                        />
                        {organizationCodeErr && (
                            <div
                                className="alert alert-danger mt-2"
                                style={{
                                    fontSize: "0.875rem",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                {organizationCodeErr}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        justifyContent: "center",
                        borderTop: "1px solid #dee2e6",
                        backgroundColor: "#f8f9fa",
                    }}
                >
                    {id ? (
                        <Button
                            variant="success"
                            onClick={onClickUpdateButton}
                            style={{
                                padding: "10px 20px",
                                fontSize: "1rem",
                                fontWeight: "500",
                            }}
                        >
                            {t('update_organization')}
                        </Button>
                    ) : (
                        <Button
                            variant="success"
                            onClick={onClickAddOrganizationButton}
                            style={{
                                padding: "10px 20px",
                                fontSize: "1rem",
                                fontWeight: "500",
                            }}
                        >
                            {t('add_organization')}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
}
