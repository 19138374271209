import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import './DragDrop.css'; // Import the external CSS file


const getIconClass = (nodetype, isActive) => {
  const iconMap = {
    pdf: 'fas fa-file-pdf  fa-lg',
    png: 'fas fa-image fa-lg',
    jpg: 'fas fa-image fa-lg',
    zip: 'fas fa-file-archive fa-lg',
    scorm: 'fas fa-file-archive fa-lg',
    html: 'fab fa-html5 fa-lg',
    youtube: 'fab fa-youtube fa-lg',
    mp4: 'fas fa-video fa-lg',
    folder: 'fas fa-folder fa-lg',
    root: 'fa fa-list fa-lg',
    pretestQuiz: 'fa fa-clipboard fa-lg',
    practiceQuiz: 'fa fa-product-hunt',
    finalQuiz: 'fas fa-question-circle',
    streaming: 'fas fa-file-video fa-lg',
    externalLink: 'fa fa-external-link fa-lg',
    assignment: 'fa fa-book fa-lg',
    feedback: 'fa fa-commenting fa-lg',
    finalfeedback: 'fa fa-commenting fa-lg'
  };
  return `${iconMap[nodetype] || 'fas fa-folder'} ${isActive ? 'text-white' : getColorClass(nodetype)}`;
};

const getColorClass = (nodetype) => {
  const colorMap = {
    pdf: 'text-danger',
    png: 'images_color',
    jpg: 'images_color',
    zip: 'zip_color',
    scorm: 'text-dark',
    html: 'html_color',
    youtube: 'text-danger',
    mp4: 'mp4_color',
    folder: 'folder_color',
    root: 'folder_color',
    pretestQuiz: 'text-success',
    practiceQuiz: 'text-success',
    finalQuiz: 'text-success',
    streaming: 'mp4_color',
    externalLink: 'text-primary',
    assignment: 'text-info',
    feedback: 'fa fa-commenting fa-lg',
    finalfeedback: 'fa fa-commenting fa-lg'
  };
  return colorMap[nodetype] || 'folder_color';
};
const DraggableItem = ({ item, index }) => {
  return (
    <Draggable draggableId={item.key} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="draggable-item"
        >
          <i className={getIconClass(item.nodetype)} style={{ fontSize: "18px", paddingRight: "5px" }}></i>
          {item.label}
        </div>
      )}
    </Draggable>
  );
};


const DraggableFolder = ({ folder, index }) => {
  return (
    <Draggable draggableId={folder.key} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="draggable-folder"
        >
          <i className={getIconClass("folder")} style={{ fontSize: "18px", paddingRight: "5px" }}></i>
          {folder.label}
        </div>
      )}
    </Draggable>
  );
};


const DragAndDropContainer = ({ items, type }) => {

  return (
    <Droppable droppableId={type} type={type}>
      {(provided) => (
        <div
          {...provided?.droppableProps}
          ref={provided?.innerRef}
          className="drag-drop-container"
        >
          {items.map((item, index) => (
            // Render DraggableItem or DraggableFolder based on the item type
            item.nodetype === 'folder' ? (
              <DraggableFolder key={item.key} folder={item} index={index} />
            ) : (
              <DraggableItem key={item.key} item={item} index={index} />
            )
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default DragAndDropContainer