import axios from "axios";
import { ASSIGN_URI } from "./AssignmentService.js";
// import { ASSIGN_URI, URII } from './AssignmentService';

//**********************  LOCAL URL   ***************************** */
// export let Frontend_UI = "http://10.244.2.164:3000";

// export let Assessment_Authoring = "http://10.244.2.253:3001"

// export let Assessment_Delivery = "http://10.244.2.253:3002"

// export let Keyclock = "http://meghs2.hyderabad.cdac.in";

// export let DMS = "10.244.2.194";

// export let DMS_URL = "http://meghs3.hyderabad.cdac.in:8080";

// export let Communication = "http://10.244.2.194:8081";

// export let COURSE_URL = "http://meghs3.hyderabad.cdac.in:8082";

// export let CERTIFICATION = 'http://meghs2.hyderabad.cdac.in:8083';

// export let USER_API2 = "http://10.244.2.194:8084/";

// export let USER_API = "http://10.244.2.194:8084/um_api/";

// export let COURSE_CLUG = 'http://meghs2.hyderabad.cdac.in:8085';

// export let Assignment = 'http://10.244.2.194:8086';

// export let ANNOUNCEMENT = 'http://10.244.2.194:8087';

// export let FEEDBACK = 'http://10.244.2.194:8088';

// export let DISCUSSION = 'http://10.244.2.194:8089';

// export let LEARNING_ANALYTICS = 'http://10.244.2.194:8090';

// export let PAYMENT = "http://10.244.2.194:8091";

// export let Rating = "http://10.244.2.194:8092"

// export let QUIZRESULT = "http://10.244.2.253:8094";

// export let QUIZCOMPLETERESULT = "http://10.244.2.253:8095";

// export let ACTIVITYCOMPLETION = "http://10.244.2.194:8100";

// const register_URL = USER_API + "registerlearner";

// const GET_COURSES = COURSE_CLUG + '/courseCatalogue';

// const ENROLLMENT = COURSE_CLUG + '/api';

// const Rating_URL = Rating + "/review"

// const ASSESSMENT = Communication + '/Assessment/quiz';

// const SEARCHENGINEURL = COURSE_CLUG + '/courseCatalogue/getCourseByCourseIds';

// const TESTIMONIAL = Communication + '/testimonial';

// const NUMBEROFCOUNTS = COURSE_CLUG + '/api/getLearnerInstructorCourseCount';

// const UPCOMINGEVENTS_URL = Communication + "/upcomingEvent";

// const ASSIGNMENT = COURSE_CLUG + "/courseCatalogue";

// const NEW_DISCUSSION = DISCUSSION + "/Discussion";

// // const USER_DASHBOARD = Communication + "/dashboard";

// const LEARNER_REQUEST_APPROVAL_For_Course = COURSE_CLUG + "/api";
// export let ADMIN_DELIVERY_URL = Assessment_Delivery + "/assessment/delivery/dashboard/conductor/quiz/ready/"
// export let CREATE_QUIZ_URL = Assessment_Authoring + "/assessment/authoring/dashboard/courseId/"

//****************     URL Structure Start      ***************************** */

//****************   URL Structure  End's   ***************************** */

export const URI = {
  SERVER: {
    _FRONTEND_UI: "",
    _DOMAIN: "",
    _DOMAIN_URL: "",
    _MEGH_API: "",
    _ASSESSMENT_AUTHORING: "",
    _ASSESSMENT_DELIVERY: "",
    _KEYCLOCK: "",
    _DMS: "",
    _DMS_URL: "",
    _COMMUNICATION: "",
    _COURSE_URL: "",
    _CERTIFICATION: "",
    _USER_API2: "",
    _USER_API: "",
    _COURSE_CLUG: "",
    _ASSIGNMENT: "",
    _ANNOUNCEMENT: "",
    _FEEDBACK: "",
    _DISCUSSION_URL: "",
    _LEARNING_ANALYTICS: "",
    _PAYMENT: "",
    _RATING: "",
    _QUIZRESULT: "",
    _QUIZCOMPLETERESULT: "",
    _ACTIVITYCOMPLETION: "",
    _ADMIN_DELIVERY_URL: "",
    _CREATE_QUIZ_URL: "",
    _QUIZ_AUTHORING: "",
    _NONCE: ""
  },
  _REGISTER_URL: "",
  _GET_COURSES: "",
  _ENROLLMENT: "",
  _RATING_URL: "",
  _ASSESSMENT: "",
  _SEARCHENGINEURL: "",
  _TESTIMONIAL: "",
  _NUMBEROFCOUNTS: "",
  _UPCOMINGEVENTS_URL: "",
  _ASSIGNMENT_URL: "",
  _DISCUSSION: "",
  _LEARNER_REQUEST_APPROVAL_For_COURSE: "",
  _ADMIN_DELIVERY_URL: "",
  _CREATE_QUIZ_URL: "",
};

class service {
  constructor() {
    // debugger

    this.init(); // Call the async init method
  }

  async init() {
    try {
      let response = await fetch('/data.json');
      let data = await response.json();
      const isStaging = data.SERVER?.MODE === 'staging';
      let baseUrl = data.SERVER;
      if (isStaging) {
        //for staging
        response = await fetch('/datas.json');
        data = await response.json();
        baseUrl = data.SERVER
        URI.SERVER = baseUrl;
      } else {
        //for production server
        Object.keys(URI.SERVER).forEach(key => {
          URI.SERVER[key] = baseUrl?._FRONTEND_UI; // common urls for all
        });
        URI.SERVER._USER_API = baseUrl?._FRONTEND_UI + '/um_api/'
        URI.SERVER._DMS = baseUrl?._DOMAIN
        URI.SERVER._DOMAIN_URL = baseUrl._DOMAIN_URL
        URI.SERVER._MEGH_API = baseUrl._MEGH_API
        URI.SERVER._ASSESSMENT_AUTHORING = baseUrl._DOMAIN_URL //need only domain url
        URI.SERVER._ADMIN_DELIVERY_URL = baseUrl._DOMAIN_URL // needed only domain url
        URI.SERVER._CREATE_QUIZ_URL = baseUrl._DOMAIN_URL
        URI.SERVER._ASSESSMENT_DELIVERY = baseUrl._DOMAIN_URL

      }

      const courseClugUrl = baseUrl?._COURSE_CLUG || baseUrl?._FRONTEND_UI;
      const communicationUrl = baseUrl?._COMMUNICATION || baseUrl?._FRONTEND_UI;

      if (isStaging) {
        //for staging
        URI._REGISTER_URL = `${baseUrl?._USER_API || baseUrl?._FRONTEND_UI}public/registerlearner`;  //STAGING
      } else {
        URI._REGISTER_URL = `${baseUrl?._USER_API || baseUrl?._FRONTEND_UI}/um_api/public/registerlearner`; //PRODUCTION
      }

      URI._GET_COURSES = `${courseClugUrl}/courseCatalogue`;
      URI._ENROLLMENT = `${courseClugUrl}/courseCatalogue/api`;
      URI._RATING_URL = `${baseUrl?._RATING || baseUrl?._FRONTEND_UI}/ratingandreview`;
      URI._ASSESSMENT = `${communicationUrl}/Assessment/quiz`;
      URI._SEARCHENGINEURL = `${courseClugUrl}/courseCatalogue/public/getCourseByCourseIds`;
      // URI._TESTIMONIAL = `${communicationUrl}/testimonial`;
      URI._COMMUNICATION_URL = `${baseUrl?._COMMUNICATION || baseUrl?._FRONTEND_UI}/communications`;
      URI._NUMBEROFCOUNTS = `${courseClugUrl}/courseCatalogue/public/getLearnerInstructorCourseCount`; // public or api
      // URI._UPCOMINGEVENTS_URL = `${communicationUrl}/upcomingEvent`;
      URI._ASSIGNMENT_URL = `${courseClugUrl}/courseCatalogue`;
      URI._DISCUSSION_URL = `${baseUrl?._DISCUSSION || baseUrl?._FRONTEND_UI}/discussion`;
      URI._LEARNER_REQUEST_APPROVAL_For_COURSE = `${courseClugUrl}/courseCatalogue/api`;
    } catch (error) {
      console.error('Failed to fetch URL:', error);
    }
  }

  // learner user service

  /* This code is for add token in header send to endpoint for refrence */
  // getCountry() {
  //     return axios.get(this.validateURL(USER_API) + '/' + "country",
  //     {
  //             headers: {
  //                 'Authorization': `Bearer ${UserService.getToken()}`
  //             },
  //         }
  //     );

  //     return axios.get(this.validateURL(USER_API) + '/' + "country");
  // }

  // getState() {
  //     return axios.post(this.validateURL(USER_API) + '/' + 'imageupload', formData, {
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //             'Authorization': `Bearer ${UserService.getToken()}`
  //         },
  //         onUploadProgress,
  //     });
  // }

  getUser() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API));
  }

  getUserById(userId) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "api/learner/" + userId);
  }

  getUserProfileById(userId) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "api/get-learner-profile/" + userId);
  }

  updateUser(userdata) {
    return axios.post(this.validateURL(URI.SERVER?._USER_API) + "api/updatelearner/", userdata);
  }

  stateByid(stateId) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "api/state/" + stateId)
  }

  getCountry() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "public/country");
  }

  getState() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "public/state");
  }

  getDistrict(id) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "api/district/byStateId/" + id);
  }
  getProfilePic(userId) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'public/getprofilepic/' + userId);
  }

  Register(formData1) {
    return axios.post(this.validateURL(URI._REGISTER_URL), formData1, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  RegisterCadre() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'public/cadre');
  }
  RegisterQualification() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'public/qualf');
  }
  RegisterDesignation() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'public/desig');
  }
  getAllOrganizations() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'public/get-all-organization');
  }

  // troken Verification Service
  tokenVerification(tokenId, email) {
    return axios.post(this.validateURL(URI.SERVER?._USER_API) + 'public/learneremailveristatus?token=' + tokenId + "&email=" + email);
  }

  assignLearnerRole(email) {
    return axios.post(URI.SERVER?._USER_API + `api/approvelearner`, email);
  }

  rejectLearnerRole(email, rejectRemark) {

    let formData = new FormData();
    formData.append("email", email);
    formData.append("remark", rejectRemark);

    return axios.post(URI.SERVER?._USER_API + `api/rejectlearner`, formData);
}

  validateURL(url) {
    const parsed = new URL(url);
    if (parsed.protocol === "http:") {
      return url;
    }
    if (parsed.protocol === "https:") {
      return url;
    } else {
      return false;
    }
  }


  getRegisterCaptcha(captchaKey) {
    return axios.get(
      this.validateURL(URI.SERVER?._USER_API) + "public/generateCaptcha",
      {
        params: { key: captchaKey },
      }
    );
  }

  LearnersCountForStatus() {
    return axios.get(
      this.validateURL(URI.SERVER?._USER_API) + "api/LearnersCountForStatus"
    );
  }

  approveCourseLearnerRequest(courseId, userId) {
    return axios.post(
      URI._LEARNER_REQUEST_APPROVAL_For_COURSE +
      `/approveCourseEnrollRequestByAdmin?courseId=` +
      courseId +
      "&userId=" +
      userId
    );
  }
  rejectCourseLearnerRequest(courseId, userId, remark) {

    return axios.post(URI._LEARNER_REQUEST_APPROVAL_For_COURSE + `/rejectCourseEnrollRequestByAdmin?courseId=` + courseId + "&userId=" + userId,  {
        remark: remark  // Including the remark in the request body
    });
  }

  getRoleIdbyCourseIdandUserId(courseId, userId) {
    return axios.get(
      URI._LEARNER_REQUEST_APPROVAL_For_COURSE +
      `/getRoleIdbyCourseIdandUserId/` +
      courseId +
      "/" +
      userId
    );
  }

  approveCourseLearnerRequestforPayment(courseId, userId) {
    return axios.post(
      URI._LEARNER_REQUEST_APPROVAL_For_COURSE +
      `/approveCourseEnrollRequestByAdminToPay?courseId=` +
      courseId +
      "&userId=" +
      userId
    );
  }

  deleteRequestInstructor(email) {
    //return axios.post(this.validateURL(USER_API) + '/userInstReq/' + email);
  }
  // assignInstructorRole(data) {


  getCourseStructure(courseId, tenantId) {
    return axios.get(
      this.validateURL(URI._GET_COURSES) + "/" + courseId + "/structure",
      {
        headers: {
          tenant_id: +tenantId,
        },
      }
    );
  }

  // getCourseContent(courseId, identifierId, path) {
  //     return axios.get(COURSE_CONTENT_ACCESS + '/' + courseId + '/' + identifierId + '/' + path);
  // }
  // End of code here

  //Enrolled user courses

  getCoursesByUserIdAndRoleId(userId, roleId) {
    return axios.get(
      this.validateURL(URI._ENROLLMENT) +
      "/getCoursesEnrolledByUser/" +
      userId +
      "/" +
      roleId
    );
  }

  getLibrariesByUserIdAndRoleId(userId, roleId) {
    return axios.get(
      this.validateURL(URI._ENROLLMENT) +
      "/getLibrariesEnrolledByUser/" +
      userId +
      "/" +
      roleId
    );
  }

  postUserEnrollment(data) {
    return axios.post(
      this.validateURL(URI._ENROLLMENT) + "/userEnrolltoCourse/",
      data
    );
  }

  getUserEnrollmentStatus(courseId, userId, roleId, tenantId) {
    return axios.get(
      this.validateURL(URI._ENROLLMENT) +
      "/CheckUserEnrollementStatus/" +
      courseId +
      "/" +
      userId +
      "/" +
      roleId +
      "?tenant_id=" +
      tenantId
    );
  }

  getCourseProgressReport(courseIds) {
    return axios.post(
      this.validateURL(URI._ENROLLMENT) + "/getCourseProgressReport",
      courseIds
    );
  }

  // End of enroll course function

  searchEngine(val) {
    return axios.get("https://eprabodhini.lms.muhs.ac.in/ngel/_search?q=" + val);
  }

  //assessment services

  getAssignedQuizDetails(courseId, userId, tenant_id) {
    return axios.get(
      this.validateURL(URI._ASSESSMENT) +
      "/AssignedQuizdetails?courseId=" +
      courseId +
      "&userId=" +
      userId +
      "&tenant_id=" +
      tenant_id
    );
  }

  getAttemptedQuizDetails(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/getAttemptedQuizDetails",
      details
    );
  }

  getAttemptIdAndQuizDetails(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/getAttemptIDAndQuizDetails",
      details
    );
  }

  initializeQuiz(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/initializeQuiz",
      details
    );
  }

  nextQuestion(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/nextQuestion",
      details
    );
  }

  prevQuestion(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/prevQuestion",
      details
    );
  }

  questionPalette(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/getRequestedQuestion",
      details
    );
  }

  FinishQuizs(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/finishQuiz",
      details
    );
  }

  getQuizScore(details) {
    return axios.post(this.validateURL(URI._ASSESSMENT) + "/getScore", details);
  }

  ResumeQuiz(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/resumeQuiz",
      details
    );
  }

  QuizUpdatePooling(details) {
    return axios.post(
      this.validateURL(URI._ASSESSMENT) + "/quizUpdatePolling",
      details
    );
  }
  //end of assessment services

  // Search Engine Service

  SearchEngineResult(courseIds, tenantid) {
    return axios.get(
      this.validateURL(URI._SEARCHENGINEURLT) +
      "?courseIds=" +
      courseIds +
      "&tenantId=" +
      tenantid
    );
  }

  // user dashbord api calling

  // userLoginDuration(userId) {
  //     return axios.get(this.validateURL(USER_DASHBOARD) + '/getUserLogoutDetails/' + userId);
  // }

  // userContentAccessDur(userId) {
  //     return axios.get(this.validateURL(USER_DASHBOARD) + '/userContentDetails/' + userId);
  // }

  paymentDetailSave(data) {
    return axios.post(
      this.validateURL(URI.SERVER?._PAYMENT) + "/payment/api/saveNewDetails",
      data
    );
  }

  getPaymentDetailsbyOrderId(orderId, generatedOrderId) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/getPaymentDetailsbyOrderId/" +
      orderId +
      "/" +
      generatedOrderId
    );
  }

  getOrderId(fees, orderId) {
    return axios.post(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/getOrderID/" +
      fees +
      "/" +
      orderId
    );
  }

  updateRazorOrderid(orderId, razorOrderId) {
    return axios.post(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/updateOrderID/" +
      orderId +
      "/" +
      razorOrderId
    );
  }

  updateRazorpayDetails(orderId, paymentId, paymentSignature) {
    return axios.post(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/updateRazorpayDetails/" +
      orderId +
      "/" +
      paymentId +
      "/" +
      paymentSignature
    );
  }

  getPaymentDetails(userId, courseId, tenantId) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/getPaymentDetails/" +
      userId +
      "/" +
      courseId +
      "/" +
      tenantId
    );
  }

  getCoursePaymentDetailsByDate(courseId, fromDate, toDate) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      "/payment/api/admin/paymentBydaterange/" +
      `${courseId}?fromdate=${fromDate}&todate=${toDate}`
    );
  }

  getPaymentDetailsbyuserIdandCourseId(userId, courseId) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      `/payment/api/getPaymentDetailsbyuserId/${userId}/${courseId}`
    );
  }

  PaymentDetailsEncrypReqString(orderId) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      `/payment/api/getPaymentDetailsEncrypReqString/${orderId}`
    );
  }

  getPaymentDetailsbyGateway(vResponse) {
    return axios.get(
      this.validateURL(URI.SERVER?._PAYMENT) +
      `/payment/api/getPaymentDetailsbyGateway`,
      vResponse
    );
  }

  // getPaymentDetailsbyGateway(orderId){
  //     return axios.get(this.validateURL(PAYMENT)+`/payment/api/getPaymentDetailsbyGateway/${orderId}`)
  // }

  updatePaymentDetailsGetbyGateway(vResponse) {
    return axios.post(
      this.validateURL(URI.SERVER?._PAYMENT) +
      `/payment/api/updatePaymentDetailsGetbyGateway`,
      vResponse
    );
  }

  getPublishedCourseStructure(courseId, userId) {
    return axios.get(
      this.validateURL(URI.SERVER?._COURSE_URL) +
      `/courseOrganizer/api/getPubCourseStructure/${courseId}/${userId}`
    );
  }

  autoAssignQuiz = (quizData) => {
    return axios.post(
      `${URI.SERVER?._QUIZ_AUTHORING}/assign/put/autoUpdateUserAssignment`,
      quizData
    );
  };

  upload(file, sig, user_id, onUploadProgress) {
    let formData = new FormData();
    formData.append("filesig", sig);
    formData.append("file", file);
    formData.append("user_id", user_id)
    return axios.post(this.validateURL(URI.SERVER?._USER_API) + 'api/imageupload', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  assignInstructorRole(data) {
    return axios.post(URI.SERVER?._USER_API + `api/approveInstRequest/${data}`)
    //return axios.post(this.validateURL(KEYCLOCK_AUTH_URL) + 'assigninstrole', data);
  }


  // assignInstructorRole(data) {
  //     return axios.post(this.validateURL(KEYCLOCK_AUTH_URL) + 'assigninstrole', data);
  // }
  // else if (service === "Review") {
  //     return axios.get(`${URI.SERVER?._RATING}/review/fetch/nonce`);
  // }
  // else if (service === "Usermanagement") {
  //     return axios.get(`${URI.SERVER?._USER_API2}/um_api/fetch/nonce`);
  // }
  // else if (service === "Feedback") {
  //     return axios.get(`${URI.SERVER?._FEEDBACK}/feedback/fetch/nonce`);
  // }
  // else if (service === "DISCUSSION") {
  //     return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + `/disQuestion/fetch/nonce`);
  // }
  // else if (service === "Assignment") {
  //     return axios.get(`${URI.SERVER?._ASSIGNMENT}/assignment/fetch/nonce`);
  // }
  /////NONCE/////////
  // end of learner managemenrt url 

  //course grid and course details services

  getAllCourses() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/courses');
  }

  getPublishCourses() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/publishCourses');
  }

  getAllLibraryForAdmin() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/libraries');
  }

  getAllLibrary() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/publishedlibraries');
  }

  getLibraryById(courseId) {
    return axios.get(this.validateURL((URI.SERVER?._COURSE_URL)) + '/courseOrganizer/api/getLibraries/' + courseId)
  }
  updateLibrary(formData) {
    return axios.post(this.validateURL((URI.SERVER?._COURSE_URL)) + '/courseOrganizer/api/instructor/updateLibrary', formData)
  }
  getAllLibraryToPublish() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/api/librariestopublish');
  }

  getCoursesById(courseId, tenantId) {
    return axios.get(this.validateURL(URI._GET_COURSES) + "/courses/" + courseId + "?tenant_id=" + tenantId);
  }
  userCount(courseId, tenantId) {
    return axios.get(this.validateURL(URI._GET_COURSES) + "/public" + "/userCount/" + courseId + "/" + tenantId);
  }

  // closed here

  // Courser Structure function 

  getCourseandFeesStatus() {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/getCourseandFeesStatus');
  }

  // getCourseContent(courseId, identifierId, path) {
  //     return axios.get(COURSE_CONTENT_ACCESS + '/' + courseId + '/' + identifierId + '/' + path);
  // }
  // End of code here 

  //Enrolled user courses

  getUserEnrolledCourses(userId, roleId) {
    return axios.get(this.validateURL(URI._ENROLLMENT) + '/getCoursesEnrolledByUser/' + userId + '/' + roleId);
  }

  // End of enroll course function 

  // ####################################### FEEDBACK START ####################################### //

  // getFeedback(typeid, cid) {
  //     return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackmap/' + typeid + '/' + cid);
  // }
  getFeedback(typeid, cid, uid) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + `/feedback/api/getCourseFeedbacklistBycourseId/${cid}/${typeid}/${uid}`);
  }

  getFeedbackByCourseTypeFeedbackId(typeid, cid, uid, feedbackId) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + `/feedback/api/get-feedback-by-course-type-feedback-id/${cid}/${typeid}/${uid}/${feedbackId}`);
  }

  getUserFeedbackStatus(cid, uid) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + `/feedbackResponse/coursefeedbackstatus/${cid}/${uid}`);
  }
  // getFeedback(typeid, cid) {
  //     return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + `/feedback/getCourseFeedbacklistBycourseId/${cid}/${typeid}`);
  // }

  getFeedbackByTypeAndItemId(typeid, id) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackmap/api/' + typeid + '/' + id);
  }

  getFeedbackById(feedbackId) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/get-feedback-by-id/' + feedbackId);
  }

  getFeedbackStatus(typeid, id, feedbackBy) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackResponse/api/' + typeid + '/' + id + '/' + feedbackBy);
  }

  postFeedback(feedback) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackResponse/api', feedback);
  }

  postContactUs(contactValues) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/contactus/api', contactValues);
  }

  // Create feedback call api
  feedbackMasterPost(data) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/addfeedback', data)
  }

  feedbackQuestionCreationForCourse(data) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/question/api', data)
  }

  feedbackQuestionUpdateForCourse(data) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/question/api/updateQuestion', data)
  }

  getAllQuestionByType(typeId) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/question/api/getQuestionByType/' + typeId);
  }

  getAllQuestionByTypeAndUpdatedBy(typeId, userId) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/question/api/getQuestionByTypeAndUpdatedBy/' + typeId + '/' + userId);
  }

  getResponseCount(typeId, id) {
    return axios.get(URI.SERVER?._FEEDBACK + `/feedbackResponse/api/questionsummary/${typeId}/${id}`);
  }

  getAllResponseByCourseId(courseId) {
    return axios.get(URI.SERVER?._FEEDBACK + `/feedbackResponse/api/get-all-response-by-courseId/${courseId}`);
  }

  deleteFeedbackQuestion(questionId) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK + '/question/api/deleteQuestion/' + questionId))
  }

  addQuestionsMapWithIds(data) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackmap/api', data)
  }

  courseFeedbackResponse(courseId, typeId) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedbackResponse/api/' + courseId + '/' + typeId);
  }

  getContactCaptcha() {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/contactus/public/generateCaptcha');
  }

  getFeedbackListByCourseId(courseid) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/get-all-feedback-by-courseid/' + courseid)
  }

  getFeedbackListByCourseIdAndFeedBackType(courseId, feedbackType) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/get-all-feedback-by-courseid-feedbackType/' + courseId + '/' + feedbackType)
  }

  getGeneralFeedbackListById(courseid) {
    return axios.get(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/id/' + courseid)
  }

  setActiveInactive(feedbackId) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/activeinactive/' + feedbackId)
  }

  setFeedbackStatus(feedbackId, feedbackStatus) {
    return axios.post(
      this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/set-feedback-status',
      {
        id: feedbackId,
        status: feedbackStatus,
      }
    );
  }

  deleteFeedback(feedbackId) {
    return axios.post(this.validateURL(URI.SERVER?._FEEDBACK) + '/feedback/api/delete/' + feedbackId)
  }

  // ####################################### FEEDBACK END ####################################### //

  // ######################################## COMMUNICATIONS START ################################ //

  // number of counts on homepage
  getNumberOfCounts() {
    //console.log(URI._NUMBEROFCOUNTS)
    return axios.get(this.validateURL(URI._NUMBEROFCOUNTS));
  }

  addTestimonial(value) {
    // return axios.post(this.validateURL(URI._TESTIMONIAL), value);
    return axios.post(this.validateURL(URI._COMMUNICATION_URL) + '/api/savetestimonial', value);
  }

  getTestimonial() {
    // return axios.get(this.validateURL(URI._TESTIMONIAL) + '/getApprovedTestimonial');
    return axios.get(this.validateURL(URI._COMMUNICATION_URL) + '/public/getApprovedTestimonial');
  }

  // Get news letter
  getnewsletterdetails(newsletterdetails) {
    // return axios.post(this.validateURL(URI._UPCOMINGEVENTS_URL) + '/subscribe', newsletterdetails);
    return axios.post(this.validateURL(URI._COMMUNICATION_URL) + '/public/subscribe', newsletterdetails);
  }

  getUpcomingActivEvents() {
    // alert("inside the service")
    // return axios.get(this.validateURL(URI._UPCOMINGEVENTS_URL) + '');
    return axios.get(this.validateURL(URI._COMMUNICATION_URL) + '/public/upcomingEvent');
  }

  // get all upcomming Event List in admin-panal
  getAllEventList() {
    // return axios.get(this.validateURL(URI._UPCOMINGEVENTS_URL) + '/getAll');
    return axios.get(this.validateURL(URI._COMMUNICATION_URL) + '/api/getAll');
  }

  // detele particular event
  deleteEvent(eventId) {
    // return axios.post(this.validateURL(URI._UPCOMINGEVENTS_URL) + `/${eventId}`);
    return axios.post(this.validateURL(URI._COMMUNICATION_URL) + `/api/${eventId}`);
  }

  //Add Event (Done by Admin)
  addEvent(eventData) {
    // return axios.post(this.validateURL(URI._UPCOMINGEVENTS_URL), eventData);
    return axios.post(this.validateURL(URI._COMMUNICATION_URL) + `/api/saveEvent`, eventData);
  }

  // Update Event (Done by Admin)
  // updateEvent(description,eventDate,eventId,location,time,title){
  //     //console.log(eventId)
  //     return axios.post(this.validateURL(UPCOMINGEVENTS_URL)+`?description=${description}&eventDate=${eventDate}&eventId=${eventId}&location=${location}&time=${time}&title=${title}`)
  // }

  updateEvent(updateData) {

    // return axios.post(this.validateURL(URI._UPCOMINGEVENTS_URL) + `/updateEvent`, updateData);
    return axios.post(this.validateURL(URI._COMMUNICATION_URL) + `/api/updateEvent`, updateData);
    // return axios.post(this.validateURL(UPCOMINGEVENTS_URL)+`?description=${description}&eventDate=${eventDate}&eventId=${eventId}&location=${location}&time=${time}&title=${title}`)
  }

  // ######################################## COMMUNICATIONS END ######################################## //

  // ####################################### DISCUSSION START ####################################### //

  setUpdateCommentValues(tenantid, values) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/comment/updateComment?tenantId=' + tenantid, values);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/updateComment?tenantId=' + tenantid, values);
  }

  deleteCommentValues(id, tenantid) {
    // return axios.post(URI.SERVER?._DISCUSSION + '/comment/deleteComment/' + id + '?tenantId=' + tenantid);
    return axios.post(URI?._DISCUSSION_URL + '/api/deleteComment/' + id + '?tenantId=' + tenantid);
  }

  setCommentValues(tenantid, commentValue) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/comment/save?tenantId=' + tenantid, commentValue);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/save?tenantId=' + tenantid, commentValue);
  }

  getCommentValues(id, tenantId) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/comment/getByResponseId?responseId=' + id + '&tenantId=' + tenantId);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getByResponseId?responseId=' + id + '&tenantId=' + tenantId);
  }

  // setCommentValues(tenantid, commentValue) {
  //     return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/comment?tenantId=' + tenantid, commentValue);
  // }

  getUpdateCommentValues(id, tenantid) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/comment/' + id + '?tenantId=' + tenantid);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/' + id + '?tenantId=' + tenantid);
  }

  // getPostValues(courseId, itemid, tenantId) {
  //     return axios.get(this.validateURL(DISCUSSION) + '/post/' + courseId + '/' + itemid + '?tenantId=' + tenantId)
  // }

  getPostValues(courseId, itemid, tenantId) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/post/' + courseId + '/' + itemid);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/post/cidtid/' + courseId + '/' + itemid);
  }

  setUpdatePostValues(tenantid, values) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/post/updatePost/?tenantId=' + tenantid, values);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/updatePost/?tenantId=' + tenantid, values);
  }

  deletePostValues(id, tenantid) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/post/deletePost/' + id + '?tenantId=' + tenantid);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deletePost/' + id + '?tenantId=' + tenantid);
  }

  setPostValues(tenantid, postValue) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/post?tenantId=' + tenantid, postValue);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/post?tenantId=' + tenantid, postValue);
  }

  getUpdatePostValues(id, tenantid) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/post/getByPostId?postId=' + id + '&tenantId=' + tenantid);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getByPostId?postId=' + id + '&tenantId=' + tenantid);
  }

  setResponseValues(tenantid, responseValue) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/response?tenantId=' + tenantid, responseValue);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/response?tenantId=' + tenantid, responseValue);
  }

  deleteResponseValues(id, tenantid) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/response/deleteResponse/' + id + '?tenantId=' + tenantid);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deleteResponse/' + id + '?tenantId=' + tenantid);
  }

  getUpdateResponseValues(id, tenantid) {
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/response/' + id + '?tenantId=' + tenantid);
  }

  // setUpdateResponseValues(tenantid, values) {
  //     return axios.post(this.validateURL(URI.SERVER?._DISCUSSION_URL) + '/response/updateResponse?tenantId=' + tenantid, values);
  // }

  setPostQuestion(tenantid, postValue) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/disQuestion?tenantId=' + tenantid, postValue);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/disQuestion?tenantId=' + tenantid, postValue);
  }

  deleteQuestion(questionId, tenantid) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/disQuestion/deleteQuestion/' + questionId + '?tenantId=' + tenantid);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deleteQuestion/' + questionId + '?tenantId=' + tenantid);
  }

  getQueryQuestions(courseId, userId) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/disQuestion/' + courseId + '/' + userId);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/learner/' + courseId + '/' + userId);
  }

  getQueryQuestionByCourseIdAndLearnerId(courseId, userId) {
    // return axios.get(this.validateURL(URI.SERVER?._DISCUSSION) + '/disQuestion/getByCourseIdAndLearnerId/' + courseId + '/' + userId);
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getByCourseIdAndLearnerId/' + courseId + '/' + userId);
  }

  setReportSpam(tenantid, value) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/reportspam/?tenantId=' + tenantid, value);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/reportspam/?tenantId=' + tenantid, value);
  }

  setVote(tenantid, value) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/vote/?tenantId=' + tenantid, value);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/vote/?tenantId=' + tenantid, value);
  }

  setUpdateResponseValues(tenantid, values) {
    // return axios.post(this.validateURL(URI.SERVER?._DISCUSSION) + '/response/updateResponse', values);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/updateResponse', values);
  }

  deleteResponse(responseId, tenantId) {
    // return axios.post(this.validateURL(URI._DISCUSSION) + '/response/deleteResponse?responseId=' + responseId + '&tenantId=' + tenantId);
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deleteResponse?responseId=' + responseId + '&tenantId=' + tenantId);
  }

  getResponseValues(postId, tenantId) {
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getByResponsePostId?postId=' + postId + '&tenantId=' + tenantId);
  }

  getCourseDiscussionDetails(courseId, tenantId, userId) {
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getResponseValues?courseId=' + courseId + '&tId=' + tenantId + '&userId=' + userId + '&roleId=1');
  }

  getAllPostInfo(postId, tenantId) {
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getAllPostInfo?postId=' + postId + '&tenantId=' + tenantId);
  }

  getDiscussionsByItem(itemId, tenantId, userId) {
    return axios.get(this.validateURL(URI?._DISCUSSION_URL) + '/api/getItemDiscussions?itemId=' + itemId + '&tenantId=' + tenantId + '&userId=' + userId + '&roleId=1');
  }

  addDiscussion(title, courseId, userId, posttext, tenantId, item, username) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/addDiscussion?posttype=Discussion&title=' + title + '&courseId=' + courseId + '&userId=' + userId + '&posttext=' + posttext + '&tId=' + tenantId + '&item=' + item + '&username=' + username);
  }

  addResponse(postId, responseTextValue, userId, username, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/addResponse?postid=' + postId + '&responseText=' + responseTextValue + '&userId=' + userId + '&username=' + username + '&tenantId=' + tenantId);
  }

  addComment(responseId, commentTextValue, userId, username, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/addComment?responseId=' + responseId + '&commentText=' + commentTextValue + '&userid=' + userId + '&username=' + username + '&tenantId=' + tenantId);
  }

  updateDiscussion(postId, editedText, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/editPost?postid=' + postId + '&editedPostText=' + editedText + '&tenantId=' + tenantId);
  }

  updateResponse(responseId, editedText, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/editResponse?responseId=' + responseId + '&responseText=' + editedText + '&tenantId=' + tenantId);
  }

  updateComment(commentId, editedText, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/editComment?commentId=' + commentId + '&commentText=' + editedText + '&tenantId=' + tenantId);
  }

  deleteDiscussion(postId, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deletePost?postid=' + postId + '&tenantId=' + tenantId);
  }

  deleteComment(commentId, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/deleteComment?commentId=' + commentId + '&tenantId=' + tenantId);
  }

  postSpamValue(postId, userId, spamValue, tenantId) {
    return axios.post(this.validateURL(URI?._DISCUSSION_URL) + '/api/postreportspam?sid=' + postId + '&userId=' + userId + '&spamValue=' + spamValue + '&sValue=' + '' + '&tenantId=' + tenantId);
  }

  // ####################################### DISCUSSION END ######################################## //

  // ################################### RATING AND REVIEW START ################################### //

  createrating(review) {
    // return axios.post(this.validateURL(URI._RATING_URL) + '/saveReview', review);
    return axios.post(this.validateURL(URI._RATING_URL) + '/api/saveReview', review);
  }

  updaterating(review) {
    // return axios.post(this.validateURL(URI._RATING_URL) + "/updateReview", review);
    return axios.post(this.validateURL(URI._RATING_URL) + "/api/updateReview", review);
  }

  deleterating(rId) {
    // return axios.post(this.validateURL(URI._RATING_URL) + '/' + "deleteReview?reviewId" + '=' + rId);
    return axios.post(this.validateURL(URI._RATING_URL) + "/api/deleteReview?reviewId" + '=' + rId);
  }

  getratingbyreviewId(Rerid) {
    // return axios.post(this.validateURL(URI._RATING_URL) + '/byReviewId', Rerid);
    return axios.post(this.validateURL(URI._RATING_URL) + '/public/byReviewId', Rerid);
  }

  getreplybyreviewId(rerid) {
    // return axios.get(this.validateURL(URI._RATING_URL) + '/byReviewItemid?reviewItemId=' + rerid);
    return axios.get(this.validateURL(URI._RATING_URL) + '/api/byReviewItemid?reviewItemId=' + rerid);
  }

  getoverallRating(itemid) {
    // return axios.get(this.validateURL(URI._RATING_URL) + '/byReviewItemid?reviewItemId=' + itemid);
    return axios.get(this.validateURL(URI._RATING_URL) + '/api/byReviewItemid?reviewItemId=' + itemid);
  }

  getRatingByItemIdUserIdReviewTypeTenantId(itemid, tenantId, reviewType) {
    const params = new URLSearchParams({
      reviewItemId: itemid,
      tenantId: tenantId,
      reviewtype: reviewType
    }).toString();
    // return axios.get(`${this.validateURL(URI._RATING_URL)}/getReviewByUserItemidTenantidReviewType?${params}`);
    return axios.get(`${this.validateURL(URI._RATING_URL)}/api/getReviewByUserItemidTenantidReviewType?${params}`);
  }

  // averageRating(reviews) {
  //     return axios.post(this.validateURL(Rating_URL) + '/getAvgbyReviewItemid?reviewType=1', reviews);
  // }

  averageRating(reviews) {
    // return axios.post(this.validateURL(URI._RATING_URL) + '/getAvgbyReviewItemidReviewType', reviews);
    return axios.post(this.validateURL(URI._RATING_URL) + '/public/getAvgbyReviewItemidReviewType', reviews);
  }

  getRatingByItemIdReviewTypeTenantId(itemid, tenantId, reviewType) {
    const params = new URLSearchParams({
      reviewItemId: itemid,
      tenantId: tenantId,
      reviewtype: reviewType
    }).toString();
    // return axios.get(`${this.validateURL(URI._RATING_URL)}/getReviewByItemidTenantidReviewType?${params}`);
    return axios.get(`${this.validateURL(URI._RATING_URL)}/public/getReviewByItemidTenantidReviewType?${params}`);
  }

  disableRatatingAndReview(reviewItemId, reviewAllowed) {
    return axios.get(`${this.validateURL(URI._RATING_URL)}/api/disableReview/${reviewItemId}/${reviewAllowed}`);

  }
  createreply(reply) {
    // return axios.post(this.validateURL(URI._RATING_URL) + "/savereply", reply);
    return axios.post(this.validateURL(URI._RATING_URL) + "/api/savereply", reply);
  }

  manageReviews(reviewId, reviewAllowed) {
    return axios.post(this.validateURL(URI._RATING_URL) + "/api/manage-reviews", null, {
      params: {
        reviewId: reviewId,
        reviewAllowed: reviewAllowed
      }
    });
  }

  // ################################### RATING AND REVIEW END ################################### //


  // ####################################### ANNOUNCEMENT START ################################### //

  AnnouncementByCurrentDataToPublishUpTo() {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/public/getAnnouncementByCurrentDateTopublishUpTo/?tenant_id=1');
  }

  MarqueeByCurrentDataToPublishUpTo() {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/public/getMarqueeByCurrentDateTopublishUpTo/?tenant_id=1');
  }

  courseAnnouncement(courseId, tenantId) {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/course/' + courseId + '/?tenant_id=' + tenantId)
  }
  createAnnouncement(data) {
    return axios.post(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/saveAnnouncement', data);
  }

  getAllGeneralAnnouncementListByAuthor(authorId) {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/getAllGeneralAnnouncementListByAuthor/' + authorId);
  }

  getAllMarqueeAnnouncementListByAuthor(authorId) {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/getAllMarqueeAnnouncementListByAuthor/' + authorId);
  }

  getAllCourseAnnouncementListByAuthor(authorId, courseId) {
    return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/getAllCourseAnnouncementListByAuthor/' + authorId + '/' + courseId);
  }

  deleteAnnouncement(id) {
    return axios.post(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/delete/' + id);
  }

  updateAnnouncement(id, data) {
    return axios.post(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + '/announcements/api/update/' + id, data)
  }

  // ####################################### ANNOUNCEMENT START ####################################### //

  // ####################################### LEARNING ANALYTICS START ################################### //

  // Action Controller

  saveUserActionDetails(browserName, os, resolution, userid, ipaddress, sessionId) {

    return axios.post(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lauseractionlogin/' + browserName + '/' + os + '/' + resolution + '/' + userid + '/' + ipaddress + '/' + sessionId)
  }

  updateUserActionDetails(userid, sessionid) {
    return axios.post(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lauseractionlogout/' + userid + '/' + sessionid);
  }

  getAllActions() {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lauseraction');
  }

  // Content Visit Controller  
  getContentVisitById(userid, courseId) {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/contentaccess/' + userid + '/' + courseId);
  }

  getContentVisitList() {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lacontentvisit');
  }

  saveContentVisit(userid, courseId, resid, restitle, sessionId, fileType) {
    return axios.post(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lacontentvisitintime/' + userid + '/' + courseId + '/' + resid + '/' + restitle + '/' + sessionId + '/' + fileType);
  }

  updateContentVisitOutTime(userid, sessionId, courseId, resId) {

    return axios.post(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/lacontentvisitouttime/' + userid + '/' + courseId + '/' + resId + '/' + sessionId);
  }

  getActivityProgressByActivityId(courseId, userId, activityId) {
    return axios.get(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + `/useractivitystatus/api/ActivitityStatus/${courseId}/${userId}/${activityId}`)
  }

  getTotalTimeSpentOnContent(userid, courseId) {

    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/timespent/' + userid + '/' + courseId);
  }

  getTimeSpentOnContents(userid, courseId) {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/timespentres/' + userid + '/' + courseId);
  }

  getCourseViewCount(courseId) {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/getviewcountbycourseid/' + courseId);
  }

  getViewCount() {
    return axios.get(this.validateURL(URI.SERVER?._LEARNING_ANALYTICS) + '/la_an/api/getviewcount');
  }

  // ####################################### LEARNING ANALYTICS END ################################### //

  // certificate Generate
  // getCertificateGenerate(tenantId, courseId, emailId, username) {
  //     return axios.post(CERTIFICATE_URL + '?tenantId=' + tenantId + '&courseId=' + courseId + '&emailId=' + emailId + '&name=' + username)
  // }

  //assignment services
  getAssignmentDetails(courseId, tenantId) {
    return axios.get(this.validateURL(URI._ASSIGNMENT) + '/getassgndetails?course_id=' + courseId, {
      headers: {
        'tenant_id': + tenantId
      }
    });
  }

  assignmentupload(file, userid, courseId, assignmentId, tenantId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userid)
    formData.append("course_id", courseId);
    formData.append("assgn_id", assignmentId);
    //formData.append("tenant_id", tenantId);

    return axios.post(this.validateURL(URI._ASSIGNMENT) + '/docupload', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'tenant_id': + tenantId
      },
    });
  }

  getAssignmentScoreDetails(courseid, tenantid, userid) {
    return axios.get(this.validateURL(URI._ASSIGNMENT) + '/getassgnscoredetails?course_id=' + courseid + '&user_id=' + userid, {
      headers: {
        'tenant_id': + tenantid
      }
    });
  }

  // Instructor data fatch
  getInstructorDetails(courseId, tenantId) {
    return axios.get(this.validateURL(URI._GET_COURSES) + '/public/getInstructorByCourseId?courseId=' + courseId + '&tenantId=' + tenantId)
  }

  getAllInstructorsDetails() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + 'api/learner/getallmeghinst')
  }

  requestForInstructor(userId) {
    return axios.post(this.validateURL(URI.SERVER?._USER_API) + "api/UserRequestinstructor/" + userId);
  }

  checkInstructorRequest(userID) {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + "api/UserRequestinstructorStatus/" + userID);
  }

  //Quiz Services

  quizReport(userId, courseId) {
    return axios.get(this.validateURL(URI.SERVER?._QUIZRESULT) + "/assign/get/getAssignedQuizzes/" + userId + "/" + courseId);
  }

  getQuizCompleteResult(data) {
    return axios.post(this.validateURL(URI.SERVER?._QUIZCOMPLETERESULT) + "/delivery/get/getListOfDeliveryContentByUserAndQuizId/", data);
  }

  // Completion Criteria

  courseCompletionMaster(courseCompletion) {

    return axios.post(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + "/coursecompletionmaster/api", courseCompletion);
  }

  addUpdateA_Restriction_Completion_CriteriaMaster(course_A_Completion) {

    return axios.post(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + "/coursecompletioncriteria/api/addUpdateActivityRestrictionAndCompletionAndCriteriaMaster", course_A_Completion);
  }

  courseActivitiesStatus(userId, courseId) {

    return axios.get(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + "/useractivitystatus/api/courseActivitiesStatus?userId=" + userId + "&ccId=" + courseId);
  }

  //////////// NOTES ///////////////

  createNote(newNote) {
    return axios.post(this.validateURL(URI.SERVER?._COURSE_CLUG) + "/courseCatalogue/api/addNote", newNote)
  }

  getAllContentNotesByCourseIdUserId(courseId) {
    const params = {
      courseId: courseId,
    };
    return axios.get(this.validateURL(URI.SERVER?._COURSE_CLUG) + "/courseCatalogue/api/getNotesByCourse", { params })
  }

  getAllContentNotesByContentIdCourseIdUserId(courseId, contentId) {
    const params = {
      courseId: courseId,
      contentId: contentId
    };
    return axios.get(this.validateURL(URI.SERVER?._COURSE_CLUG) + "/courseCatalogue/api/getNotesByCourseByContent", { params })
  }

  updateContentNote(updatedNote) {
    return axios.post(this.validateURL(URI.SERVER?._COURSE_CLUG) + "/courseCatalogue/api/updateNote", updatedNote)
  }

  deleteContentNote(deleteNote) {
    return axios.post(this.validateURL(URI.SERVER?._COURSE_CLUG) + "/courseCatalogue/api/deleteNote", deleteNote)
  }

  getCourseStructureForNotes(courseId) {
    return axios.get(this.validateURL(URI.SERVER?._COURSE_URL + "/courseOrganizer/getCourseStructure/" + courseId))
  }

  //////////// NOTES ///////////////


  autoAssignQuiz = (quizData) => {
    return axios.post(`${URI.SERVER?._QUIZ_AUTHORING}/assign/put/autoUpdateUserAssignment`, quizData);
  }

  getUserCourseContentDetails(userId, courseId) {
    return axios.get(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + `/coursecompletionmaster/api/user-course-content-details/${userId}/${courseId}`)
  }

  getPublishCourseRequestCount() {
    return axios.get(this.validateURL(URI.SERVER?._COURSE_URL) + `/courseOrganizer/api/admin/getRequestPublishCourseCount`)
  }

  // No. of User for Request as a Learner
  getNumberOfUserApproval() {
    return axios.get(this.validateURL(URI.SERVER?._USER_API) + `api/admin/pending-request`);
  }

  // No. of Enrollement in Course + Pending for Approval in Course
  getCourseEnrollementDetails() {
    return axios.get(this.validateURL(URI.SERVER?._COURSE_CLUG) + `/courseCatalogue/api/admin/getCourseWiseRequestCount`);
  }

  getUserCourseProgress(userId) {
    return axios.get(this.validateURL(URI.SERVER?._ACTIVITYCOMPLETION) + `/coursecompletionmaster/api/getallbyuserId/${userId}`)
  }

  requestforApproveLibContent(libData) {
    return axios.post(this.validateURL(URI.SERVER._COURSE_URL) + '/courseOrganizer/api/learner/requestForApproveLibContent', libData)
  }
  getLibContentStatus(userId, libId) {
    return axios.get(this.validateURL(URI.SERVER._COURSE_URL) + `/courseOrganizer/api/getLibRequests/${userId}/${libId}`)

  }

  /////NONCE/////////
  fetchNonce(service) {

    return axios.get(this.validateURL(`${URI.SERVER?._NONCE}/nonce/fetch`));
    // if (service === "COURSE_URL") {
    //     return axios.get(this.validateURL(URI.SERVER?._COURSE_URL) + `/fetch/nonce`);
    // } else if (service === "DMS") {
    //     return axios.get(this.validateURL(URI.SERVER?._DMS_URL) + `/dms/fetch/nonce`);
    // }
    // else if (service === "Announcement") {
    //     return axios.get(this.validateURL(URI.SERVER?._ANNOUNCEMENT) + `/announcements/fetch/nonce`);
  }
  deleteFeedback = (feedbackId) => {
    return axios.post(URI.SERVER._FEEDBACK + `/feedback/api/delete/${feedbackId}`)
  }

  createInstWithoutRequest(userId) {
    return axios.post(this.validateURL(URI.SERVER._USER_API) + "api/createInstWithoutRequest/" + userId);
  }

  getUserReports(data) {
    //console.log(data)
    return axios.post(URI.SERVER._COURSE_CLUG + `/courseCatalogue/api/admin/getUserCourseSummary`, data)
  }

}
export default new service();
