import React, { Fragment, useEffect, useMemo } from 'react';
// import {  } from 'react';
// import {  } from 'react';

import Navbar from '../../app/shared/Navbar';
import Sidebar from '../../app/shared/Sidebar';
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import learnerService from '../../services/learnerService';
import service, { URI, USER_API } from '../../services/service';
import UserService from '../../services/UserService';
import StickyMenu from '../common/StickyMenu';
import { Styles } from '../library/styles/coursedetails.js';
//import { Styles } from '../../pages/instructor/instCourses/styles/coursedetails.js';
//import { Styles } from '../../app/Courses/styles/course.js'
import { Accordion, Button, Card, Col, Container, Modal, Nav, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import SettingsPanel from '../../app/shared/SettingsPanel';
import Footer from '../../app/shared/Footer';
import instructorService from '../../services/instructorService';
import { colors } from '../common/element/elements';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "./styles/pagination.css"

import swal from 'sweetalert';
import Videojs from '../../pages/instructor/instCourses/video.js';
import ReactPlayer from 'react-player';
import adminServices from '../../services/adminServices';
import { useState } from 'react';
import CryptoJS from "crypto-js";
import { PreviewModal } from '../../pages/instructor/instCourses/CourseStructureDriveNew.js';
import axios from 'axios';



const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};



function AdminViewLibraryContent(props) {

    useEffect(() => {
        UserService.generateToken();
    }, []);

    const um_api = URI.SERVER?._USER_API;


    let courseId = props.match.params.id;
    let tenantId = 1;
    const [courseDetails, setCourseDetails] = useState([]);
    const [getRating, setRating] = useState([]);
    const [getFeedbackResponse, setfeedbackResponse] = useState([])
    const [userCount, setUserCount] = useState();
    const [getLearnerData, setlearnerData] = useState([]);
    const [getInstructorData, setInstructorData] = useState([]);
    const [getYouTubeUrl, setYouTubeUrl] = useState();
    const [contentData, setContentData] = useState([])

    const [getUrl, setUrl] = useState('')
    const [getContentType, setContentType] = useState('');
    const [getContentLabel, setContentLabel] = useState("");
    const [urlModal, setUrlModal] = useState(false);
    const [previewContent, setPreviewContent] = useState({ contentType: '', url: '', videoEncodeStatus: null });

    useEffect(() => {
        getContentList();
        // getCourseDetails();
        // fatchRatingData();
        // courseFeedbackResponses();
        // fetchPosts();
        // getUserEnrolledByCourse();
        getCourseInstructors();
    }, [])

    // const usersPerPage = 8;
    // const pagesVisited = pageNumber * usersPerPage;
    // const pageCount = Math.ceil(currentCourseState.length / usersPerPage);


    const handleContentPreview = async (url, contentType, encodeStatus, streamId, row) => {

        try {
            const response = await instructorService.contentAccess(URI.SERVER?._DMS_URL + url);
            let finalUrl = '';
            if (contentType === 'pdf') {
                const encrypted = CryptoJS.AES.encrypt("/" + URI.SERVER._MEGH_API + "/" + response.data, "cdac@123").toString();
                const safeEncodedString = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
                finalUrl = URI.SERVER?._DMS + "/" + safeEncodedString;
            } else if (contentType === 'mp4') {
                if (encodeStatus === 'pending') {
                    finalUrl = response.data;
                } else if (encodeStatus === 'success') {
                    finalUrl = response.data;
                } else {
                    finalUrl = response.data;
                }
            } else {
                if (encodeStatus === 'success') {
                    const axiosResponse = await axios.get(response.data, {
                        headers: { Authorization: UserService.getToken() },
                        responseType: "arraybuffer",
                    });
                    finalUrl = `data:${axiosResponse.headers['content-type']};base64,${new Buffer(axiosResponse.data).toString('base64')}`;
                } else {
                    finalUrl = response.data;
                }
            }
            setPreviewContent({ contentType, url: finalUrl, videoEncodeStatus: encodeStatus });
            setUrlModal(true);
        } catch (error) {
            swal(`${t("error")}`, `${error} ${t("try_sometimes")}`, "error");
        }
    };
    const getContentList = () => {

        instructorService.getLibraryContent(courseId).then((resp) => {


            if (resp.data)

                setContentData(resp.data.nodes)
        }).catch((err) => {

        })
    }

    const onClickViewContent = (fileUrl, contentType, contentLabel) => {
        // setUrl();
        setContentType();
        setUrlModal(false);
        console.log("fileurl", fileUrl)
        alert("sdf", fileUrl)
        instructorService.getContentAccess(fileUrl).then((resp) => {

            setUrl(resp.data);
            setContentType(contentType);
            setContentLabel(contentLabel);
            setUrlModal(true)
        }).catch((err) => {

        })







    }


    const onClickApproveButton = (id) => {

        adminServices.approveContent(id, courseId).then((resp) => {
            if (resp.status === 200) {
                swal(`${t('success')}`, `${t('content_approve_msg')}`, "success")
                getContentList();
            }

        }).catch((err) => {

        })

    }

    const [contentId, setContentId] = useState()

    const [rejectError, setRejectError] = useState();

    const validateError = () => {
        let remarkErr = ''
        if (!desp) {
            remarkErr = t('field_required')
        }

        if (remarkErr) {
            setRejectError(remarkErr);
            return false;
        }
        return true;
    }

    const rejectSubmitButton = () => {


        const data = {
            courseId: courseId,
            contentId: contentId,
            description: desp,
        }



        const check = validateError();
        if (check) {
            adminServices.rejectContent(data).then((resp) => {
                if (resp.status === 200) {
                    setModalShow(false);
                    setDesp();
                    swal(`${t('success')}`, `${t('content_reject_msg')}`, "success")
                    getContentList();
                }
            }).catch((err) => {

            })
        }

    }

    const [modalShow, setModalShow] = useState(false);
    const [desp, setDesp] = useState();
    const despHandler = (e) => {
        setDesp(e.target.value);
    }

    const onClickRejectButton = (id) => {

        setModalShow(true);
        setContentId(id);



    }
    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 1100,
        height: 800,
        controls: true,
        sources: [
            {
                src: `https://eprabodhini.lms.muhs.ac.in/${getUrl}`,
                type: 'video/mp4',
            },
        ]
    };



    const getCourseDetails = () => {
        learnerService.getCourseMetadataById(courseId)
            .then(res => {
                setCourseDetails(JSON.parse(res.data));
            }).catch(err => {

            })
    }

    const getUserEnrolledByCourse = () => {
        learnerService.getUserEnrolledByCourse(courseId, tenantId)
            .then(res => {
                setlearnerData(res.data);
            }).catch(err => {

            })
    }

    const getCourseInstructors = () => {
        learnerService.getCourseInstructors(courseId, tenantId)
            .then(res => {
                setInstructorData(res.data);
            }).catch(err => {

            })
    }

    const fatchRatingData = async () => {
        try {
            const res = await service.getoverallRating(courseId, tenantId);
            setRating(res.data);
        } catch (e) {

        }
    }
    const [courseState, setCourseState] = useState([]);
    const fetchPosts = async () => {



        setLoading(true);

        const res = await instructorService.getLibraryContent(courseId);

        setCourseState(res.data);

        setPaidJsonState(res.data.filter(function (ele) {
            return ele.fees > 0;
        }));
        setFreeJsonState(res.data.filter(function (ele) {
            return ele.fees == 0;
        }));
        setLoading(false);
    };



    const courseFeedbackResponses = async () => {
        let result = await service.courseFeedbackResponse(1, courseId);
        setfeedbackResponse(result.data);
    }

    const convertDate = (dateFormat) => {
        let timestamp = Date.parse(dateFormat);
        let date = new Date(timestamp);
        let d = date.toLocaleString('en-IN', { hour12: false, timeZone: 'IST' });
        // let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
        return d;
    }

    const feedbackResponseCheckHandler = (data) => {
        let array = data;
        let result = array.split(",").map((e) => parseInt(e));
        return result;
    }


    const columns = [
        {
            name: "Learner",
            cell: (row) => <img src={um_api + `public/getprofilepic/${row.learnerUsername}`} style={{ width: '42px', height: '42px', borderRadius: '100%', boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)" }} />,
            sortable: true,
            width: "150px"
        },
        {
            name: "Name",
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            wrap: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
            wrap: true
        },
        {
            name: "Mobile",
            selector: row => row.mobile,
            sortable: true,
            wrap: true
        },
    ];

    /* Table content Filter and Search */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = getLearnerData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const filteredItems1 = getInstructorData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const CourseDetails = (id, tid) => {
        // var result = '';
        // let length = 10;
        // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // var charactersLength = characters.length;
        // for (var i = 0; i < length; i++) {
        //     result += characters.charAt(Math.floor(Math.random() *
        //         charactersLength));
        // }
        // const myCipher = myCipher(`${result}`)
        // let cid = myCipher(`${id}`);
        // let tId = myCipher(`${tid}`);
        // let rNumber = Math.floor(Math.random() * 10000000000);

        // //history.push(`${process.env.PUBLIC_URL + "/instLibraryDetails/"}${rNumber}${cid}/${result}${tId}`);
        // history.push(`${process.env.PUBLIC_URL + "/course-details/"}${rNumber}${cid}/${result}${tId}`);

        const secretKey = "cdac@123"

        const encodedCourseId = CryptoJS.AES.encrypt(
            `${id}`,
            secretKey
        ).toString();
        const safeEncodedCourseId = encodedCourseId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        const encodedTenantId = CryptoJS.AES.encrypt(
            `${tid}`,
            secretKey
        ).toString();
        const safeEncodedTenantId = encodedTenantId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        history.push(`${process.env.PUBLIC_URL + "/course-details/"}${safeEncodedCourseId}/${safeEncodedTenantId}`);


    }

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [getAvgRating, setAvgRating] = useState([]);
    const [postsPerPage] = useState(10);
    const [paidJsonState, setPaidJsonState] = useState();
    const [freeJsonState, setFreeJsonState] = useState();

    const [filteredCourse, setFilteredCourse] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [hovered, setHovered] = useState(false);
    const history = useHistory();
    let categoryValue = useSelector(state => state.categoryValue);
    let value = useSelector(state => state.inputValue);
    let paidStat = useSelector(state => state.paidCourse);
    let freeStat = useSelector(state => state.freeCourse);


    let currentCourseState;
    if (value) {
        let data = contentData.filter((course) =>
            course.courseDetails.courseName.toLowerCase().includes(value)
        )
        currentCourseState = data.slice(0, 1000);
    } else {
        currentCourseState = contentData.slice(0, 1000);
    }

    // const usersPerPage = 6;
    // const pagesVisited = pageNumber * usersPerPage;
    // const pageCount = Math.ceil(currentCourseState.length / usersPerPage);

    // let currentCourseState;
    // if (value) {
    //     let data = courseState.filter((course) =>
    //         course.courseName.toLowerCase().includes(value)
    //     )
    //     currentCourseState = data.slice(0, 1000);
    // }

    // else if (freeStat) {
    //     if (freeJsonState == undefined) {
    //         currentCourseState = courseState.slice(0, 1000);
    //     } else {
    //         currentCourseState = freeJsonState.slice(0, 1000);
    //     }
    // }
    // else if (categoryValue) {
    //     currentCourseState = dummyCategory.slice(0, 1000);
    // }

    // else {
    //     currentCourseState = courseState.slice(0, 1000);
    // }
    // let dummyCategory = [];
    // if (categoryValue) {
    //     dummyCategory = courseState.filter(function (ele) {
    //         return ele.catName == categoryValue;
    //     })

    // }




    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;

    const pageCount = Math.ceil(currentCourseState.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };



    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">
                                    Library Content To Publish
                                </h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"> <a href="#" onClick={event => event.preventDefault()}>Library</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Library Content To Publish</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <Styles>
                                            <section className="course-details-area">
                                                <Container fluid className='rounded'>
                                                    {/* </Col> */}
                                                    <Col lg="12" md="12" sm="12">
                                                        <div className="course-details-top">
                                                            <div className="course-tab-list">
                                                                <Tab.Container defaultActiveKey="libraryContent">
                                                                    <Nav className="flex-column">
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="libraryContent">{t('library_content')}</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="instructor">{t('inst_details')}</Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                    <Tab.Content>
                                                                        <Tab.Pane eventKey="libraryContent" className="library-tab">
                                                                            <Row>
                                                                                {
                                                                                    currentCourseState.length == 0
                                                                                        ?
                                                                                        <>
                                                                                            {/* <h1>There is No Content Right Now</h1> */}
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                currentCourseState.slice(pagesVisited, pagesVisited + usersPerPage).map((content, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {/* <Col lg="4" md="12" key={i}> */}
                                                                                                            <Row>
                                                                                                                <div className='container-fluid d-flex justify-content-center'>
                                                                                                                    <div className='row'>
                                                                                                                        <div className='col-12'>
                                                                                                                            <div className='card text-center shadow'>
                                                                                                                                <div className='overflow'>
                                                                                                                                    {
                                                                                                                                        content.nodetype === "pdf"
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/pdf.png"} alt="" className='card-img-top' style={{ width: "50%", height: "100%", padding: "5px" }} />
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            content.nodetype === "jpg" || content.nodetype === "jpeg"
                                                                                                                                                ?
                                                                                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/JPEGImage.png"} alt="" className='card-img-top' style={{ width: "50%", height: "100%", padding: "5px" }} />
                                                                                                                                                :
                                                                                                                                                content.nodetype === "mp4"
                                                                                                                                                    ?
                                                                                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/VideoFileImage.png"} alt="" className='card-img-top' style={{ width: "50%", height: "100%", padding: "5px" }} />
                                                                                                                                                    :
                                                                                                                                                    content.nodetype === "zip"
                                                                                                                                                        ?
                                                                                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/zipFileImage.png"} alt="" className='card-img-top' style={{ width: "50%", height: "100%", padding: "5px" }} />
                                                                                                                                                        :
                                                                                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/FileImage.png"} alt="" className='card-img-top' style={{ width: "50%", height: "100%", padding: "5px" }} />

                                                                                                                                    }


                                                                                                                                </div>
                                                                                                                                <div className='card-body text-dark'>
                                                                                                                                    <h4 className='card-title'>{content.label}</h4>
                                                                                                                                    <p className='card-text text-secondary'>
                                                                                                                                        {/* <a href='#' className='btn btn-outline-success'>View Learner</a> */}
                                                                                                                                        <Button variant="info" style={{ marginRight: "10px" }} onClick={() => { handleContentPreview(content.filePath, content.nodetype, content.label) }} >{t('view')}</Button>
                                                                                                                                        {
                                                                                                                                            content.status === "C"
                                                                                                                                                ?
                                                                                                                                                <>
                                                                                                                                                    <Button style={{ marginRight: "10px" }} onClick={() => { onClickApproveButton(content.id) }} variant='success'>{t('approve')}</Button>
                                                                                                                                                    <Button onClick={() => { onClickRejectButton(content.id) }} variant='danger'> {t('reject')}</Button>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    {
                                                                                                                                                        content.status === "P"
                                                                                                                                                            ?
                                                                                                                                                            <>
                                                                                                                                                                <Button onClick={() => { onClickRejectButton(content.id) }} variant='danger'> {t('reject')}</Button>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            <>
                                                                                                                                                                <Button onClick={() => { onClickApproveButton(content.id) }} variant='success'>{t('approve')}</Button>
                                                                                                                                                            </>
                                                                                                                                                    }
                                                                                                                                                </>
                                                                                                                                        }
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Row>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                }
                                                                                {currentCourseState.length == 0 ? null :
                                                                                    <Col md="12" className="text-center">
                                                                                        <ReactPaginate
                                                                                            previousLabel={t('previous')}
                                                                                            nextLabel={t('next')}
                                                                                            pageCount={pageCount}
                                                                                            onPageChange={changePage}
                                                                                            containerClassName={"paginationBttns"}
                                                                                            previousLinkClassName={"previousBttn"}
                                                                                            nextLinkClassName={"nextBttn"}
                                                                                            disabledClassName={"paginationDisabled"}
                                                                                            activeClassName={"paginationActive"}
                                                                                        />
                                                                                    </Col>
                                                                                }
                                                                            </Row>
                                                                        </Tab.Pane>
                                                                        <Tab.Pane eventKey="instructor">
                                                                            <DataTable
                                                                                columns={columns}
                                                                                data={filteredItems1}
                                                                                defaultSortField="Name"
                                                                                defaultSortAsc={true}
                                                                                striped
                                                                                pagination
                                                                                highlightOnHover
                                                                                customStyles={customStyles}
                                                                                subHeader
                                                                                subHeaderComponent={subHeaderComponent}
                                                                            />
                                                                        </Tab.Pane>
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Container>
                                            </section>
                                        </Styles>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>

                <Modal centered show={modalShow} onHide={() => setModalShow(false)} backdrop="static" className='custom-modal-style' >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                            Remark
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label className="mb-0">Remark <span className="text-danger">*</span></label>
                            <br />
                            <input name="feedback_title" type="text" minLength={3} maxLength={50} value={desp} className="form-control" placeholder="Enter Feedback Title" onChange={despHandler}
                            />
                            {
                                rejectError
                                    ?
                                    <>
                                        <div className="alert alert-danger mt-2">
                                            {rejectError}
                                        </div>
                                    </>
                                    :
                                    <>
                                        {null}
                                    </>
                            }


                            {/* {questionData.questionError
                                                ? <div className="alert alert-danger mt-2">{questionData.questionError}</div>
                                                : ''
                                            } */}
                        </div>

                    </Modal.Body>
                    <Modal.Footer >
                        <Button onClick={rejectSubmitButton}> Submit </Button>
                    </Modal.Footer>
                </Modal>
                <PreviewModal
                    show={urlModal}
                    onHide={() => setUrlModal(false)}
                    contentType={previewContent.contentType}
                    url={previewContent.url}
                    videoEncodeStatus={previewContent.videoEncodeStatus}
                />
            </div >
        </div >
    );
}

export default AdminViewLibraryContent;