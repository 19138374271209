import React, { useEffect, useState } from 'react';
import "./NoteComponent.css";
import service from '../../../services/service';
import UserService from '../../../services/UserService';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Truncate from 'react-truncate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Circles } from 'react-loader-spinner';

const NoteComponent = ({ courseId, showContentNotes, setShowContentNotes }) => {

    const [contentNotesList, setContentNotesList] = useState([]);
    const [contentNotesModuleShow, setContentNotesModuleShow] = useState(false);
    const [courseStructure, setCourseStructure] = useState([]);
    const [mergedList, setMergedList] = useState([]);

    const [selectedContent, setSelectedContent] = useState(null);

    const [expandedFolder, setExpandedFolder] = useState(null);
    const [notesLoader, setNotesLoader] = useState(false);

    let userId = UserService.getUserid();

    useEffect(() => {
        fetchCourseAllNotes();
        fetchCourseStructure();
    }, [])


    const fetchCourseAllNotes = () => {
        setNotesLoader(true);
        service.getAllContentNotesByCourseIdUserId(courseId)
            .then((res) => {
                setContentNotesList(res.data);
                setNotesLoader(false);
            })
            .catch((err) => {
                setNotesLoader(false);
            })
    }

    const fetchCourseStructure = () => {
        setNotesLoader(true);
        service.getPublishedCourseStructure(courseId, userId)
            .then((res) => {
                setCourseStructure(res.data);
                setNotesLoader(false);
            })
            .catch((err) => {
                setNotesLoader(false);
            })
    }

    useEffect(() => {

        if (contentNotesList?.length > 0 && courseStructure?.nodes?.length > 0) {
            mergeNotesIntoCourseStructure();
        }
    }, [contentNotesList, courseStructure?.nodes])


    const mergeNotesIntoCourseStructure = () => {
        setNotesLoader(true);
        // Clone the courseStructure.nodes to avoid direct mutation
        let newCourseStructure = JSON.parse(JSON.stringify(courseStructure.nodes));

        // Iterate over the course structure to find and add the notes to the relevant content
        newCourseStructure.forEach(module => {
            module.nodes.forEach(content => {
                // Initialize notes as an empty array for each content
                content.notes = [];

                // Filter the notes that match the current content's ID
                const notesForContent = contentNotesList.filter(note => note.id.contentId === content.id).map(note => ({
                    noteId: note.noteMaster.noteId,
                    noteText: note.noteMaster.noteText,
                }));

                // Add the notes to the content. If no notes are found, the notes property remains an empty array
                content.notes = [...notesForContent];
            });
        });

        // Update the mergedList state with the new structure including notes
        setMergedList(newCourseStructure);
        setNotesLoader(false);
    };

    const handleModalClose = () => {

        setShowContentNotes(false);
    }

    const handleFolderClick = (folderId) => {
        if (expandedFolder === folderId) {
            setExpandedFolder(null);
        } else {
            setExpandedFolder(folderId);
        }
    };

    const handleNodeClick = (node) => {
        setSelectedContent(node);
    }

    const renderFolders = (nodes) => {

        return nodes?.map(node => {
            const isSelected = expandedFolder === node.id;
            return (
                <div
                    key={node.id}
                    className={`note-component-folder-and-contents`}
                >
                    <div
                        className={`note-component-folder-node ${expandedFolder === node.id ? 'selected-folder' : ''}`}
                        onClick={() => {
                            handleFolderClick(node.id);
                        }}
                    >
                        <Row className='note-component-folder-row'>
                            <Col className='note-component-folder-col' xs={1} lg={1}>
                                <i class={`${isSelected ? 'fa fa-folder-open' : 'fa fa-folder'}`} aria-hidden="true"></i>
                            </Col>
                            <Col className='note-component-folder-col' xs={10} lg={10}>
                                <span className="note-component-folder-label">
                                    <OverlayTrigger
                                        placement='right'
                                        overlay={
                                            <Tooltip
                                                id=""
                                            >
                                                {node.label}
                                            </Tooltip>
                                        }
                                    >
                                        <span id='label-name'>
                                            <Truncate
                                                lines={1}
                                                width={220}
                                                ellipsis={"...."}
                                            >
                                                {node.label}
                                            </Truncate>
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <div className={`note-component-folder-contents ${expandedFolder === node.id ? 'show' : ''}`}>
                        {expandedFolder === node.id && renderContents(node.nodes, node.id)}
                    </div>
                </div>
            );
        });
    };

    const renderContents = (nodes, folderId) => {
        if (!nodes || !expandedFolder || folderId !== expandedFolder) return null;

        return (
            <div className={`note-component-contents ${expandedFolder === folderId ? 'show' : ''}`}>
                {nodes.map(node => (
                    <div
                        key={node.id}
                        className={`${selectedContent?.id === node.id ? "note-component-selected-content" : "note-component-content-node"}`}
                        onClick={() => {
                            handleNodeClick(node);
                        }}
                    >
                        <div className="note-component-content-details">
                            <div className="note-component-content-label">
                                <OverlayTrigger
                                    placement='right'
                                    overlay={
                                        <Tooltip
                                            id=""
                                        >
                                            {node.label}
                                        </Tooltip>
                                    }
                                >
                                    <span id='label-name'>
                                        <Truncate
                                            lines={1}
                                            width={220}
                                            ellipsis={"...."}
                                        >
                                            {node.label}
                                        </Truncate>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const generatePDF = mergedList => {
        const unit = "pt";
        const size = "a4";
        const orientation = "portrait"
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(13);

        const tableData = [];
        mergedList.forEach(module => {
            let moduleHasNotes = false;
            module.nodes.forEach(content => {
                content.notes.forEach(note => {
                    const moduleName = module.label;
                    const contentName = content.label;
                    const noteText = note.noteText;

                    tableData.push([moduleName, contentName, noteText]);
                    tableData.push(["", "", ""]); // Empty row to add space after each note

                    moduleHasNotes = true;
                });
            });

            if (moduleHasNotes) {
                tableData.push(["", "", ""]); // Empty row to add space after each module
            }
        });

        const headers = [["Module Name", "Content Name", "Notes"]];

        doc.autoTable({
            startY: 40,
            head: headers,
            body: tableData,
        });

        doc.save("content_notes.pdf");
        doc.save(`${courseStructure?.label}_notes.pdf`);
    };

    const handleGeneratePDF = () => {
        generatePDF(mergedList);
    };



    return (
        <div>
            <Modal
                size="xl"
                centered
                show={showContentNotes}
            >
                <Modal.Header>
                    <h4>Course Notes:</h4>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {notesLoader ?
                            (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                    <Circles
                                        height={40}
                                        width={40}
                                        color="#4fa94d"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            )
                            :
                            (
                                <Row style={{ padding: "10px" }}>
                                    <Col lg={4} className='note-component-course-structure'>
                                        {renderFolders(mergedList)}
                                    </Col>

                                    <Col lg={8} className='note-component-notes-list'>

                                        {mergedList && selectedContent ? (
                                            <div>
                                                {selectedContent?.notes.length > 0 ? (
                                                    <div>
                                                        {
                                                            selectedContent.notes.map((note) => (
                                                                <div
                                                                    id={note.noteId}
                                                                    className='note-component-note'
                                                                >
                                                                    <textarea
                                                                        className='note-component-note-textarea'
                                                                        placeholder=''
                                                                        value={note.noteText}
                                                                        contentEditable={false}
                                                                        inputMode='none'
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='note-component-notes-notfound'>
                                                        <p>No Notes Found</p>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className='note-component-notes-notfound'>
                                                <p>Please Select a Content</p>
                                            </div>
                                        )
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        mergedList.length > 0
                        && (

                            <Button
                                size='sm'
                                variant='success'
                                onClick={handleGeneratePDF}
                            >
                                Download Notes
                            </Button>
                        )
                    }
                    <Button
                        size='sm'
                        variant='warning'
                        onClick={handleModalClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NoteComponent