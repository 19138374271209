import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import service from "../../services/service";
import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FilterDataTable from "../instructor/FilterDataTable";
import ProgressBar from "@ramonak/react-progress-bar";
import { Circles } from "react-loader-spinner";


export const LearnerCourseWiseContentWatchStatus = ({ userId, courseId, modalStatus, setModalStatus }) => {

    const { t } = useTranslation();

    const [userCourseContentReportList, setuserCourseContentReportList] = useState([]);
    const [filterTextContentSummary, setFilterTextContentSummary] = useState("");
    const [isContentSummaryLoading, setIsContentSummaryLoading] = useState(false);
    const [showSummary, hideSummary] = useState(false);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    const toggleSummary = () => {
        setModalStatus(false);
    }

    const convertTimestampToDate = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    const contentSummarySearchBox = useMemo(() => {
        const handleClear = () => {
            if (filterTextContentSummary) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterTextContentSummary("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterTextContentSummary(e.target.value)}
                onClear={handleClear}
                filterTextContentSummary={filterTextContentSummary}
            />
        );
    }, [filterTextContentSummary]);

    useEffect(() => {
        if (modalStatus === true) {
            getUserCourseContentWatchReport();
        }
    }, [modalStatus])


    const getUserCourseContentWatchReport = () => {
        setIsContentSummaryLoading(true);

        service.getUserCourseContentDetails(userId, courseId)
            .then((res) => {
;
                setuserCourseContentReportList(res.data);
            })
            .catch((err) => {
;
            })
        setIsContentSummaryLoading(false);

    }

    let userCourseContentReportfilteredList = [];
    userCourseContentReportfilteredList = userCourseContentReportList?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterTextContentSummary.toLowerCase()) !== -1
    );

    const userContentColumn = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.cname,
            width: "350px",
            sortable: true
        },
        // {
        //     name: "Progress",
        //     selector: row => row.activityProgress,
        //     width: "150px"

        // },
        {
            name: "Progress",
            selector: row => row.activityProgress,
            sortable: true,
            wrap: true,
            width: '300px',
            cell: (row) => (
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProgressBar
                                completed={row.activityProgress || 1}
                                baseBgColor="#e0e0de"
                                maxCompleted={100}
                                bgColor={row.activityProgress === 100 ? "#11B67A" : "#ffae4a"}
                                height='20px'

                                animateOnRender={true}
                                isLabelVisible={true}
                                transitionTimingFunction={'ease-in-out'}
                                labelColor='#ffffff'
                                labelSize='10px'
                            />
                        </Col>
                    </Row>
                </Container>
            ),

        },
        {
            name: "Type",
            selector: row => row.contentType,
            width: "150px"
        },
        {
            name: "Published Date",
            selector: row => convertTimestampToDate(row.publishDate),
            width: "250px",
            sortable: true
        },
        {
            name: "Last Accessed on",
            selector: row => convertTimestampToDate(row.lastAccessed),
            width: "250px",
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.activityStatus,
            width: "150px"
        }
    ];

    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                paddingLeft: '0 8px',
                marginLeft: '10px',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                // paddingLeft: '0 8px',
                // marginLeft: '10px'
            },
        },
    };


    return (
        <Modal
            centered
            show={modalStatus}
            onHide={toggleSummary}
            backdrop="static"
            size="xl"
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', position: "relative" }}>
                        <span className='text-success'>Content </span>
                        <span className='text-secondary'>Details</span>
                        <span style={{ position: 'absolute', left: "38.5%", bottom: "-3px", width: '30%', height: '2px', backgroundColor: '#28a745' }}></span>
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isContentSummaryLoading && (<div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000, // Ensure it's above other elements
                        }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
                        </div>
                    </div>)
                }
                {userCourseContentReportList.length > 0 && (
                    <div className="card my-3 p-3" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>
                        <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                            <DataTable
                                columns={userContentColumn}
                                data={userCourseContentReportfilteredList}
                                defaultSortField="Name"
                                defaultSortAsc={true}
                                striped
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={contentSummarySearchBox}
                            />
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "right" }}>
                <Button variant="danger" onClick={toggleSummary}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
