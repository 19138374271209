import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip, Container } from 'react-bootstrap'
import instructorService from '../../../services/instructorService';
import swal from 'sweetalert';
import "./UpdateCourseDuration.css";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';
import { Circles } from 'react-loader-spinner';


const UpdateCourseDuration = ({ courseId }) => {

  const history = useHistory();

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const currentDate = formatDate(new Date());

  const [courseDuration, setCourseDuration] = useState(0);
  const [courseDurationDate, setCourseDurationDate] = useState(null)
  const [courseDurationData, setCourseDurationData] = useState([]);
  const [courseData, setCourseData] = useState({});

  const [loader, setLoader] = useState(false);

  const [errors, setErrors] = useState({});

  const onDurationChange = (duration) => {

    setCourseDuration(duration);
    setCourseDurationDate(formatDate(moment(formatDate(courseData?.closingDate)).add(duration, 'days')));
  }

  const onDurationBlur = () => {
    const errors = {};
    if (isNaN(courseDuration)) {
      errors.duration = "Duration must be a number";
    }
    setErrors(errors);
  }

  useEffect(() => {
    fetchCourseDurationData();
    fetchCourseData();
  }, [courseId])


  const fetchCourseDurationData = () => {
    setLoader(true);
    instructorService.getCourseDuartionUpdateData(courseId)
      .then((res) => {
        if (res.status === 200) {
          setCourseDurationData(res.data);
        } else {
          swal("Error", "Unexpected Error! Contact Admin", "error");
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || err.message || "An unexpected error occurred";
        swal("Error", errorMessage, "error");
      })
      .finally(() => {
        setLoader(false);
      })
  }

  const fetchCourseData = () => {
    setLoader(true);
    instructorService.getCourseById(courseId)
      .then((res) => {
        if (res.status === 200) {
          setCourseData(res.data);
          setCourseDurationDate(formatDate(res.data?.closingDate));
        } else {
          swal("Error", "Unexpected Error! Contact Admin", "error");
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || err.message || "Server Error, please try again later";
        swal("Error", errorMessage, "error");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const convertTimestampToDate = (timestamp) => {
    return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
  };

  const durationColumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      wrap: true,
      width: '150px',
    },
    {
      name: "Duration(Days)",
      selector: row => row.duartion,
      wrap: true,
      sortable: true,
      width: '150px',
    },
    {
      name: "Updated On",
      selector: row => convertTimestampToDate(row.updatedOn),
      sortable: true,
      wrap: true,
      width: '250px',
      // right: true
    }
  ];

  const courseDurationDateChangeHandler = (value) => {
;

    let currentEndDateTime = moment(formatDate(courseData?.closingDate))
    let newDateTime = moment(value)

    let diffDuration = newDateTime.diff(currentEndDateTime, 'days')
    setCourseDuration(diffDuration)

;

    setCourseDurationDate(moment(value));
  }


  const courseDurationDateBlurHandler = () => {

  }

  const onSubmitButtonClick = () => {
    setLoader(true);

    if (Object.keys(errors).length !== 0) {
      swal("Warning", "Please Check the duration again", "warning")
    } else {
      instructorService.updateCourseDuration(courseId, courseDuration)
        .then((res) => {
          if (res.status === 200) {
            swal("Success", "Successfully Updated the Schedules", "success")
            fetchCourseDurationData();
            fetchCourseData();
          } else {

          }
        })
        .catch((err) => {
          swal("Error", err, "error");
        })
        .finally(() => {
          setLoader(false);
        })
    }
    setCourseDuration(0);
    setCourseDurationDate(null);
  }

  const handleGoBack = () => {
    history.push({
      pathname: '/ViewCourses',
      state: { showCourseEditModal: true, courseId }
    });
  };

  return (
    <div className='main-course-duration-update-page'>

      {
        (loader === true) && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.4)', // Translucent background
            zIndex: 9999, // Ensure the overlay is on top
            height: "150vh"
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Circles
                height={40}
                width={40}
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        )
      }
      <Row>
        <Col md={5}>
          <div className='course-duration-update-left'>
            <div className='input-area-duration'>
              <label className='label-duration'>Course Duration Extend(in Days):
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Enter the Duration by which you want to extend the Course Duration (in Days)
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="number"
                className="courseDuration-form"
                value={courseDuration}
                onChange={(e) => onDurationChange(e.target.value)}
                onBlur={() => onDurationBlur()}
                placeholder={"Enter the Course Commencement Date"}
              />
              {errors?.duration && <span className="error-message">{errors?.duration}</span>}
              <div className='duration-date-separation'>OR</div>
              <input
                type="date"
                className="endDate"
                value={formatDate(courseDurationDate)}
                onChange={(e) => courseDurationDateChangeHandler(e.target.value)}
                onBlur={() => courseDurationDateBlurHandler()}
                placeholder={"Enter the Course Commencement Date"}
              />

            </div>
            <div className='submit-button-area'>
              <div className='d-flex justify-content-end'>
                <Button
                  size='lg'
                  className='submit-button mr-2 btn-success' // Add margin-right for spacing
                  onClick={onSubmitButtonClick}
                  disabled={courseDuration == 0}
                >
                  Submit
                </Button>
                <Button
                  size='lg'
                  className='goback-button btn-info'
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
              </div>
            </div>
            <div className='input-area-enddate'>
              <label className='label-publish'>Current Course End Date:
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-enddate">
                      Current Course End Date for reference
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="date"
                className="enddate-form"
                value={formatDate(courseData?.closingDate)}
                disabled
              />
            </div>
          </div>
        </Col>
        <Col md={7}>
          <DataTable
            columns={durationColumns}
            data={courseDurationData}
            pagination
          />
        </Col>

      </Row>
    </div>
  )
}

export default UpdateCourseDuration