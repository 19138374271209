import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import StickyMenu from '../../components/common/StickyMenu';
import adminServices from '../../services/adminServices';
import service from '../../services/service';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Modal from '../employeeNomination/Modal';
import { Styles } from "../../components/styles/nomination.js";
import Modal from '../shared/GlobalModal.js';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    },
    // {
    //   code: 'te',
    //   name: 'Telugu',
    //   country_code: 'in'
    // },
    {
        code: 'pu',
        name: 'Punjabi',
        country_code: 'in'
    },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]



export default function AssignOrRemoveInstructors() {
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        UserService.generateToken();
    }, []);
    let UserId = UserService.getUserid();

    const defaultState = {
        setInstructorList: [],
        setCourseList: [],
        validCourse: true,
        validInstructor: true,
        selectedInstructorId: "",
        selectedCourseId: "",
        courseInstructorList: [],
        showInstTable: false,
        showModal: false


    }
    const [getData, setData] = useState(defaultState)
    useEffect(() => {
        instructorListFuction();
        getCourseList();
    }, [getData.selectedCourseId])

    // const [instId, setInstId] = useState('');
    const customStyles = {
        title: {
            style: {
                fontColor: 'red',
                fontWeight: '900',
            }
        },
        headCells: {
            style: {
                fontSize: '17px',
                fontWeight: '500',
                paddingLeft: '0 8px',
                marginLeft: '10px',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                // paddingLeft: '0 8px',
                // marginLeft: '10px'
            },
        },
    };

    const instCourseColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            wrap: true,

        },
        {
            name: "Email Id",
            // selector: row => row.courseDetails.enrollSdate,
            selector: row => row.email,
            wrap: true,
        },
        {
            name: "Instructor Name",
            selector: row => row.firstName,
            wrap: true,

        },
        {
            name: "Course Owner",
            selector: row => row.courseOwner === 1 ? <span className='text-success font-weight-bold'>YES</span> : <span className='text-danger font-weight-bold'>No</span>,
            wrap: true,
        },

        {
            name: "Action",
            // selector: row => row.courseDetails.enrollEdate,
            selector: (row) => (
                <Button className='btn-danger' onClick={() => { onClickRemove(row) }} disabled={row.courseOwner === 1}>Remove</Button>
            ),
            wrap: true,
        },

    ];
    const instructorListFuction = () => {
        adminServices.getAllInstructors().then((resp) => {
            // setInstructorList(resp.data);
            setData((prevState) => {
                return {
                    ...prevState,
                    setInstructorList: [...(resp.data)]
                }
            })
        }).catch((err) => {

        })
    }

    const getCourseList = () => {
        service.getPublishCourses()
            .then(res => {

                if (res.data.length !== 0) {

                    setData((prevState) => {
                        return {
                            ...prevState,
                            setCourseList: [...(res.data)],

                        }
                    })
                }

            }).catch((err) => {
                toast.error("Server error, Unable to fetch courses!")
            })
    }
    const getMultipleInst = () => {
        adminServices.getCourseMultipleInstructors(UserId, getData.selectedCourseId).then((res) => {
            if (res.data.length === 0) {
                toast.info("Course doesn't contains multiple instructors");
            } else {

                setData((prevState) => {
                    return {
                        ...prevState,
                        courseInstructorList: [...(res.data)],
                        showInstTable: true
                    }
                })

            }

        }).catch((err) => {

        })
    }
    const assignMultipleInstructor = () => {
        let allFieldsValid = true;
        if (getData.selectedCourseId === undefined || getData.selectedCourseId === "Select Course" || getData.selectedCourseId === "") {
            setData((prevState) => {
                return {
                    ...prevState,
                    selectedCourseId: "",
                    validCourse: false
                }
            })
            allFieldsValid = false
        }
        if (getData.selectedInstructorId === undefined || getData.selectedInstructorId === "Select Instructor" || getData.selectedInstructorId === "") {
            setData((prevState) => {
                return {
                    ...prevState,
                    selectedInstructorId: "",
                    validInstructor: false
                }
            })
            allFieldsValid = false
        }
        if (allFieldsValid) {
            //serivice call

            setData((prevState) => {
                return {
                    ...prevState,
                    showModal: true
                }
            })
            const instId = {
                "instId": getData.selectedInstructorId
            }
            let authorId = ""
            getData.courseInstructorList.map((inst) => {
                if (inst.courseOwner === 1) {

                    authorId = inst.learnerUsername;
                }
            })
            const instAssessment = {
                "authorId": authorId,
                "courseId": getData.selectedCourseId,
                "allowedAuthorIds": {
                    [getData.selectedInstructorId]: true,
                }
            }
            adminServices.assignMultipleInstructor(UserId, getData.selectedCourseId, instId).then((res) => {

                if (res.status === 200) {
                    if (res.data?.status === 406) {
                        toast.error("Invalid Instructor Details!")

                    } else if (res.data?.status === 409) {


                        toast.info("Instructor Already Assigned!")
                    } else if (res.data?.status === 401) {
                        toast.error("Unauthorized User!")
                    }
                    else {

                        getMultipleInst()

                        adminServices.assignMultipleInstructorToAssessment(instAssessment).then((res) => {
                            if (!res.data) {
                                toast.error("Fail to Assign Intructor to Quiz")
                            }
                        }).catch((err) => {
                            toast.error("Internal Server Error at Quiz!")
                        })
                        toast.success("Instructor Assigned Successfully!")
                    }
                    setData((prevState) => {
                        return {
                            ...prevState,
                            showModal: false
                        }
                    })
                }
            }).catch((err) => {
                toast.error("Internal Server Error!")
            })


        }
    }
    const onClickRemove = (row) => {

        // alert("hello")
        const instId = {
            "instId": row.learnerUsername
        }
        let authorId = ""
        getData.courseInstructorList.map((inst) => {
            if (inst.courseOwner === 1) {

                authorId = inst.learnerUsername;
            }
        })
        const instAssessment = {
            "authorId": authorId,
            "courseId": getData.selectedCourseId,
            "allowedAuthorIds": {
                [row.learnerUsername]: true,
            }
        }
        setData((prevState) => {
            return {
                ...prevState,
                showModal: true,
                showInstTable: false,

            }
        })
        adminServices.removeMultipleInstructor(UserId, getData.selectedCourseId, instId).then((res) => {

            if (res.status === 200) {
                if (res.data?.status === 404) {
                    toast.error("Instructor not found!")

                } else if (res.data?.status === 401) {
                    toast.error("Unauthorized User!")
                }
                else {

                    getMultipleInst()
                    adminServices.removeMultipleInstructorFromAssessment(instAssessment).then((res) => {
                        if (!res.data) {
                            toast.error("Fail to Remove Intructor from Quiz")
                        }
                    }).catch((err) => {

                    })

                    toast.success("Instructor Removed Successfully!")
                }
                setData((prevState) => {
                    return {
                        ...prevState,
                        showModal: false,

                    }
                })
            }
        }).catch((err) => {

        })
    }
    const onInstructorChange = (e) => {

        if (e.target.value !== "Select Instructor") {
            setData((prevState) => {
                return {
                    ...prevState,
                    selectedInstructorId: e.target.value,
                    validInstructor: true
                }
            })
        } else {
            setData((prevState) => {
                return {
                    ...prevState,
                    selectedInstructorId: "",
                    validInstructor: false
                }
            })
        }

    }

    const onCourseChange = (e) => {
        if (e.target.value !== "Select Course") {


            setData((prevState) => {
                return {
                    ...prevState,
                    selectedCourseId: e.target.value,
                    validCourse: true,
                    showInstTable: false,
                }
            })
            adminServices.getCourseMultipleInstructors(UserId, e.target.value).then((res) => {
                if (res.data.length === 0) {
                    toast.info("Course doesn't contains multiple instructors");
                } else {

                    setData((prevState) => {
                        return {
                            ...prevState,
                            courseInstructorList: [...(res.data)],
                            showInstTable: true
                        }
                    })

                }

            }).catch((err) => {
                toast.error("Internal Server Error!");

            })
        } else {
            setData((prevState) => {
                return {
                    ...prevState,
                    selectedCourseId: "",
                    validCourse: false,
                    courseInstructorList: [],


                }
            })
        }
    }


    return (
        <Styles>
            <div className="container-scroller">
                {/* {//console.log(instId)} */}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {
                    getData.showModal &&
                    <div className='modal-loader'>
                        <Modal showModal={getData.showModal} />
                    </div>

                }
                <Navbar />
                <StickyMenu />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title">Assign or Remove Instructors from Course</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('instructor_details')}</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Assign or Remove Instructors from Course</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className='bg-white p-4 rounded'>
                                <div className=''>
                                    <h3 className="page-title p-3 cursor-pointer " >
                                        <span className=''>Assign/Remove Instructors</span>
                                    </h3>
                                    <div class="d-flex flex-row align-items-baseline w-100 bg-light p-4 ">
                                        <Form.Group className='w-100 d-flex flex-row my-3 mr-2'>
                                            <div className='w-100 mr-2'>
                                                <Form.Control className={`${!getData.validCourse ? "border-danger" : ""} border  p-1 `} onChange={(e) => { onCourseChange(e) }} as='select'>
                                                    <option selected>Select Course</option>
                                                    {
                                                        getData.setCourseList?.map((courseData) => {
                                                            return (
                                                                <>
                                                                    <option value={courseData.courseId}> {courseData.courseName}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                                <span className={`${!getData.validCourse ? "d-block text-danger  p-1" : "d-none"}`}>Please Select the Course</span>
                                            </div>
                                            <div className='w-100'>
                                                <Form.Control className={`${!getData.validInstructor ? "border-danger" : ""} border p-1`} onChange={(e) => { onInstructorChange(e) }} as='select'>
                                                    <option selected>Select Instructor</option>
                                                    {
                                                        getData.setInstructorList?.map((inst) => {
                                                            return (
                                                                <>
                                                                    <option value={inst.learnerUsername}> {inst.firstName} {inst.lastName}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                                <span className={`${!getData.validInstructor ? "d-block text-danger  p-1" : "d-none"}`}>Please Select the Instructor</span>
                                            </div>

                                        </Form.Group>
                                        <Button className='btn-success' onClick={() => { assignMultipleInstructor() }}>Assign</Button>

                                    </div>


                                    {
                                        getData.showInstTable && <DataTable
                                            columns={instCourseColumns}
                                            data={getData.courseInstructorList}
                                            defaultSortField="Name"
                                            defaultSortAsc={true}
                                            striped
                                            pagination
                                            highlightOnHover
                                            customStyles={customStyles}
                                            subHeader
                                        // subHeaderComponent={subHeaderComponent}
                                        // onRowClicked={handlerRowClick}
                                        />
                                    }





                                </div>
                            </div>
                            {/* <div className='bg-white p-4 rounded'>
                            <div className=''>
                                <h3 className="page-title d-block p-3 cursor-pointer">Remove Instructors</h3>
                                <div class="d-flex flex-row align-items-end w-100 bg-light p-4 ">
                                    <Form.Group className='w-100 d-flex flex-row my-3 mr-2'>
                                        <Form.Control onChange={(e) => { }} as='select'>
                                            <option className='text-grey' selected> Select Course</option>
                                            {
                                                instructorList.map((inst) => {
                                                    return (
                                                        <>
                                                            <option value={inst.learnerUsername}> {inst.firstName} {inst.lastName}</option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Button className='my-3 btn-danger' onClick={() => { onClickRemove() }}>Remove</Button>
                                </div>
                            </div>
                        </div> */}
                            <SettingsPanel />
                        </div>
                        <Footer />
                    </div>

                </div>
            </div>
        </Styles>


    );
}



