import React, { useEffect, useState } from 'react';
import "./ContentDeliveryRatingSideBar.css";
import { Rating } from 'react-simple-star-rating'
import service from '../../../../services/service';
import swal from 'sweetalert';
import UserService from '../../../../services/UserService';
import { Circles } from 'react-loader-spinner';

const ContentDeliveryRatingSideBar = ({ selectedContent, toggleRatingSidebar, ratingSideBarOpen, setRatingSideBarOpen }) => {

  // const [ratingSideBarOpen, setRatingSideBarOpen] = useState(false);

  const [ratingLoader, setRatingLoader] = useState(false);
  const [newContentRating, setNewContentRating] = useState(0);
  const [newContentReview, setNewContentReview] = useState('');

  const [contentRatingReview, setContentRatingReview] = useState(null);

  let userId = UserService.getUserid();

  useEffect(() => {
    if (selectedContent.id) {
      getRatingBasedOnContentId();
    }
    setNewContentRating(0);
    setNewContentReview("");
    setRatingSideBarOpen(false);
  }, [selectedContent]);

  useEffect(() => {
    if (contentRatingReview) {
      setNewContentRating(contentRatingReview.rating);
      setNewContentReview(contentRatingReview.reviewText);
    }
  }, [contentRatingReview]);

  const getRatingBasedOnContentId = () => {
    setRatingLoader(true);
    service.getRatingByItemIdUserIdReviewTypeTenantId(selectedContent.id, 1, 2)
      .then((res) => {
;
        setContentRatingReview(res.data[0]);
        setRatingLoader(false);
      })
      .catch((err) => {
        setRatingLoader(false);

        swal("", "Unable to fetch Rating and review for the content", "error");
      })
  }

  // const toggleRatingSidebar = () => {
  //   setRatingSideBarOpen(!ratingSideBarOpen);
  // }

  const handleRating = (rate) => {
    setNewContentRating(rate)
  }

  const handleReviewChange = (event) => {
    setNewContentReview(event.target.value);
  };

  const handleSubmitRatingAndReview = () => {
    setRatingLoader(true);
    let ratingAndReview = {
      reviewId: contentRatingReview?.reviewId,
      learnerId: userId,
      rating: newContentRating,
      reviewText: newContentReview,
      itemId: selectedContent.id,
      reviewStatus: 'Done',
      reviewType: 2,
      tenantId: 1,
    };
    if (contentRatingReview == null || contentRatingReview == undefined) {

      service.createrating(ratingAndReview)
        .then((res) => {
          if (res.status == 200) {
            swal("", "Submited the Review and Rating", "success");
            setRatingSideBarOpen(false);
            setRatingLoader(false);
            getRatingBasedOnContentId();
          }
        })
        .catch((err) => {
          swal("", "Unable to Submit the Review and Rating", "error");
          setRatingLoader(false);
        })
    } else {
      service.updaterating(ratingAndReview)
        .then((res) => {
          if (res.status == 200) {
            swal("", "Updated the Review and Rating", "success");
            setRatingSideBarOpen(false);
            setRatingLoader(false);
            getRatingBasedOnContentId();
          }
        })
        .catch((err) => {
          swal("", "Unable to Update the Review and Rating", "error");
          setRatingLoader(false);
        })
    }
;
  }

  return (
    (Object.keys(selectedContent).length !== 0) && (selectedContent?.activityProgressforDependency === 100) && (
      <div
        id='content-delivery-rating-sidebar-main'
        className='content-delivery-rating-sidebar-main'
      >
        <div
          id='content-delivery-rating-sidebar-main'
          className={`content-delivery-rating-sidebar ${ratingSideBarOpen ? "open" : ""}`}
        >
          <div
            id='content-delivery-rating-sidebar-main'
            className='content-delivery-rating-sidebar-contents'
          >
            <p className='content-delivery-rating-sidebar-title'>
              <button
                className='content-delivery-rating-sidebar-close-button'
                onClick={toggleRatingSidebar}
              >
                <i class="fa fa-2x fa-times" aria-hidden="true"></i>
              </button>
              Rating and Review
            </p>

            <div className='content-delivery-rating-sidebar-rating-stars'>
              <Rating
                onClick={handleRating}
                transition={true}
                allowFraction={true}
                size={30}
                initialValue={newContentRating}
              />
            </div>
            <textarea
              className='content-delivery-rating-sidebar-review-textbox'
              placeholder='Write your review here...'
              value={newContentReview}
              onChange={handleReviewChange}
            />
            <div
              className={`content-delivery-rating-sidebar-submit-button`}
              title='Submit rating and Review'
            >
              {
                ratingLoader ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Circles height={25} width={25} color="white" ariaLabel="circles-loading" />
                  </div>
                ) :
                  <i
                    className="fa fa-paper-plane"
                    aria-hidden="true"
                    onClick={handleSubmitRatingAndReview}
                  >
                  </i>
              }
            </div>
          </div>
        </div>

      </div >
    )
  )
}

export default ContentDeliveryRatingSideBar;
