import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Modal, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import ReactPlayer from 'react-player';
import cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import UserService from '../../services/UserService.js';
import learnerService from '../../services/learnerService.js';
import instructorService from '../../services/instructorService.js';
import service, { URI } from '../../services/service.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import HeaderTwo from '../../components/HeaderTwo.js';
import FooterTwo from '../../components/FooterTwo.js';
import CourseSearch from './components/CourseSearch.js';
import ViewPdf from '../instructor/ViewPdf.js';
import MediaContentView from './components/MediaContentView.js';
import { PreviewModal } from '../instructor/instCourses/CourseStructureDriveNew.js';
import { useTranslation } from 'react-i18next';
import './styles/libraryDetailsNew.css';
import { Rating } from 'react-simple-star-rating';
import SideDrawer from './components/SideDrawer.js';
import { Circles } from 'react-loader-spinner';


const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        },
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
        },
    },
};

const languages = [
    { code: 'en', name: 'English', country_code: 'gb' },
];

function LibraryDetails(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const [contentData, setContentData] = useState([]);
    const [libraryName, setLibraryName] = useState('');
    const [viewCount, setViewCount] = useState([]);
    const [urlModal, setUrlModal] = useState(false);
    const [previewContent, setPreviewContent] = useState({ contentType: '', url: '', videoEncodeStatus: null });
    const [getLibContentStatus, setLibContentStatus] = useState({})
    const [videoEncodeStatus, setVideoEncodeStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const value = useSelector(state => state.inputValue);

    const decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 32))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    let userId = UserService.getUserid();
    let sessionId = UserService.getSessionId();
    let courseID = props.match.params.id;
    let tenantID = props.match.params.tid;
    let hashcode = tenantID.substring(0, 10);
    const myDecipher = decipher(`${hashcode}`);
    let CID = courseID.substring(10);
    let TID = tenantID.substring(10);
    let courseId = myDecipher(`${CID}`);
    let tenantId = myDecipher(`${TID}`);
    let currentCourseState;
    if (value) {
        let data = contentData.filter((course) =>
            course.label.toLowerCase().includes(value)
        )
        currentCourseState = data.slice(0, 1000);
    } else {
        currentCourseState = contentData.slice(0, 1000);
    }
    useEffect(() => {
        UserService.generateToken();
        getContentList();
        getViewCount();
        handleGetApproveRequests()
        document.title = t('app_title');
    }, [t]);
    const onHide = () => {
        setUrlModal(false)
    }
    const getContentList = useCallback(() => {
        setLoading(true)
        learnerService.getLibraryStructureById(courseId).then(resp => {
            setContentData(resp.data.nodes);
            setLibraryName(resp.data.label);
            setLoading(false)
            scrollWin()
        }).catch(err => {
            setLoading(false)
            scrollWin()
            console.error(err)
        });
    }, [courseId]);

    const getViewCount = useCallback(() => {
        service.getCourseViewCount(courseId).then(res => setViewCount(res.data)).catch(err => console.error(err));
    }, [courseId]);


    const handleContentPreview = useCallback(async (url, contentType, contentLabel, id, encodeStatus) => {
        try {
            const response = await instructorService.contentAccess(URI.SERVER?._DMS_URL + url);
            let finalUrl = response.data;

            if (contentType === 'pdf') {
                const encrypted = CryptoJS.AES.encrypt("/" + URI.SERVER._MEGH_API + "/" + response.data, "cdac@123").toString();
                finalUrl = URI.SERVER._DMS + "/" + encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
            }

            setPreviewContent({ contentType, url: finalUrl, videoEncodeStatus: encodeStatus });
            setUrlModal(true);
            service.saveContentVisit(userId, courseId, id, contentLabel, sessionId, contentType);
        } catch (error) {
            swal(`${t("error")}`, `${error} ${t("try_sometimes")}`, "error");
        }
    }, [courseId, sessionId, t, userId]);

    const changePage = ({ selected }) => setPageNumber(selected);
    const pageCount = Math.ceil(contentData.length / usersPerPage);
    const displayedContent = currentCourseState.slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage);

    const [showDrawer, setShowDrawer] = useState(false);
    const [getContentId, setContentId] = useState()
    const handleShowDrawer = (contentId) => {

        setShowDrawer(true)
        setContentId(contentId)
    };

    //Average rating and reviews
    const [getAllRatingReview, setAllRatingReview] = useState([])
    const [getAvgRating, setAvgRating] = useState([]);

    useEffect(() => {

        // if (rating !== " ") {
        service.averageRating(getAllRatingReview)
            .then(res => {
                setAvgRating(res.data);
            })
        // }
    }, [getAllRatingReview]);


    useEffect(() => {
        ratingTest();

    }, [contentData])
    const ratingTest = () => {

        contentData.map((data, i) => {


            setAllRatingReview(rating => [...rating, { itemId: data.id, reviewType: 2, tenantId: 1 }])
        })
    }
    const handleWatingForAdminAppproval = (contentId) => {
        const remarks = getLibContentStatus[contentId.toString()]?.remarks
        if (getLibContentStatus[contentId.toString()]?.status === 'P') {

            swal('Remarks', remarks === null ? "Request Rejected!" : `Request Rejected :${remarks}`, "warning")
        }
        else {
            swal('Warning', "Waiting for Admin Approval!", "warning")
        }
    }


    const handleRequestForAdminApproval = useCallback(async (contentId, contentType) => {
        const libData = {
            userId,
            contentId,
            courseId,
            contentType,
            status: 'P'
        };

        try {
            const status = getLibContentStatus[contentId.toString()]?.status;

            if (status === 'R') {
                const remarks = getLibContentStatus[contentId.toString()]?.remarks;
                await swal('Remarks', remarks || "Request Rejected!", "warning");
            }

            if (status === undefined || status === 'R') {
                const res = await service.requestforApproveLibContent(libData);
                if (res.status === 200) {
                    await handleGetApproveRequests();
                    swal(t("success"), 'Request Sent Successfully!!', "success");
                }
            }
        } catch (err) {
            console.error("Error!", err);
            swal(t("error"), t("try_sometimes"), "error");
        }
    }, [userId, courseId, getLibContentStatus, t]);

    const handleGetApproveRequests = useCallback(async () => {
        await service.getLibContentStatus(userId, courseId).then((res) => {

            if (res.status === 200) {
                setLibContentStatus(res.data)
            }
        }).catch((err) => {
            //console.log("Error ", err)
        })
    }, [courseId])

    if (loading) {
        return <div
            style={{
                position: 'fixed', // Ensures the div stays on top of other content
                top: 0,
                left: 0,
                width: '100vw', // Covers the entire viewport width
                height: '100vh', // Covers the entire viewport height
                backgroundColor: 'rgba(255, 255, 255, 0.562)', // Semi-transparent background
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Centers the loading indicator vertically
                alignItems: 'center', // Centers the loading indicator horizontally
                zIndex: 999, // Ensures the div is on top of other elements
            }}
        >
            <Circles
                height={40}
                width={40}
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <span style={{ color: '', marginTop: '20px' }}>Loading, please wait...</span>
        </div>;
    }
    const scrollWin = () => {
        //document.getElementById('Main').scrollIntoView({behavior:'smooth'})
        window.scrollTo({
            top: 290,
            behavior: 'smooth'
        });
    }
    return (
        <div className="main-wrapper course-details-page">
            <HeaderTwo />
            <BreadcrumbBox title={libraryName} />
            <br /><br />
            <div id="Tab">
                {UserService.hasRole(['learner']) ? (
                    <Container>
                        {/* <pre>{JSON.stringify(getLibContentStatus, null, 2)}</pre> */}
                        <CourseSearch name={t('search_material')} />
                        <br />
                        <Row>
                            {
                                displayedContent.length === 0 && <div className='w-100'>

                                    <Alert variant="danger ">
                                        <Alert.Heading className=''>Library Update Pending!</Alert.Heading>
                                        <p>
                                            Content is currently unavailable...
                                        </p>
                                        <hr />
                                        <p className="mb-0">
                                            Please contact the library Author to publish content.
                                        </p>
                                    </Alert>
                                </div>
                            }
                            {displayedContent.map((content, i) => (
                                <Col key={i} className='mb-4' lg={3}>
                                    <div className='card text-center shadow card-custom '>
                                        <p className='text-right bg-light mr-3'>
                                            {/* <i className="fas fa-eye text-success"></i> {viewCount.find(vc => vc.resId === content.key)?.count || 0} */}
                                            {content?.accessType === 'restrict' && <div
                                                className="p-1 rounded-circle"
                                                style={{ position: "absolute", top: "0", left: "-3px", background: "white", color: "#182B49" }}
                                            >
                                                {/* <div>{t('restrict_content')}</div> */}
                                                {getLibContentStatus[content?.id.toString()]?.status === 'A' ? <i class="fa fa-unlock-alt fa-lg text-success" aria-hidden="true"></i> : <i class="fa fa-lock fa-lg" aria-hidden="true"></i>}

                                            </div>}
                                        </p>
                                        <div className='overflow'>
                                            <img
                                                src={process.env.PUBLIC_URL + `/assets/images/${content.nodetype === "pdf" ? "pdf.png" : content.nodetype === "mp4" ? 'VideoFileImage.png' : "FileImage.png"}`}
                                                alt=""
                                                className='card-img-top card-custom-img-top'
                                                style={{ height: "100%", width: "50%", padding: "5px" }}
                                            />
                                        </div>
                                        <div className='card-body text-dark'>
                                            <h4 className='card-title' style={{ fontFamily: "serif", fontWeight: "bold" }}>
                                                {content.label}
                                            </h4>

                                            {
                                                content?.accessType === 'restrict' && (getLibContentStatus[content?.id.toString()]?.status === 'R' || getLibContentStatus[content?.id.toString()]?.status === undefined) //rejected request
                                                    ?
                                                    <Button
                                                        variant='danger'
                                                        className="w-100"
                                                        onClick={() => { handleRequestForAdminApproval(content?.id, content?.nodetype) }}

                                                    >
                                                        Request View
                                                    </Button>

                                                    : content?.accessType === 'restrict' && getLibContentStatus[content?.id.toString()]?.status === 'P' //pending request
                                                        ?
                                                        <Button
                                                            // variant="success"
                                                            style={{ backgroundColor: "#182B49" }}
                                                            className="w-100"
                                                            onClick={() => { handleWatingForAdminAppproval(content?.id) }}
                                                        >
                                                            <small>Waiting for Admin Approval</small>
                                                        </Button>
                                                        : //accepted request or open content
                                                        <Button
                                                            // variant="success"
                                                            className="w-100 create-button"
                                                            onClick={() => handleContentPreview(content.filePath, content.nodetype, content.label, content.id)}
                                                        >
                                                            {t('view')}
                                                        </Button>

                                            }


                                        </div>

                                        {/* get the rating and reviews */}
                                        <ul className="d-flex w-100 bg-light p-2">
                                            {(() => {
                                                let contentRatingReviewFound = false;
                                                return getAvgRating.map((contentRating, i) => {
                                                    if (content?.id.toString() === contentRating?.itemId) {
                                                        contentRatingReviewFound = true;
                                                        return (
                                                            <li key={i}>
                                                                <Rating
                                                                    className="flex-grow-1"
                                                                    readOnly
                                                                    initialValue={contentRating?.avgScore || 0}
                                                                    size={20}
                                                                    onClick={() => handleShowDrawer(content?.id)}
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                    if (i === getAvgRating.length - 1 && !contentRatingReviewFound) {
                                                        return (
                                                            <li key={i}>
                                                                <Rating
                                                                    className="flex-grow-1"
                                                                    readOnly
                                                                    initialValue={0}
                                                                    size={20}
                                                                    onClick={() => handleShowDrawer(content?.id)}
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                    return null;
                                                }).filter(Boolean);
                                            })()}
                                            <p className="flex-grow-1 text-right">
                                                <i className="fas fa-eye text-success"></i> {viewCount.find(vc => vc.resId === content.key)?.count || 0}
                                            </p>
                                        </ul>
                                        {/* <div className="d-flex w-100  bg-light p-2">
                                            
                                            <Rating
                                                className="flex-grow-1"
                                                readOnly
                                                initialValue={getAvgRating[2]?.avgScore}
                                                size={20}
                                                onClick={() => { handleShowDrawer(content.id) }}
                                            />
                                            <p className="flex-grow-1 text-right ">
                                                <i className="fas fa-eye text-success"></i> {viewCount.find(vc => vc.resId === content.key)?.count || 0}
                                            </p>
                                        </div> */}



                                    </div>
                                </Col>
                            ))}

                        </Row>
                        {contentData.length > usersPerPage && (
                            <Col md="12" className="text-center">
                                <ReactPaginate
                                    previousLabel={t('previous')}
                                    nextLabel={t('next')}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName="paginationBttns"
                                    activeClassName="paginationActive"
                                />
                            </Col>
                        )}
                    </Container>
                ) : UserService.doLogin()}
            </div>
            <PreviewModal
                show={urlModal}
                onHide={onHide}
                contentType={previewContent.contentType}
                url={previewContent.url}
                videoEncodeStatus={videoEncodeStatus}
            />
            <SideDrawer
                showDrawer={showDrawer}
                setShowDrawer={setShowDrawer}
                getContentId={getContentId}
                userId={userId}
                libraryId={courseId}
            />

            <FooterTwo />
        </div>
    );
}

export default LibraryDetails;
