import React, { useEffect, useState, useMemo } from 'react';
import StickyMenu from '../../components/common/StickyMenu';
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import adminServices from '../../services/adminServices';
import learnerService from '../../services/learnerService';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import DataTable from "react-data-table-component";
import service, { URI, USER_API } from '../../services/service';
import swal from 'sweetalert';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';
import jsPDF from 'jspdf';
import { downloadExcel } from 'react-export-table-to-excel';
import axios from 'axios';
import { PreviewModal } from '../../pages/instructor/instCourses/CourseStructureDriveNew';
import { TokenImage } from './TokenImage';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            paddingLeft: '0 8px',
            marginLeft: '10px'
        },
    },
};

function NonVerifiedLearners() {

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])


    useEffect(() => {
        UserService.generateToken();
    }, []);

    const um_api = URI.SERVER?._USER_API;

    const [getLearnerData, setlearnerData] = useState([]);
    const [getLearnerDataStatus, setlearnerDataStatus] = useState(false);
    useEffect(() => {
        LearnersDatas();
    }, [getLearnerDataStatus])

    const LearnersDatas = async () => {
        try {
            let result = await adminServices.getNonVerifiedListofLearner()
            setlearnerData(result.data);
            setlearnerDataStatus(false);
        } catch (e) {
            setlearnerDataStatus(false);
        }
    }

    const [approveLoading, setApproveLoading] = useState({
        isLoading: false
    })

    const sendEmailVerificationLink = (emailid) => {

        adminServices.EmailVerificationLink(emailid)
            .then((res) => {
                if (res.data == "learner-not-exist") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Learner does not exist.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                else if (res.data == "success") {

                    Swal.fire({
                        icon: 'success',
                        title: 'Mail Send Successfully',
                        showConfirmButton: false,
                        timer: 3000
                    })

                }
                else if (res.data == "fail-to-message") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error while sending message, Try after some time.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong, Try after some time.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
            .catch((err) => {
                if (err.data == "fail-to-message") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error while sending message, Try after some time.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong, Try after some time.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }

            })

    }

    const removeLearner = (learnerId) => {

        console.log("learnerId = ", learnerId)

        Swal.fire({
            title: "Please Wait",
            didOpen: () => {
                Swal.showLoading();
                adminServices.removeLearner(UserService.getUserid(), learnerId).then((resp) => {
                    if (resp.data === "success") {
                        Swal.fire({
                            icon: "success",
                            text: "Learner Delete Successfully",
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        LearnersDatas();
                    }
                }).catch((error) => {
                    if (error.status == 401) {
                        Swal.fire({
                            icon: "error",
                            text: "UNAUTHORIZED",
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            text: "Something went Wrong Try Later",
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    }
                })
            }
        })
    }


    const columns = [
        {
            name: "Learner",
            // cell: (row) => <img src={um_api + `public/getprofilepic/${row.learnerUsername}`} style={{ width: '42px', height: '42px', borderRadius: '100%', boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)" }} />,
            cell: (row) => <TokenImage path={row.idproofpicpath} />,
            sortable: true,
            width: "100px",
        },
        // {
        //     name: "S.No",
        //     selector: (row, index) => index + 1,
        //     //width: '100px',
        //     sortable: true,
        // },
        {
            name: "Name",
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            wrap: true,
            //width: '150px',
        },
        {
            name: "Email",
            selector: row => row.email,
            wrap: true,
            sortable: true,
        },
        {
            name: "Mobile",
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: "Action",
            sortable: true,
            wrap: true,
            // width: '300px',
            cell: (row) => (
                <div className="row">
                    {<div>
                        <div className="d-inline-block">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Send Email Verification Link</Tooltip>}>
                                <button onClick={() => sendEmailVerificationLink(row.email)} type="button" class="btn m-2" style={{ background: "green", color: "white" }} disabled={approveLoading.isLoading ? "true" : ""}>
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </OverlayTrigger>
                        </div>

                    </div>}
                    {
                        <>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Remove</Tooltip>}>
                                <button className="btn btn-danger m-2" onClick={() => {removeLearner(row.learnerUsername) }}><i class="fa fa-trash" aria-hidden="true"></i></button>
                            </OverlayTrigger>
                        </>
                    }
                </div>
            )
        }
    ];


    /* Table content Filter and Search */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = getLearnerData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const [ShareUrlModal, setShareUrlModal] = useState({
        show: false
    });

    const [infoLoading, setInfoLoading] = useState({
        isLoading: false
    })


    const shareUrlModalHide = () => {
        setShareUrlModal({ show: false });
        setInfoLoading({ isLoading: false });
    }


    const [getViewID, setViewID] = useState({
        show: false
    });
    const [getViewCertificate, setViewCertificate] = useState({
        show: false
    });
    const [getViewPhoto, setViewPhoto] = useState({
        show: false
    });
    const [IDLoading, setIDLoading] = useState({
        isLoading: false
    })
    const [CertificateLoading, setCertificateLoading] = useState({
        isLoading: false
    })

    const [PhotoLoading, setPhotoLoading] = useState({
        isLoading: false
    })

    const [PhotoEmail, setPhotoEmail] = useState();
    const [IDEmail, setIDEmail] = useState();


    const IDCardHide = () => {
        setViewID({ show: false });
        setIDLoading({ isLoading: false });
        //setInfoLoading({ isLoading: false });
    }
    const ProfilePicHide = () => {
        setViewPhoto({ show: false });
        setPhotoLoading({ isLoading: false });
        //setInfoLoading({ isLoading: false });
    }
    const viewPhoto = (profilepicpath, email) => {
        setPhotoEmail(email);
        setPhotoLoading({ isLoading: true });
        setViewPhoto({
            show: true,
            profilepicpath: profilepicpath,
        });
    }
    const viewID = (idproofpicpath, email) => {
        setIDEmail(email);
        setIDLoading({ isLoading: true });
        setViewID({
            show: true,
            idproofpicpath: idproofpicpath,
        });
    }

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">
                                    Non Verified Learners
                                </h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('learners')}</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Non Verified Learners</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <br></br>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            defaultSortField="Name"
                                            defaultSortAsc={true}
                                            striped
                                            pagination
                                            highlightOnHover
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={subHeaderComponent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

    );
}

export default NonVerifiedLearners;