
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import StickyMenu from '../../components/common/StickyMenu';
import adminServices from '../../services/adminServices';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import UserService from '../../services/UserService';

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import { downloadExcel } from 'react-export-table-to-excel';
import service from '../../services/service';
import ReactSelect from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]


const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900px',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500px',

        },
    },
    cells: {
        style: {
            fontSize: '15px',

        },
    },
};


export default function StudentCompleteReport() {

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    const [data, setData] = useState({
        publishCourse: "",
        courseIds: [],
        getUserReport: []
    });


    useEffect(() => {
        getPublishCourseList();
    }, [])

    let selectedDatanew = [];
    let selectedDatanew2 = [];
    const onSelecteHandler = (selectedData) => {
;
        selectedDatanew = selectedData.map(({ value }) => ({ value }));
;
        for (let i = 0; i < selectedDatanew.length; i++) {
            selectedDatanew2.push(selectedDatanew[i].value)
        }
;
        setData((prev) => {
            return {
                ...prev,
                courseIds: selectedDatanew2
            }
        })
    }

    const getPublishCourseList = () => {
        try {
            service.getPublishCourses().then((resp) => {
                const courseData = resp.data.map((single) => {
                    return {
                        value: single.courseId,
                        label: single.courseName
                    }
                })
                setData((prev) => {
                    return {
                        ...prev,
                        publishCourse: courseData
                    }
                })
            }).catch((err) => {
                swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
            })
        } catch (err) {
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
        }
    }

    const getUserReportsOnClickSubmit = () => {


        if (data.courseIds.length === 0) {
            Swal.fire({
                title: "Please Select the Course's",
                text: "To retrive the User's Reports",
                icon: "info",
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: false,
                timer: 3000
            })
        }
        else {
            service.getUserReports(data.courseIds).then((resp) => {
                //console.log(resp.data)
                if (resp.status === 200) {
                    setData((prev) => {
                        return {
                            ...prev,
                            getUserReport: resp.data
                        }
                    })
                }
            })
        }

    }

    const escapeExcelValue = (value) => {
        if (typeof value === "string" && value.includes("/")) {
            return `'${value}`;
        }
        return value;
    };



    const generateReportColumn = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            wrap: true,
            width: "250px",

        },
        {
            name: "Name",
            selector: row => row.learnerName,
            width: "260px",
            sortable: true,
            wrap: true,

        },
        {
            name: "Date of Birth",
            selector: row => row.dob,
            width: "250px",

        },
        {
            name: "Age",
            selector: row => row.age,
            sortable: true,
            width: "250px",

        },
        {
            name: "Gender",
            selector: row => row.gender,
            sortable: true,
            width: "250px",
        },
        {
            name: "Designation",
            selector: row => row.designation,
            sortable: true,
            width: "250px",
        },
        {
            name: "College Name",
            selector: row => row.institute,
            sortable: true,
            width: "250px",
        },
        {
            name: "State",
            selector: row => row.state,
            sortable: true,
            width: "250px",
        },
        {
            name: "ABC id No.",
            selector: row => row.abcIdNo,
            width: "250px",
        },
        {
            name: "Mobile",
            selector: row => row.mobile,
            width: "250px",
        },
        {
            name: "Email",
            selector: row => row.email,
            width: "250px",
        },
        {
            name: "Date of Registration",
            selector: row => moment(row?.registrationDate).format('MM-DD-YYYY HH:mm'),
            width: "250px",
        },
        {
            name: "Course Name",
            selector: row => row.courseName,
            sortable: true,
            width: "250px",
        },
        {
            name: "Date of Enrollment",
            selector: row => moment(row?.enrollmentDate).format('MM-DD-YYYY HH:mm'),
            width: "250px",
        },
        {
            name: "Date of Completion",
            selector: row => row.completionDate == null ? "Not Yet Completed" : moment(row?.completionDate).format('MM-DD-YYYY HH:mm'),
            width: "250px",
        },
        {
            name: "Pre Test Score",
            selector: row => row.pretestScore,
            width: "250px",
        },
        {
            name: "Post Test Score",
            selector: row => row.postTestScore,
            width: "250px",
        },
        {
            name: "Assignment Status",
            selector: row => row.assignmentStatus,
            width: "250px",
        },
        {
            name: "Total Time Spent",
            selector: row => row.contentAccessTotalTime,
            width: "250px",
        },
        {
            name: "Course Progress",
            selector: row => row.courseProgress,
            width: "250px",
        },
        {
            name: "Progress Status",
            selector: row => row.progressStatus,
            width: "250px",
        },
        {
            name: "Feedback",
            selector: row => row.feedback,
            width: "250px",
        },
        {
            name: "Certificate",
            selector: row => row.certificate,
            width: "250px",
        },
        {
            name: "Credit Points",
            selector: row => row.creditPoints,
            width: "250px",
        },

    ];

    const exportExcel = () => {
        if (data.courseIds.length === 0) {
            Swal.fire({
                title: "Please Select the Course's",
                text: "To retrive the User's Reports",
                icon: "info",
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: false,
                timer: 3000
            })
            return
        }
        var checkData = [];
        const header = ["Name", "Date Of Birth", "Age", "Gender", "Designation", "College Name", "State", "ABC id No.", "Mobile", "Email", "Date of Registration", "Course Name", "Date of Enrollment", "Date of Completion", "Pre Test Score", "Post Test Score", "Assignment Status", "Total Time Spend", "Course Progress", "Progress Status", "Feedback", "Certificate", "Credit Points"]
        data.getUserReport.map((data) => {
            const name = `${data.learnerName}`
            const dob = `${data.dob}`
            const age = `${data.age}`
            const gender = `${data.gender}`
            const designation = `${data.designation}`
            const instituteName = `${data.institute}`
            const state = `${data.state}`
            const abcids = `${data.abcIdNo}`
            const mobile = `${data.mobile}`
            const email = `${data.email}`
            const dateOfRegistration = `${moment(data?.registrationDate).format('MM-DD-YYYY HH:mm')}`
            const courseName = `${data.courseName}`
            const enrollDate = `${moment(data?.enrollmentDate).format('MM-DD-YYYY HH:mm')}`
            const completionDate = `${data.completionDate === null ? "Not yet Completed" : moment(data?.completionDate).format('MM-DD-YYYY HH:mm')}`
            const pretestScore = `${escapeExcelValue(data.pretestScore)}`
            const postTestScore = `${escapeExcelValue(data.postTestScore)}`
            const assignmentStatus = `${escapeExcelValue(data.assignmentStatus)}`
            const contentAccessTotalTime = `${data.contentAccessTotalTime}`
            const courseProgress = `${data.courseProgress}`
            const progressStatus = `${data.progressStatus}`
            const feedback = `${escapeExcelValue(data.feedback)}`
            const certificate = `${data.certificate}`
            const creditPoints = `${data.creditPoints}`
            const userReports = [name, dob, age, gender, designation, instituteName, state, abcids, mobile, email, dateOfRegistration, courseName, enrollDate, completionDate, pretestScore, postTestScore, assignmentStatus, contentAccessTotalTime, courseProgress, progressStatus, feedback, certificate, creditPoints]
            checkData.push(userReports);
        })
        downloadExcel({
            fileName: "UserSummaryReport",
            sheet: "UserSummaryReport",
            tablePayload: {
                header,
                body: checkData,
            },
        })

    }



    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Course Detailed Summary Report</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Reports</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Course Detailed Summary Report</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div className='card-body'>
                                        <div className="pb-5">
                                            <label className='pb-1'>{t('select_courses')}</label>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <ReactSelect options={data.publishCourse} isMulti onChange={onSelecteHandler} />
                                                </div>
                                                <div className='col-6'>
                                                    <button className='btn btn-primary' onClick={() => getUserReportsOnClickSubmit()}>Submit</button>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row pb-5">
                                            <div className="col-md-12 text-center mb-3">
                                                <h4>Generate Report</h4>
                                            </div>
                                        </div> */}
                                        {
                                            data.getUserReport.length !== 0 ?
                                                <>
                                                    <div className='pt-3'>
                                                        <div className='pb-4'>
                                                            <Button onClick={exportExcel} style={{ background: "#f0ad4e", border: "0px" }}> {t('export_to_excel')} </Button>
                                                        </div>
                                                        <div>
                                                            <DataTable
                                                                columns={generateReportColumn}
                                                                data={data.getUserReport}
                                                                pagination
                                                                customStyles={customStyles}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>

        </div>
    );
}


