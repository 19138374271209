import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { htmlToText } from 'html-to-text';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]
export const CustomEditor = ({ content, setContent, type, input, setIsCourseDesValid }) => {
    const editorRef = useRef(null);
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()



    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])
    // course specific for validating the objective, prerequ
    function setError(input, message) {
        const errorMsg = document.getElementById(input);
        if (!errorMsg) {
            console.error(`Element with ID "${input}" not found.`);
            return;
        }
        errorMsg.className = "text-danger form-control border-0 text-left error";
        errorMsg.innerText = message;
    }

    function setSuccess(input) {
        const errorMsg = document.getElementById(input);
        if (!errorMsg) {
            console.error(`Element with ID "${input}" not found.`);
            return;
        }

        errorMsg.className = "float-right my-1";
        errorMsg.innerHTML = "";

        // Create the success icon element
        const icon = document.createElement("i");
        icon.className = "fa fa-check-circle-o text-success fa-2x";
        icon.setAttribute("aria-hidden", "true");
        errorMsg.appendChild(icon);
    }

    const onBlurcourseEditorHandler = (input, newContent) => {
        // const newContent = editorRef.current.getContent();
        const plainText = htmlToText(newContent);
        const regex = /^[A-Za-z0-9&, .\d:+=\-/"'()\n\u0020\u00A0●○■→]*$/;

        // setContent(plainText)
        const courseOeditor = input; // ID of the editor element
        const courseOeditorVal = plainText.trim();

        // Validation Logic
        if (!courseOeditorVal || courseOeditorVal === null || courseOeditorVal === "") {

            setIsCourseDesValid((prevState) => ({
                ...prevState,
                [input]: false
            }))
            return setError(courseOeditor, t('enter_course_object'));
        }

        if (courseOeditorVal.length < 5) {

            setIsCourseDesValid((prevState) => ({
                ...prevState,
                [input]: false
            }))
            return setError(courseOeditor, t('course_objective_min_5_char'));
        }

        if (courseOeditorVal.length > 1500) {

            setIsCourseDesValid((prevState) => ({
                ...prevState,
                [input]: false
            }))
            return setError(courseOeditor, t('course_objective_man_1500_char'));
        }

        if (!regex.test(courseOeditorVal)) {
            setIsCourseDesValid((prevState) => ({
                ...prevState,
                [input]: false
            }))
            return setError(courseOeditor, t('alphabet_digit_special_character_allowed'));
        }

        // All validations passed
        setIsCourseDesValid((prevState) => ({
            ...prevState,
            [input]: true
        }))
        return setSuccess(courseOeditor);
    };
    const onBlurHandler = (input) => {
        const newContent = editorRef.current.getContent();
        setContent(newContent)
        if (type === 'course') {

            onBlurcourseEditorHandler(input, newContent)
        }
    }
    return (
        <div>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL +
                    '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue={content}
                init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image',
                        'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code',
                        'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview',
                        'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdentindent | ' +
                        'removeformat | help',
                    content_style: 'body {font - family:Helvetica,Arial,sansserif;font-size:14px }',

                }}
                onBlur={() => { onBlurHandler(input) }}
            />
        </div>
    )
}