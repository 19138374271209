import { assign } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, CloseButton, Col, Container, ListGroup, Modal, OverlayTrigger, ProgressBar, Row, Table, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
//import DateTimePicker from 'react-datetime-picker';

import { setDefaults } from 'react-i18next';
import { Step } from 'react-step-progress-bar';
import swal from 'sweetalert';
import { BreadcrumbBox } from '../../../../components/common/Breadcrumb';
import AssignmentService, { ASSIGN_URI, getFileToView, getSolutionFile } from '../../../../services/AssignmentService';
import instructorService from '../../../../services/instructorService';

import UserService from '../../../../services/UserService';
import FilterDataTable from '../../FilterDataTable';
// import AssignStep1 from './AssignStep1';
// import AssignStep2 from './AssignStep2';

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import md5 from 'md5';
import service from '../../../../services/service';
import useNonce from '../../../../helper/Nonce';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]






function FrontAssignment(props) {
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()
    // const fetchAndSetNonce = useNonce();
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        UserService.generateToken();
    }, []);


    const userID = props.userID;
    const courseID = props.courseID;
    const tenantID = props.tenantID;
    const isCourseOwner = props.isCourseOwner

    const handleSaveFile = async (uri, filename, fileId) => {
        try {

            const response = await AssignmentService.getfileView(uri, fileId);
            if (filename.match(".zip")) { saveAs(response.data, filename) }
            else if (filename.match(".pdf")) { saveAs(response.data, filename) }
            else if (filename.match(".doc") || filename.match(".docx")) { saveAs(response.data, filename) }
        } catch (error) {
            if (error) {
                swal("Information", "Assignment File does not Exist!", "info");
            }
            // console.error("error", error)
        }
    }


    /*********************************** MAIN FRONT COMPONENT ************************/
    const [comp, setComp] = useState(<ViewAssignment />);
    //const [comp, setComp] = useState(<EvaluationMarks />);
    return (
        <>
            {comp}
        </>
    );

    /*********************************** VIEW ASSIGNMENT ***********************************/
    function ViewAssignment(props) {
        const currentLanguageCode = cookies.get('i18next') || 'en'
        const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
        const { t } = useTranslation()
        const [getAssignmentDetails, setAssignmentDetails] = useState(false)
        const [getRow, setRow] = useState(null)
        const [getCourseOwner, setCourseOwner] = useState(false);

        useEffect(() => {
            document.body.dir = currentLanguage.dir || 'ltr'
            document.title = t('app_title')
        }, [currentLanguage, t])
        const [getInstructors, setInstructors] = useState({});
        useEffect(() => {
            getInstDetials()


        }, [])
        const getInstDetials = async () => {


            // const secretKey = "cdac@123"
            // const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
            // const decodedTenantId = CryptoJS.AES.decrypt(
            //   encodedTenantId,
            //   secretKey
            // ).toString(CryptoJS.enc.Utf8);
            await service.getInstructorDetails(courseID, 1).then((res) => {

                if (res.status === 200) {
                    instructorService.isCourseOwner(userID, courseID).then((res) => {

                        if (res.data) {
                            //course owner have all access
                            setCourseOwner(res.data)

                        }


                    }).catch((err) => {
                        swal(`${t('error')}`, err.response.data, "error");
                    })
                    let instDetails = res.data.reduce((instDetailsObj, obj) => {
                        const { learnerUsername } = obj;
                        if (!instDetailsObj[learnerUsername]) {
                            instDetailsObj[learnerUsername] = []; // Initialize as array
                        }
                        instDetailsObj[learnerUsername].push(obj); // Push object to array
                        return instDetailsObj; // Return accumulator
                    }, {});
                    setInstructors(instDetails)

                }
            }).catch((err) => {
                console.error("Error:", err);
            });


        }

        const customStyles = {
            title: {
                style: {
                    fontColor: 'red',
                    fontWeight: '900',
                }
            },

            rows: {
                style: {
                    minHeight: '72px'
                },
            },

            headCells: {
                style: {

                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for head cells
                    // paddingRight: '8px',
                    fontSize: '17px',
                    // fontWeight: '500',
                    // textTransform: 'uppercase',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px',
                },
            },
            cells: {
                style: {
                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for data cells
                    // paddingRight: '8px',
                    fontSize: '15px',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px'
                },
            },
        };

        useEffect(() => {
            assignmentListAPI();
        }, [])
        const assignmentListAPI = async () => {
            try {
                let result = await AssignmentService.getAssignDetail(UserService.getUserid(), courseID, tenantID)
                if (result.status === 200)
                    setAssignmentList(result.data);
            } catch (error) {

            }
        }

        const [getAssignmentList, setAssignmentList] = useState([]);
        const [filterText, setFilterText] = useState('');
        const [resetPaginationToggle, setResetPaginationToggle] = useState(
            false
        );

        const filteredAssignmentList = getAssignmentList.filter(
            item =>
                item.name.toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );

        const subHeaderComponent = useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText("");
                }
            };

            return (
                <FilterDataTable
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            );
        }, [filterText, resetPaginationToggle]);



        const deleteAssign = (assignID) => {
            instructorService.checkContentStatus(assignID, "assignment").then(async (res) => {
                if (res.data === true) {
                    await swal(
                        t('msg'),
                        t('this_content_map_mydrive'),
                        "warning"
                    );
                } else {
                    swal({
                        title: `${t("r_u_sure")}`,
                        text: `${t("u_want_to_delete_content")}`,
                        icon: "warning",
                        buttons: [t("no_cancel"), t("yes_delete")],
                        dangerMode: true,
                    }).then((isConfirmed) => {
                        if (isConfirmed) {
                            AssignmentService.deleteAssignment(assignID).then(async (res) => {
                                if (res.status == 200) {
                                    await swal(
                                        t('deleted'),
                                        t('your_content_deleted'),
                                        "success"
                                    );
                                    assignmentListAPI();
                                }
                            });
                        }
                    });
                }
            });
        };


        const uploadFile = (assignID) => {
            setComp(
                <AssignStep2
                    uploadFileClick={true}
                    steps={2}
                    assignID={assignID}
                    courseID={courseID}
                    tenantID={tenantID}
                />
            )
        }
        function onClickEvaluationButton(courseID, tenantID, assignID, assignType) {
            setComp(
                <>
                    <Evaluation
                        courseID={courseID}
                        tenantID={tenantID}
                        assignID={assignID}
                        assignType={assignType}
                    />
                </>
            )
        }
        function onClickEdit(assignID, name, totalMarks, passMark, description, openingDate, closingDate, assignType, mailStatus) {

            setComp(
                <CreateAssignment
                    updateAssignment={true}
                    assignID={assignID}
                    name={name}
                    totalMarks={totalMarks}
                    passMarks={passMark}
                    description={description}
                    openDate={openingDate}
                    closeDate={closingDate}
                    assignType={assignType}
                    mailstatus={mailStatus}
                    courseID={courseID}
                    tenantID={tenantID}
                />
            )

        }

        const columnViewAssignment = [
            {
                name: 'Assignment Name',
                selector: row => row.name,
                sortable: true,
                wrap: true,
                width: '200px',
            },
            {
                name: 'Description',
                selector: row => row.description,
                wrap: true
            },
            {
                name: 'Author',
                selector: row => getInstructors[row.createdBy]?.[0]?.firstName || "--",
                wrap: true
            },
            // {
            //     name: 'Total Marks',
            //     selector: row => row.totalMarks,
            //     wrap: true,
            //     width: '130px',
            // },
            // {
            //     name: 'Passing Marks',
            //     selector: row => row.passMarks,
            //     wrap: true,
            //     width: '150px',
            // },
            // {
            //     name: "Opening Date",
            //     selector: row => moment(row.openingDate).format('DD-MM-yyyy'),
            //     // selector: row => moment(row.openingDate).format('MM-DD-YYYY'),
            //     wrap: true,
            //     width: '150px',
            // },
            // {
            //     name: 'Closing Date',
            //     selector: row => moment(row.closingDate).format('DD-MM-yyyy'),
            //     // selector: row => moment(row.closingDate).format('MM-DD-YYYY'),
            //     wrap: true,
            //     width: '150px',
            // },
            {
                name: 'View',
                selector: row =>
                    <>
                        <Button style={{ whiteSpace: "nowrap" }} variant='info' onClick={() => { toggleViewAssignment(row) }}>More Details</Button>
                    </>,
                // selector: row => moment(row.closingDate).format('MM-DD-YYYY'),
                wrap: true,
                width: '200px',
            },
            {
                name: 'Action',
                width: '200px',
                cell: row => <>
                    <div style={{ whiteSpace: "nowrap" }}>
                        {UserService.getUserid() === row.createdBy || getCourseOwner
                            ?
                            <>
                                {/* {//console.log('Check-----', row.assignmentFilePath.length)} */}
                                {/* <button className='btn btn-primary' style={{ marginRight: '5px' }} onClick={() => { onClickEdit(row.assignment_id, row.name, row.totalMarks, row.passMarks, row.description, row.openingDate, row.closingDate) }} ><i class="fas fa-edit"></i> </button> */}
                                {
                                    row.assignmentFilePath.length > 0 ?
                                        <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('edit_assign')}</Tooltip>}>
                                                <button className='btn btn-info' style={{ marginRight: '5px' }} onClick={() => { onClickEdit(row.assignment_id, row.name, row.totalMarks, row.passMarks, row.description, row.openingDate, row.closingDate, row.assignType, row.mailStatus) }} ><i class="fas fa-edit"></i> </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('upload_file')}</Tooltip>}>
                                                <button className='btn btn-info' onClick={() => { uploadFile(row.assignment_id) }} style={{ marginRight: '5px' }} ><i class="fas fa-upload"></i> </button>
                                            </OverlayTrigger>
                                        </>
                                        :
                                        <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('upload_file')}</Tooltip>}>
                                                <button className='btn btn-info' onClick={() => { uploadFile(row.assignment_id) }} style={{ marginRight: '5px' }} ><i class="fas fa-upload"></i> </button>
                                            </OverlayTrigger>
                                        </>
                                }
                                {/* {
                                    !row.assignmentFilePath
                                        ?
                                        <>

                                        </>
                                        :
                                        <>
                                        </>
                                } */}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate_assign')}</Tooltip>}>
                                    <button className='btn btn-success' onClick={() => { onClickEvaluationButton(courseID, tenantID, row.assignment_id, row.assignType) }} style={{ marginRight: '5px' }} ><i class="fas fa-book-open"></i> </button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('delete_assign')}</Tooltip>}>
                                    <button className='btn btn-danger' onClick={() => { deleteAssign(row.assignment_id) }} ><i class="fas fa-trash"></i> </button>
                                </OverlayTrigger>
                            </>
                            :
                            <>
                            </>
                        }
                    </div>
                </>
            }
        ]

        const onClickCreateAssign = () => {
            setComp(
                <CreateAssignment />)
        }
        const toggleViewAssignment = (row) => {


            setRow(row)
            setAssignmentDetails(prevState => !prevState)

        }


        return (
            <>

                <div>

                    <Button type="submit" onClick={() => { onClickCreateAssign(); }} className="btn btn-primary" > {t('create_assign')}</Button>
                    <br></br>
                    <br></br>
                </div>
                <div className='shadow-lg p-3 bg-body rounded'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5>{t('assign_list')}</h5>
                            <br />
                            <Card>
                                <DataTable
                                    columns={columnViewAssignment}
                                    data={filteredAssignmentList}
                                    defaultSortField="Name"
                                    defaultSortAsc={true}
                                    striped
                                    pagination
                                    highlightOnHover
                                    customStyles={customStyles}
                                    subHeader
                                    subHeaderComponent={subHeaderComponent}
                                    fixedHeader
                                    fixedHeaderScrollHeight="300px"
                                />
                            </Card>
                        </div>
                    </div>
                </div>
                {/*  view */}
                <Modal
                    centered
                    show={getAssignmentDetails}
                    onHide={toggleViewAssignment}
                    size="xl"
                    backdrop="static"
                    scrollable

                >
                    <Modal.Header closeButton>
                        <Modal.Title>Assignment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {getRow} */}
                        <Card className='w-100 text-center'>
                            <Card.Body>
                                <Card.Title>{getRow?.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{getRow?.description}</Card.Subtitle>
                                <Card.Text>

                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Total Marks</th>
                                                <th>Passing Marks</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Files & Preview</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{getRow?.totalMarks}</td>
                                                <td>{getRow?.passMarks}</td>
                                                <td>{getRow?.createdDate}</td>
                                                <td>{getRow?.closingDate}</td>
                                                <td>
                                                    <ListGroup defaultActiveKey="#link1">
                                                        {
                                                            getRow?.assignmentFilePath?.map((filename) => (
                                                                <div key={filename?.id} onClick={() => { handleSaveFile(ASSIGN_URI._GET_FILE_TO_VIEW, filename?.filename, filename?.id) }} rel="noopener noreferrer">
                                                                    <ListGroup.Item className='text-info' style={{ cursor: "pointer" }}>
                                                                        {filename?.filename}
                                                                    </ListGroup.Item>
                                                                </div>
                                                            ))
                                                        }
                                                    </ListGroup>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>


                                </Card.Text>
                                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="danger" onClick={toggleViewAssignment}>
                            Close
                        </Button>
                        {/* You can add additional buttons or actions here */}
                    </Modal.Footer>
                </Modal>


            </>
        )
    }

    /***********************************   Creating Assignment Function ******************************/

    /*****PARENT COMPONENT OF AssignStep1 and AssignStep2 */
    function CreateAssignment(props) {
        const currentLanguageCode = cookies.get('i18next') || 'en'
        const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
        const { t } = useTranslation()

        useEffect(() => {
            document.body.dir = currentLanguage.dir || 'ltr'
            document.title = t('app_title')
        }, [currentLanguage, t])

        const assignID = props.assignID
        const onClickClose = () => {
            setComp(<ViewAssignment />)
        }
        const [getServerTime, setServerTime] = useState();
        const [showCreateAssignment, setShowCreateAssignment] = useState(true);
        const [swalMsg, setSwalMsg] = useState(t('assign_data_msg'))

        useEffect(() => {

            instructorService.getServerTime()
                .then(res => {
                    let serTime = res.data;
                    setServerTime(serTime);
                    setServerTime(serTime.replace(/\s/g, 'T'));
                    // var arr = serTime.split(" ");
                    // setDateTime(arr);
                })
            if (props.assignID) {
                setAssignmentData({
                    steps: 1,
                    updateAssignment: props.updateAssignment,
                    name: props.name,
                    totalMarks: props.totalMarks,
                    passMarks: props.passMarks,
                    description: props.description,
                    openDate: props.openDate,
                    closeDate: props.closeDate,
                    assignID: props.assignID,
                    assignType: props.assignType,
                    mailstatus: props.mailstatus,
                })
                setSwalMsg(t('assign_update_msg'))
                setSuccessMsg(t('success'))
                setDefaultDate({
                    ...defaultDate,
                    defaultOpeningDate: props.openDate,
                    defaultClosingDate: props.closeDate,
                })
            }
        }, [])

        const [defaultDate, setDefaultDate] = useState({
            defaultOpeningDate: '',
            defaultClosingDate: ''
        });


        const [successMsg, setSuccessMsg] = useState();
        const [assignmentData, setAssignmentData] = useState({
            steps: 1,//steps: 1,
            updateAssignment: '',
            name: '',
            totalMarks: '',
            passMarks: '',
            description: '',
            openDate: '',
            closeDate: '',
            assignID: '',// assignID: undefined,
            assignType: '',
            mailstatus: '',
        })

        const handleChange = (event) => {
            setAssignmentData({
                ...assignmentData, [event.target.name]: event.target.value
            })
        }

        const [assignFormError, setAssignFormError] = useState({
            nameError: '',
            totalMarksError: '',
            passMarksError: '',
            descriptionError: '',
            openDateError: '',
            openTimeError: '',
            closeDateError: '',
            closeTimeError: '',
            assignTypeError: "",
            mailstatusError: "",
        })




        function assignmentFormValidate() {


            let nameE = '';
            let totalMarksE = '';
            let passMarksE = '';
            let descriptionE = '';
            let openDateE = '';
            let closeDateE = '';
            let assignTypeErr = '';
            let emailstatusErr = '';

            if (assignmentData.name == '') {
                nameE = t('assign_name_req')
            } else if (assignmentData.name.match(/^[A-Za-z0-9()&.,\- ]+$/)) {
                nameE = '';
            } else if (assignmentData.name.length > 150) {
                nameE = "Maximun 150 Character are Allowed";
            } else if (assignmentData.name.length < 2) {
                nameE = "Minimun 2 Character are Allowed";
            } else {
                nameE = "AlphaNumeric with ( ) & . , - and Space are Allowed ";
            }

            if (assignmentData.assignType === "marktype") {
                if (assignmentData.passMarks == '') {
                    passMarksE = t('passing_mark_req')
                }
                if (parseInt(assignmentData.passMarks) > parseInt(assignmentData.totalMarks)) {
                    passMarksE = t('not_greater_than_total')
                }
                if (parseInt(assignmentData.passMarks) <= 0) {
                    passMarksE = t('not_negative')
                }
                if (parseInt(assignmentData.totalMarks) <= 0) {
                    totalMarksE = t('not_negative')
                }
                if (assignmentData.totalMarks == '') {
                    totalMarksE = t('total_mark_req')
                }
            }

            if (assignmentData.mailstatus === "mailstatus") {
                passMarksE = "Select Receive E-Mail Status"
            }

            if (assignmentData.description == '') {
                descriptionE = t('assign_desc_require')
            } else if (assignmentData.description.match(/^[A-Za-z0-9()&.,\"\'\\s ]+$/)) {
                nameE = '';
            } else if (assignmentData.description.length > 150) {
                nameE = "Maximun 150 Character are Allowed";
            } else if (assignmentData.description.length < 2) {
                nameE = "Minimun 2 Character are Allowed";
            } else {
                nameE = "AlphaNumeric with ( ) & . , - and Space are Allowed ";
            }


            if (assignmentData.openDate == '') {
                openDateE = t('open_date_req')
            }

            if (assignmentData.closeDate == '') {
                closeDateE = t('close_date_req')
            }

            if (assignmentData.updateAssignment) {
                if (defaultDate.defaultOpeningDate) {
                    //const openDateTime = assignmentData.openDate;
                    const defOpenDate = new Date(defaultDate.defaultOpeningDate).getTime();
                    const openingAssignDate = new Date(assignmentData.openDate).getTime();
                    if (openingAssignDate < defOpenDate) {
                        openDateE = t('not_greater_opening_date')
                    }
                }
            }
            else {
                const serv = new Date(getServerTime).getTime();
                const openingAssignDate = new Date(assignmentData.openDate).getTime();
                if (openingAssignDate < serv) {
                    openDateE = t('greater_than_current_time')
                }
            }


            if (assignmentData.openDate && assignmentData.closeDate) {
                const openDateTime = assignmentData.openDate
                const closeDateTime = assignmentData.closeDate
                const closingAssignDate = new Date(closeDateTime).getTime();
                const openingAssignDate = new Date(openDateTime).getTime();
                if (closingAssignDate < openingAssignDate) {
                    closeDateE = t('opening_date_greater')
                }
            }

            if (nameE !== '' || totalMarksE !== '' || passMarksE !== '' || descriptionE !== '' || openDateE !== '' || closeDateE !== '' || emailstatusErr !== '') {
                setAssignFormError({
                    ...assignFormError,
                    nameError: nameE,
                    totalMarksError: totalMarksE,
                    passMarksError: passMarksE,
                    descriptionError: descriptionE,
                    openDateError: openDateE,
                    closeDateError: closeDateE,
                    assignTypeError: assignTypeErr,
                    mailstatusError: emailstatusErr,
                })
                return false;
            }
            return true
        }

        const onClickNext = () => {
            // dateHandler();

            if (assignmentData.updateAssignment) {
                const check = assignmentFormValidate();
                if (check == true) {
                    assignmentDataService();
                }
            }
            else if (assignmentData.assignID) {
                setAssignmentData({
                    ...assignmentData,
                    steps: 2,
                })

            }
            else {
                const check = assignmentFormValidate();
                if (check == true) {

                    assignmentDataService();
                }
            }
        }



        var OpenTime = ''
        var CloseTime = ''
        const assignmentDataService = async () => {
            // await fetchAndSetNonce();
            if (assignmentData.updateAssignment) {

                var openDate, openMonth, openYear, openHour, openMinute, openSecond;
                var openDate1, openMonth1, openYear1, openHour1, openMinute1, openSecond1;
                var closeDate, closeMonth, closeYear, closeHour, closeMinute, closeSecond;
                var closeDate1, closeMonth1, closeYear1, closeHour1, closeMinute1, closeSecond1;
                openDate = new Date(assignmentData.openDate).getDate();
                openMonth = (new Date(assignmentData.openDate).getMonth()) + 1;
                openYear = new Date(assignmentData.openDate).getFullYear();
                openHour = new Date(assignmentData.openDate).getHours();
                openMinute = new Date(assignmentData.openDate).getMinutes();

                closeDate = new Date(assignmentData.closeDate).getDate();
                closeMonth = (new Date(assignmentData.closeDate).getMonth()) + 1;
                closeYear = new Date(assignmentData.closeDate).getFullYear();
                closeHour = new Date(assignmentData.closeDate).getHours();
                closeMinute = new Date(assignmentData.closeDate).getMinutes();

                openDate1 = openDate < 10 ? '0' + openDate : openDate;
                openMonth1 = openMonth < 10 ? '0' + openMonth : openMonth;
                openHour1 = openHour < 10 ? '0' + openHour : openHour;
                openMinute1 = openMinute < 10 ? '0' + openMinute : openMinute;

                closeDate1 = closeDate < 10 ? '0' + closeDate : closeDate;
                closeMonth1 = closeMonth < 10 ? '0' + closeMonth : closeMonth;
                closeHour1 = closeHour < 10 ? '0' + closeHour : closeHour;
                closeMinute1 = closeMinute < 10 ? '0' + openMinute : closeMinute;

                OpenTime = openYear + '-' + openMonth1 + '-' + openDate1 + "%20" + openHour1 + '%3A' + openMinute1 + '%3A00'
                CloseTime = closeYear + '-' + closeMonth1 + '-' + closeDate1 + "%20" + closeHour1 + '%3A' + closeMinute1 + '%3A00'

                const assignmentName = assignmentData.name.trim();
                const totalMarks = assignmentData.assignType === "marktype" ? parseInt(assignmentData.totalMarks) : ""
                const passMarks = assignmentData.assignType === "marktype" ? parseInt(assignmentData.passMarks) : ""
                const assignmentDescription = assignmentData.description.trim();
                const instID = UserService.getUserid();
                const courseId = parseInt(courseID)

                const tenantId = parseInt(tenantID)
                const assignmentID = assignmentData.assignID

                let date4 = moment(assignmentData.openDate).format();
                let date5 = moment(assignmentData.closeDate).format();



                let ReqBodyInfo = {
                    assignmentId: assignmentID,
                    name: assignmentName,
                    totalMarks: assignmentData.assignType === "marktype" ? totalMarks : 0,
                    passMarks: assignmentData.assignType === "marktype" ? passMarks : 0,
                    description: assignmentDescription,
                    openingDate: date4,
                    closingDate: date5,
                    createdBy: instID,
                    courseId: courseId,
                    tenantId: tenantId,
                    assignType: assignmentData.assignType,
                    mailstatus: assignmentData.mailstatus
                }


                AssignmentService.updateAssignmentDetail(ReqBodyInfo).then(res => {

                    setAssignmentData({
                        ...assignmentData,
                        steps: 2,
                        assignID: assignID,
                    })
                }).catch(err => {

                })
            }
            if (!assignmentData.updateAssignment) {


                const assignmentName = assignmentData.name.trim();
                const totalMarks = assignmentData.assignType === "marktype" ? parseInt(assignmentData.totalMarks) : ""
                const passMarks = assignmentData.assignType === "marktype" ? parseInt(assignmentData.passMarks) : ""
                const assignmentDescription = assignmentData.description.trim();
                const openingDateSplit = assignmentData.openDate.split("T");
                const closingDateSplit = assignmentData.closeDate.split("T");
                // const openingAssignDate = openingDateSplit[0] + "%20" + openingDateSplit[1] + "%3A00";
                // const closingAssignDate = closingDateSplit[0] + "%20" + closingDateSplit[1] + "%3A00";
                const openingAssignDatereplace = (assignmentData.openDate.replace("T", '%20')) + "%3A00"
                const openingAssignDate = openingAssignDatereplace.replace(':', '%3A00')
                const closingAssignDatereplace = (assignmentData.closeDate.replace('T', '%20')) + "%3A00"
                const closingAssignDate = closingAssignDatereplace.replace(':', '%3A00')

                // const openingAssignDate = assignmentData.openDate + " " + assignmentData.openTime + "%3A00"
                // const openAD1 = openingAssignDate.replace(':', '%3A');
                // const openAD = openAD1.replace(' ', '%20');
                // const closingAssignDate = assignmentData.closeDate + " " + assignmentData.closeTime + "%3A00"
                // const closeAD1 = closingAssignDate.replace(' ', '%20');
                // const closeAD = closeAD1.replace(' ', '%20');
                const instID = UserService.getUserid();
                const courseId = parseInt(courseID)
                const tenantId = parseInt(tenantID)

                let date4 = moment(assignmentData.openDate).format();
                let date5 = moment(assignmentData.closeDate).format();


                let ReqBodyInfoSave = {
                    name: assignmentName,
                    totalMarks: assignmentData.assignType === "marktype" ? totalMarks : 0,
                    passMarks: assignmentData.assignType === "marktype" ? passMarks : 0,
                    description: assignmentDescription,
                    openingDate: date4,
                    closingDate: date5,
                    createdBy: instID,
                    courseId: courseId,
                    tenantId: tenantId,
                    assignType: assignmentData.assignType,
                    mailstatus: assignmentData.mailstatus
                }



                AssignmentService.postAssignmentDetail(ReqBodyInfoSave).then(res => {

                    setAssignmentData({
                        ...assignmentData,
                        steps: 2,
                        assignID: res.data
                    })
                }).catch(err => {

                })
            }
        }
        const onClickSubmit = async () => {

            if (successMsg) {
                swal(t('success_swal'), swalMsg, 'success');
                setComp(
                    <>
                        <ViewAssignment />
                    </>
                )
            }
        }

        const onClickPrev = () => {
            setAssignmentData({
                ...assignmentData, steps: 1
            })
        }

        const toggleCreateAssignment = () => {
            setShowCreateAssignment(prevState => !prevState)
        }

        return (
            <>

                <Modal
                    centered
                    show={showCreateAssignment}
                    onHide={() => { toggleCreateAssignment(); onClickClose(); }}
                    size="xl"
                    backdrop="static"


                >
                    <Modal.Header closeButton>
                        <Modal.Title>Create Assignment</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body>
                            {/* <div>
                            
                            <h5>{t('create_assign')}</h5>
                        </div> */}

                            {/* <div>
                            <Row style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 1px' }}>
                                <p>{t('fill_all_form')}</p>
                                <Button className='btn btn-danger' style={{ textAlign: 'right' }} onClick={onClickClose}> X </Button>
                            </Row>
                        </div> */}
                            {/* <br /> */}
                            <Container>

                                {/*Assignment step 1 Component is Called */}
                                <AssignStep1
                                    steps={assignmentData.steps}
                                    name={assignmentData.name}
                                    type={assignmentData.assignType}
                                    totalMarks={assignmentData.totalMarks}
                                    passMarks={assignmentData.passMarks}
                                    description={assignmentData.description}
                                    openDate={assignmentData.openDate}
                                    mailstatus={assignmentData.mailstatus}
                                    //openTime={assignmentData.openTime}
                                    closeDate={assignmentData.closeDate}
                                    //closeTime={assignmentData.closeTime}                            
                                    handleChange={handleChange}
                                    assignmentFormError={assignFormError}
                                    assignID={assignID}
                                />
                                {/*Assignment step 2 Component is Called */}
                                <AssignStep2
                                    steps={assignmentData.steps}
                                    assignID={assignmentData.assignID}
                                    setState={setSuccessMsg}
                                />

                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="row justify-content-between">
                                {
                                    assignmentData.steps == 2
                                        ?
                                        <>
                                            <Row style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 1px' }}>
                                                <Button onClick={onClickPrev} variant='light' style={{ marginRight: "5px" }}> {t('prev')} </Button>
                                                <Button onClick={onClickSubmit} variant='success'> {t('submit')} </Button>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Button onClick={onClickNext} variant='light'>{t('next')} </Button>
                                        </>
                                }

                            </div>
                            <Button variant="danger" onClick={() => { toggleCreateAssignment(); onClickClose(); }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>

            </>
        )
    }
    ////Assignment Step 1
    function AssignStep1(props) {

        const [getServerTime, setServerTime] = useState();
        useEffect(() => {
            instructorService.getServerTime()
                .then(res => {
                    let serTime = res.data;
                    setServerTime(serTime.replace(/\s/g, 'T'))
                })
        }, [])
        if (props.steps !== 1) {
            return null;
        }
        else {

            return (
                <>
                    <div className='card'>
                        <div className='card-body'>
                            <fieldset>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p class="control-label">{t('assignment_name')}</p>
                                        <input type='text' className="form-control" minLength={2} maxLength={150} placeholder={t('enter_assign_name')} name='name' value={props.name} onChange={props.handleChange} />
                                        {
                                            props.assignmentFormError.nameError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.nameError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                    <div className='col-md-4'>
                                        <p class="control-label">Assignment Type</p>
                                        <select className="form-control" name='assignType' value={props.type} onChange={props.handleChange} >
                                            <option value="select" selected>Select Assignment type</option>
                                            <option value="reviewtype">Review Based</option>
                                            {/* <option value="marktype">Marks Based</option> */}
                                        </select>
                                        {
                                            props.assignmentFormError.assignTypeError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.assignTypeError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                    <div className='col-md-4'>
                                        <p class="control-label">Receive Student Assignment in E-Mail</p>
                                        <select className="form-control" name='mailstatus' value={props.mailstatus} onChange={props.handleChange} >
                                            <option value="select" selected>Select Mail Status</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        {
                                            props.assignmentFormError.mailstatusError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.mailstatusError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                                <br />
                                {
                                    props.type === "marktype" ?
                                        <>
                                            <div class="row ">
                                                <div class="col-md-6">
                                                    <p class="control-label">{t('total_marks')}</p>
                                                    <input type='number' className="form-control" min={0} pattern="^[0-9]" placeholder={t('enter_total_marks')} name='totalMarks' value={props.totalMarks} onChange={props.handleChange} />
                                                    {
                                                        props.assignmentFormError.totalMarksError
                                                            ?
                                                            <>
                                                                <div className="alert alert-danger mt-2">{props.assignmentFormError.totalMarksError}</div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                </div>
                                                <div class="col-md-6">
                                                    <p class="control-label">{t('pass_marks')}</p>
                                                    <input type='number' className="form-control" min={1} pattern="^(?=.*[1-9])[0-9]+$" placeholder={t('enter_passing_marks')} name='passMarks' value={props.passMarks} onChange={props.handleChange} />
                                                    {
                                                        props.assignmentFormError.passMarksError
                                                            ?
                                                            <>
                                                                <div className="alert alert-danger mt-2">{props.assignmentFormError.passMarksError}</div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }
                                <br />
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <p class="control-label">{t('assign_desc')}</p>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" minLength={2} maxLength={150}
                                            name='description' value={props.description} onChange={props.handleChange}></textarea>
                                        {
                                            props.assignmentFormError.descriptionError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.descriptionError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                                <br />
                                <div class="row ">
                                    <div class="col col-md-6">
                                        <p class="control-label">{t('opening_date')}</p>
                                        <input type='datetime-local' className="form-control" name='openDate' value={props.openDate} onChange={props.handleChange} />
                                        {
                                            props.assignmentFormError.openDateError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.openDateError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </div>
                                    <div class="col col-md-6">
                                        <p class="control-label">{t('close_date')}</p>
                                        <input type='datetime-local' className="form-control" name='closeDate' value={props.closeDate} onChange={props.handleChange} />
                                        {
                                            props.assignmentFormError.closeDateError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{props.assignmentFormError.closeDateError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                                <br />
                            </fieldset>
                        </div>
                    </div>
                </>
            )
        }
    }



    ////Assignment Step 2 
    function AssignStep2(props) {
        const assignid = props.assignID;

        useEffect(() => {
            getAssignDetailById();

        }, [assignid])

        const [storeAssignDataById, setStoreAssignDataById] = useState();
        const [showUploadFile, setShowUploadFile] = useState(false);

        const getAssignDetailById = () => {
            AssignmentService.getAssignmentData(assignid).
                then((resp) => {
                    setStoreAssignDataById(resp.data.assignmentFilePath);

                }).catch((err) => {

                })
        }

        const [errorType, setErrorType] = useState();
        const [uploadFileCheck, setUploadFileCheck] = useState(false);
        const [selectedFiles, setSelectedFiles] = useState([])
        const selectFiles = (event) => {
            // event.preventDefault();
            // let fi = document.getElementById('file');
            let files = event.target.files;

            for (let i = 0; i < files.length; i++) {

                if (files[i].type != "application/pdf" && files[i].type != "application/x-zip-compressed" && files[i].type != "application/msword" && files[i].type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    setErrorType(t('assign_upload_err'));
                    return;
                }
                else {
                    setUploadFileCheck(true)
                    setErrorType();
                    setSelectedFiles(files)
                }
            }
        }
        const onClickupload = async (e) => {

            e.preventDefault()
            // await fetchAndSetNonce("Assignment");
            if (uploadFileCheck == true) {
                setUploadFileCheck((prev) => !prev)
                for (let index = 0; index < selectedFiles.length; index++) {
                    const fileName = selectedFiles[index].name;
                    const validFileNamePattern = /^[a-zA-Z0-9-._ ]+$/;

                    // alert(fileName)

                    if (!validFileNamePattern.test(fileName)) {
                        swal(t('error'), t('Invalid File name'), "error");
                        return;
                    }
                    const fileParts = fileName.split('.');
                    if (fileParts.length > 2) {
                        swal(t('error'), t('Double Extension file!'), "error");
                        return;
                    }


                    const form = new FormData();
                    form.append('file', selectedFiles[index]);
                    form.append('lengthHash', md5((selectedFiles[index].size).toString()))


                    AssignmentService.postFileUpload(props.assignID, form).then((res) => {


                        if (res.data === "InvalidFile") {
                            swal(t('error'), t('file_not_support'), "error");
                        }
                        else if (res.data === "double-extension-file") {
                            swal(t('error'), t('double-ext-file'), "error");
                        }
                        else {
                            swal(t('success'), t('file_upload_msg'), 'success');
                            getAssignDetailById();
                            document.getElementById('file').value = null;
                            props.setState("Success");
                            setUploadFileCheck(false)
                        }


                    }).catch((error) => {
                        // if(error.response.status === 406){
                        //     swal(t('error'),"File Not Supported","error");
                        // }

                    })
                }

                // document.getElementById('form').addEventListener('submit', function (e) {
                //     // e.preventDefault();

                //     // let fi = document.getElementById('file');
                //     // let files = fi.files;

                // })
            }
            else {

            }
        }


        /*This is For when instructor Click upload Button from Assignment List passes */
        /*uploadFileClick={true}
        steps={2}
        assignID={assignID}
        courseID={props.courseID}
        tenantID={props.tenantID} Line No 129  */

        const onClickBack = () => {
            setComp(<ViewAssignment />)
        }

        const toggleFileUpload = (state) => {
            setShowUploadFile((!state))
        }



        // useEffect(() => {
        //     listAssignmentFileFunction();
        // }, [])
        // const [listFileAssignment, setListFileAssignment] = useState([]);
        // const listAssignmentFileFunction = () => {
        //     AssignmentService.getAssignDetail(props.userID , props.courseID , props.tenantID)
        //     .then((resp)=>{
        //         setListFileAssignment(resp.data)
        //     }).catch((err)=>{
        //        
        //     })
        //     if(listFileAssignment)
        //     {
        //         //console.log(listFileAssignment);
        //     }
        // }
        // const [filterText, setFilterText] = React.useState("");
        // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        //     false
        // );
        // const filteredAssignmentFile = listFileAssignment.filter(
        //     item =>
        //         JSON.stringify(item)
        //             .toLowerCase()
        //             .indexOf(filterText.toLowerCase()) !== -1
        // );
        // const subHeaderComponent = useMemo(() => {
        //     const handleClear = () => {
        //         if (filterText) {
        //             setResetPaginationToggle(!resetPaginationToggle);
        //             setFilterText("");
        //         }
        //     };

        //     return (
        //         <FilterDataTable
        //             onFilter={e => setFilterText(e.target.value)}
        //             onClear={handleClear}
        //             filterText={filterText}
        //         />
        //     );
        // }, [filterText, resetPaginationToggle]);

        const [dispModel, setDispModal] = useState(false)
        const onHideClick = () => {
            setDispModal(false);
        }
        const [contentType, setContentType] = useState();
        const [file, setFile] = useState();
        const onClickView = (id) => {

            setFile(id);
            setDispModal(true)

        }
        const onClickDeleteFile = (id) => {
            AssignmentService.fileOnDelete(id).
                then((resp) => {
                    if (resp.status === 200) {
                        swal(t('success'), t('file_delete_msg'), 'success');
                        getAssignDetailById();
                        props.setState("Success");
                    }
                }).catch((err) => {

                })
        }
        const customStyles = {
            title: {
                style: {
                    fontColor: 'red',
                    fontWeight: '900',
                }
            },

            rows: {
                style: {
                    minHeight: '72px'
                },
            },

            headCells: {
                style: {

                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for head cells
                    // paddingRight: '8px',
                    fontSize: '17px',
                    // fontWeight: '500',
                    // textTransform: 'uppercase',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px',
                },
            },
            cells: {
                style: {
                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for data cells
                    // paddingRight: '8px',
                    fontSize: '15px',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px'
                },
            },
        };

        const fileUploadColumn = [
            {
                name: 'File Name',
                selector: row => row.filename,
            },
            {
                name: "Preview",
                cell: (row) =>
                    <>
                        <a className='link' href="#" onClick={() => { onClickView(row.id) }}>
                            {
                                row.filename.match(".zip")
                                    ?
                                    <>
                                        {setContentType(".zip")}
                                        <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_FILE_TO_VIEW, row.filename, row.id) }}  ><i class="fas fa-file-archive" style={{ fontSize: "35px", color: "#fdbf00" }}></i></div>
                                    </>

                                    :
                                    row.filename.match(".pdf")
                                        ?
                                        <>
                                            {setContentType(".pdf")}
                                            {/* <a href={`${getFileToView}${row.id}`} >
                                                <i class="fas fa-file-pdf" style={{ fontSize: "35px", color: "#b30b00" }}></i>
                                            </a> */}
                                            <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_FILE_TO_VIEW, row.filename, row.id) }} >
                                                <i class="fas fa-file-pdf" style={{ fontSize: "35px", color: "#b30b00" }}></i>
                                            </div>

                                        </>
                                        :
                                        row.filename.match(".doc") ||
                                            row.filename.match(".docx")
                                            ?
                                            <>
                                                {setContentType(".doc")}
                                                <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_FILE_TO_VIEW, row.filename, row.id) }} target="_blank">
                                                    <i class="fas fa-file-word" style={{ fontSize: "35px", color: "#2596be" }}></i>
                                                </div>

                                            </>

                                            :
                                            <></>
                            }
                        </a>
                    </>
            },
            {
                name: 'Delete',
                selector: row =>
                    <>
                        <Button className='' variant='danger' onClick={() => { onClickDeleteFile(row.id) }}> <i class="fas fa-trash"></i> </Button>
                    </>


            }
        ]

        if (props.steps !== 2) {
            return null;
        }
        else {

            return (
                <>
                    {/* {//console.log(assignid)} */}

                    <div>
                        <h4 class="control-label" style={{ textAlign: 'center' }}> {t('upload_file')}</h4>
                        <p class="control-label" style={{ textAlign: 'center' }}>{t('file_pdf_doc_zip')}</p>
                        <br />
                        <form id='form'>
                            <div className='row'>
                                <div className='col-md-6 offset-md-3'>
                                    <input type='file' id='file' onChange={selectFiles} />
                                    {/* <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="file"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={selectFiles} />
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div> */}
                                </div>
                                <button class="btn btn-primary" onClick={onClickupload} disabled={(!uploadFileCheck)}> {t('upload_file')}</button>
                            </div>
                            <div>
                                {errorType ? <div className="alert alert-danger mt-2">{errorType}</div> : <></>}
                            </div>
                        </form>
                        {
                            <>



                                {/* <div>
                                    {
                                        props.uploadFileClick == true ?
                                            <>
                                                <div>
                                                    <DataTable
                                                        columns={fileUploadColumn}
                                                        data={filteredAssignmentFile}
                                                        defaultSortField="Name"
                                                        defaultSortAsc={true}
                                                        striped
                                                        pagination
                                                        highlightOnHover
                                                        //customStyles={customStyles}
                                                        subHeader
                                                        subHeaderComponent={subHeaderComponent}
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="300px"
                                                    />
                                                </div> 
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div> */}
                            </>
                        }
                    </div>
                    <br />
                    <br />
                    <div>
                        <DataTable
                            columns={fileUploadColumn}
                            data={storeAssignDataById}
                            customStyles={customStyles}
                        />
                    </div>
                    {/* <br /> */}
                    {/* {
                        props.uploadFileClick == true
                            ?
                            <>
                                <Button onClick={onClickBack} style={{ marginRight: "5px" }}> {t('back')} </Button>
                            </>
                            :
                            <>
                            </>
                    } */}
                    <Modal
                        centered
                        show={props.uploadFileClick}
                        onHide={onClickBack}
                        size="xl"
                        backdrop="static"


                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Upload Files</Modal.Title>
                        </Modal.Header>
                        <form id='form'>
                            <Modal.Body>

                                <div>
                                    <h4 class="control-label" style={{ textAlign: 'center' }}> {t('upload_file')}</h4>
                                    <p class="control-label" style={{ textAlign: 'center' }}>{t('file_pdf_doc_zip')}</p>
                                    <br />

                                    <div className='row'>
                                        <div className='col-md-6 offset-md-3'>
                                            <input type='file' id='file' onChange={selectFiles} />
                                            {/* <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="file"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={selectFiles} />
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div> */}
                                        </div>
                                        <button class="btn btn-primary" onClick={onClickupload} disabled={(!uploadFileCheck)}> {t('upload_file')}</button>
                                    </div>
                                    <div>
                                        {errorType ? <div className="alert alert-danger mt-2">{errorType}</div> : <></>}
                                    </div>

                                    {
                                        <>



                                            {/* <div>
                                    {
                                        props.uploadFileClick == true ?
                                            <>
                                                <div>
                                                    <DataTable
                                                        columns={fileUploadColumn}
                                                        data={filteredAssignmentFile}
                                                        defaultSortField="Name"
                                                        defaultSortAsc={true}
                                                        striped
                                                        pagination
                                                        highlightOnHover
                                                        //customStyles={customStyles}
                                                        subHeader
                                                        subHeaderComponent={subHeaderComponent}
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="300px"
                                                    />
                                                </div> 
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div> */}
                                        </>
                                    }
                                </div>
                                <br />
                                <br />
                                <div>
                                    <DataTable
                                        columns={fileUploadColumn}
                                        data={storeAssignDataById}
                                        customStyles={customStyles}
                                    />
                                </div>


                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant='light' onClick={onClickBack}> {t('back')} </Button>
                                <Button variant="danger" onClick={onClickBack}>
                                    Close
                                </Button>
                                {/* You can add additional buttons or actions here */}
                            </Modal.Footer>
                        </form>
                    </Modal>


                </>
            )
        }
    }


    /**************************************  EVALUATION COMPONENT ********************************/

    function Evaluation(props) {

        const courseID = props.courseID;
        const tenantID = props.tenantID;
        const assignID = props.assignID;
        const assignType = props.assignType;

        const toggleEvaluation = () => {
            setShowEvaluation(prevState => !prevState);
        }
        const [showEvaluation, setShowEvaluation] = useState(false);
        const [showLoading, setShowLoading] = useState(false);
        const [selectedAssignment, setSelectedAssignment] = useState({
            solID: null, assignId: null, courseID: null, tenantID: "", submittedBy: "", assignType: ""
        });
        const printRow = (row) => {

        }

        const customStyles = {
            title: {
                style: {
                    fontColor: 'red',
                    fontWeight: '900',
                }
            },

            rows: {
                style: {
                    minHeight: '72px'
                },
            },

            headCells: {
                style: {

                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for head cells
                    // paddingRight: '8px',
                    fontSize: '17px',
                    // fontWeight: '500',
                    // textTransform: 'uppercase',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px',
                },
            },
            cells: {
                style: {
                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for data cells
                    // paddingRight: '8px',
                    fontSize: '15px',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px'
                },
            },
        };

        function onClickClose() {
            setComp(
                <>
                    <ViewAssignment />
                </>
            )
        }

        useEffect(() => {
            assignmentSubmittedListAPI();
        }, [])


        const [getAssignmentSubmittedList, setAssignmentSubmittedList] = useState([]);
        const [filterText, setFilterText] = React.useState("");
        const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
            false
        );
        const assignmentSubmittedListAPI = async () => {
            setShowLoading(true);
            await AssignmentService.getAssignmentSubmittedList(courseID, tenantID, assignID)
                .then((resp) => {
                    setAssignmentSubmittedList(resp.data)
                    setShowLoading(false);
                }).catch((err) => {
                    setShowLoading(false);
                })
        }
        const filteredAssignmentSubmittedList = getAssignmentSubmittedList.filter(
            item =>
                item.solution.submittedBy
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        );


        const subHeaderComponent = useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText("");
                }
            };
            return (
                <FilterDataTable
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            );
        }, [filterText, resetPaginationToggle]);


        const columnViewSubmittedAssignment = assignType === "reviewType" ? [
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
                wrap: true,

            },
            {
                name: 'Status',
                selector: row => row.solutionmarks.status,
            },
            {
                name: 'Score',
                selector: row => row.solutionmarks.marks,
                wrap: true
            },
            {
                name: 'Remarks',
                selector: row => row.solutionmarks.remarks,
                wrap: true,
            },
            {
                name: 'Action',
                cell: row => <>
                    <div>
                        {props.userID === row.createdBy
                            ?
                            <>
                                {
                                    row.solutionmarks.status !== 'evaluated'
                                        ?
                                        <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate')}</Tooltip>}>
                                                <button className='btn btn-success' style={{ marginLeft: '5px' }} onClick={() => { toggleEvaluation(); printRow(row); onClickEvalutionMarks(row.solution.assignmentId, row.solution.submittedBy, row.solution.solutionId); }} ><i class="fas fa-book-open"></i> </button>
                                                {/* style={activeAddModule ? {color: "white"} : {color:"#5cb85c"} */}
                                            </OverlayTrigger>

                                        </>
                                        :
                                        <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate')}</Tooltip>}>
                                                <button className='btn btn-success' style={{ marginLeft: '5px' }} onClick={() => { onClickEvalutionMarks(row.solution.assignmentId, row.solution.submittedBy, row.solution.solutionId) }} disabled><i class="fas fa-book-open"></i> </button>
                                                {/* style={activeAddModule ? {color: "white"} : {color:"#5cb85c"} */}
                                            </OverlayTrigger>
                                        </>

                                }


                            </>
                            :
                            <>
                            </>
                        }
                    </div>
                </>
            }
        ] : [
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
                wrap: true,

            },
            {
                name: 'Remarks',
                selector: row => row.solutionmarks.remarks,
                wrap: true,
            },
            {
                name: 'Action',
                cell: row => <>
                    <div>
                        {props.userID === row.createdBy
                            ?
                            <>
                                {
                                    // row.solutionmarks.status !== 'evaluated' || 
                                    row.solutionmarks.status == 'approve'
                                        ?
                                        <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate')}</Tooltip>}>
                                                <button className='btn btn-success' style={{ marginLeft: '5px' }} onClick={() => { onClickEvalutionMarks(row.solution.assignmentId, row.solution.submittedBy, row.solution.solutionId) }} disabled><i class="fas fa-book-open"></i> </button>
                                                {/* style={activeAddModule ? {color: "white"} : {color:"#5cb85c"} */}
                                            </OverlayTrigger>

                                        </>
                                        :
                                        <>
                                            {
                                                row.solutionmarks.status === 'evaluated' ?
                                                    <>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate')}</Tooltip>}>
                                                            <button className='btn btn-success' style={{ marginLeft: '5px' }} onClick={() => { onClickEvalutionMarks(row.solution.assignmentId, row.solution.submittedBy, row.solution.solutionId) }} disabled><i class="fas fa-book-open"></i> </button>
                                                            {/* style={activeAddModule ? {color: "white"} : {color:"#5cb85c"} */}
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    <>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('evaluate')}</Tooltip>}>
                                                            <button className='btn btn-success' style={{ marginLeft: '5px' }} onClick={() => { toggleEvaluation(); printRow(row); onClickEvalutionMarks(row.solution.assignmentId, row.solution.submittedBy, row.solution.solutionId); }} ><i class="fas fa-book-open"></i> </button>
                                                            {/* style={activeAddModule ? {color: "white"} : {color:"#5cb85c"} */}
                                                        </OverlayTrigger>
                                                    </>
                                            }
                                        </>

                                }


                            </>
                            :
                            <>
                            </>
                        }
                    </div>
                </>
            }]

        function onClickEvalutionMarks(assignId, submittedBy, solID) {

            setSelectedAssignment({ solID, assignId, courseID, tenantID, submittedBy, assignType });

        }


        return (
            <>
                <Modal
                    centered
                    show={true}
                    onHide={onClickClose}
                    size="xl"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('evaluation')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            showEvaluation ?
                                <Container>
                                    <EvaluationMarks {...selectedAssignment} />
                                </Container>
                                :
                                <>
                                    {
                                        showLoading ?
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                    <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    <Card>
                                                        <DataTable
                                                            columns={columnViewSubmittedAssignment}
                                                            data={filteredAssignmentSubmittedList}
                                                            defaultSortField="Name"
                                                            defaultSortAsc={true}
                                                            striped
                                                            pagination
                                                            highlightOnHover
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={subHeaderComponent}
                                                            fixedHeader
                                                            fixedHeaderScrollHeight="300px"
                                                        // onRowClicked={(row) => {printRow(row); onClickEvalutionMarks(row.solID, row.assignId, row.courseID, row.tenantID, row.submittedBy)}}
                                                        />
                                                    </Card>
                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            showEvaluation &&

                            <>
                                {/* <Button variant="success"> {t('submit')} </Button> */}
                                <Button variant="light" onClick={toggleEvaluation}>
                                    Back
                                </Button>
                            </>


                        }

                        <Button variant="danger" onClick={onClickClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    ////Evaluation Marks
    function EvaluationMarks(props) {
        const currentLanguageCode = cookies.get('i18next') || 'en'
        const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
        const { t } = useTranslation()
        const assignType = props.assignType;


        useEffect(() => {
            document.body.dir = currentLanguage.dir || 'ltr'
            document.title = t('app_title')
        }, [currentLanguage, t])
        const { solID, assignId, tenantID, courseID, submittedBy } = props;


        // const solID = props.solID;
        // const assignID = props.assignID;
        // const tenantID = props.tenantID;
        // const courseID = props.courseID;
        // const submittedBy = props.submittedBy;
        const userID = UserService.getUserid();

        useEffect(() => {
            getStudentFile();
        }, [])

        const [solStudFile, setSolStudFile] = useState([])
        const getStudentFile = () => {
            AssignmentService.getStudentFileUpload(assignId, submittedBy).then((resp) => {

                if (resp.status === 200) {
                    setSolStudFile(resp.data.solutionfile)
                }
            }).catch((err) => {

            })
        }

        const customStyles = {
            title: {
                style: {
                    fontColor: 'red',
                    fontWeight: '900',
                }
            },

            rows: {
                style: {
                    minHeight: '72px'
                },
            },

            headCells: {
                style: {

                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for head cells
                    // paddingRight: '8px',
                    fontSize: '17px',
                    // fontWeight: '500',
                    // textTransform: 'uppercase',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px',
                },
            },
            cells: {
                style: {
                    widthRight: '8px',
                    widthLeft: '8px',
                    // paddingLeft: '8px', // override the cell padding for data cells
                    // paddingRight: '8px',
                    fontSize: '15px',
                    // paddingLeft: '0 8px',
                    // marginLeft: '10px'
                },
            },
        };

        const resetEvalutionMarks = () => {
            setEvaluationMarks((prev) => {
                return {
                    ...prev,
                    marks: "",
                    remarks: "",
                    row: "",
                    showRejectModal: false
                }
            })

            setEvaluationMarksError((prev) => {
                return {
                    ...prev,
                    marksError: "",
                    remarksError: ""
                }
            })
        }

        const approveAssignment = (row) => {

            const data = {
                remark: ""
            }
            Swal.fire({
                title: "Confirmation",
                text: "Are you sure you want to Approve the assignment, Once it Approve cannot be Reject Later",
                icon: "info",
                showConfirmButton: true,
                showCloseButton: true,
            }).then(resp => {
                if (resp.isConfirmed) {
                    Swal.fire({
                        title: "Please Wait",
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading();
                            AssignmentService.approveAssignment(row?.sno, data).then((res) => {
                                if (res.status === 200) {
                                    Swal.fire({
                                        title: "Success",
                                        text: "Assignment Approve Successfully",
                                        icon: "success",
                                        showConfirmButton: false,
                                        showCloseButton: false,
                                        showCancelButton: false
                                    })
                                    resetEvalutionMarks();
                                    getStudentFile();

                                }
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Something went wrong",
                                    icon: "error",
                                    showConfirmButton: false,
                                    showCloseButton: false,
                                    showCancelButton: false
                                })
                                resetEvalutionMarks();


                            })
                        }
                    })
                }
            })
        }

        const submitRejectAssignmentWithRemarks = () => {
            const status = ValidateForm();
            const data = {
                remarks: evaluationMarks.remarks
            }
            if (status) {

                Swal.fire({
                    title: "Please Wait",
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                        AssignmentService.rejectAssignment(evaluationMarks.row?.sno, data).then((res) => {
                            if (res.status === 200) {
                                Swal.fire({
                                    title: "Success",
                                    text: "Assignment Reject Successfully",
                                    icon: "success",
                                    showConfirmButton: false,
                                    showCloseButton: false,
                                    showCancelButton: false
                                })
                                getStudentFile();
                                resetEvalutionMarks();


                            }
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                showConfirmButton: false,
                                showCloseButton: false,
                                showCancelButton: false
                            })
                            getStudentFile();
                            resetEvalutionMarks();

                        })
                    }
                })
            }
        }

        const rejectAssignment = (row) => {

            Swal.fire({
                title: "Confirmation",
                text: "Are you sure you want to Reject the assignment, Once it Reject cannot be Approve Later",
                icon: "info",
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async resp => {
                if (resp.isConfirmed) {
                    setEvaluationMarks((prev) => {
                        return {
                            ...prev,
                            showRejectModal: true,
                            row: row
                        }
                    })
                }
            })
        }

        const studfileColumn = [
            {
                name: 'File Name',
                selector: row => row.filename,
            },
            {
                name: "Preview",
                cell: (row) =>
                    <>

                        <a className='link' href="#" >
                            {
                                row.filename.match(".zip")
                                    ?
                                    <>

                                        <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_STUDENT_FILE, row.filename, row.sno) }}  ><i class="fas fa-file-archive" style={{ fontSize: "35px", color: "#fdbf00" }}></i></div>
                                    </>

                                    :
                                    row.filename.match(".pdf")
                                        ?
                                        <>

                                            <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_STUDENT_FILE, row.filename, row.sno) }} style={{ cursor: "pointer" }}>
                                                <i class="fas fa-file-pdf" style={{ fontSize: "35px", color: "#b30b00" }}></i>
                                            </div>

                                        </>
                                        :
                                        row.filename.match(".doc") ||
                                            row.filename.match(".docx")
                                            ?
                                            <>

                                                <div onClick={() => { handleSaveFile(ASSIGN_URI._GET_STUDENT_FILE, row.filename, row.sno) }} target="_blank">
                                                    <i class="fas fa-file-word" style={{ fontSize: "35px", color: "#2596be" }}></i>
                                                </div>
                                            </>
                                            :
                                            <></>
                            }
                        </a>
                    </>
            },

            {
                name: assignType === "reviewtype" ? "Action" : "",
                cell: assignType === "reviewtype" ? (row) =>
                    <>
                        {
                            row?.status === "submitted" ?
                                <>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Approve</Tooltip>}>
                                        <button type="button" onClick={() => {
                                            approveAssignment(row)
                                        }} class="btn m-2" style={{ background: "green", color: "white" }}><i class="fa fa-thumbs-up"></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject</Tooltip>}>
                                        <button type="button" onClick={() => {
                                            rejectAssignment(row)
                                        }} class="btn btn-danger m-2" > <i class="fa fa-thumbs-down"></i></button>
                                    </OverlayTrigger>
                                </>
                                :
                                <>
                                    {
                                        row?.status === "approve" ?
                                            <>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Approve</Tooltip>}>
                                                    <button disabled type="button" onClick={() => {
                                                        approveAssignment(row)
                                                    }} class="btn m-2" style={{ background: "green", color: "white" }}><i class="fa fa-thumbs-up"></i></button>
                                                </OverlayTrigger>
                                            </>
                                            :
                                            <>
                                                {
                                                    row?.status === "reject" ?
                                                        <>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject</Tooltip>}>
                                                                <button disabled type="button" onClick={() => {
                                                                    rejectAssignment(row)
                                                                }} class="btn btn-danger m-2" > <i class="fa fa-thumbs-down"></i></button>
                                                            </OverlayTrigger>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </>
                                    }
                                </>
                        }
                    </>
                    : ""
            },
        ]



        const [evaluationMarks, setEvaluationMarks] = useState({
            showRejectModal: false,
            marks: '',
            remarks: '',
            row: "",
        });
        const [evaluationMarksError, setEvaluationMarksError] = useState({
            marksError: '',
            remarksError: '',
        })

        const marksHandler = (event) => {
            setEvaluationMarks({
                ...evaluationMarks,
                [event.target.name]: event.target.value
            })
        }

        function ValidateForm() {
            let marksE = '';
            let remarksE = '';

            if (assignType === "reviewtype") {
                if (evaluationMarks.remarks == '') {
                    remarksE = t('field_required')
                }
                if (!evaluationMarks.remarks.match(/^[A-Za-z0-9()&.,\"\'\\s ]+$/)) {
                    remarksE = "AlphaNumeric with ( ) & . , - \" \' and Space are Allowed "
                }
                if ((remarksE != '' || !evaluationMarks.remarks.match(/^[A-Za-z0-9()&.,\"\'\\s ]+$/))) {
                    setEvaluationMarksError({
                        ...evaluationMarksError,
                        remarksError: remarksE,
                    })
                    return false;
                }
                return true
            }
            else {
                if (evaluationMarks.marks == '') {
                    marksE = t('field_required')
                }
                if (evaluationMarks.remarks == '') {
                    remarksE = t('field_required')
                }
                if (!evaluationMarks.remarks.match(/^[A-Za-z0-9()&.,\"\'\\s ]+$/)) {
                    remarksE = "AlphaNumeric with ( ) & . , - \" \' and Space are Allowed "
                }
                if (marksE != '' && (remarksE != '' || !evaluationMarks.remarks.match(/^[A-Za-z0-9()&.,\"\'\\s ]+$/))) {
                    setEvaluationMarksError({
                        ...evaluationMarksError,
                        marksError: marksE,
                        remarksError: remarksE,
                    })
                    return false;
                }
                return true
            }
        }

        async function onClickSubmit() {
            const check = ValidateForm();
            // await fetchAndSetNonce("Assignment");
            if (check == true) {

                swal({
                    title: t('swal_title'),
                    text: t('evaluation_msg'),
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((Ok) => {
                        if (Ok) {

                            let evalBody = {
                                solution_id: solID,
                                marks: evaluationMarks.marks,
                                remarks: evaluationMarks.remarks,
                                evaluatedBy: userID
                            }


                            AssignmentService.putEvaluatedBy(evalBody).then((resp) => {
                                if (resp.status == 200) {
                                    swal(t('evaluate_success'), {
                                        icon: "success",
                                    });
                                    // setComp(
                                    //     <>
                                    //         <Evaluation
                                    //             courseID={courseID}
                                    //             tenantID={tenantID}
                                    //             assignID={assignId}
                                    //         />
                                    //     </>
                                    // )
                                    setComp(
                                        <>
                                            <ViewAssignment />
                                        </>
                                    )
                                }
                            })
                        }
                        // else {
                        //     swal("Your imaginary file is safe!");
                        // }
                    });


                // AssignmentService.putEvaluatedBy(solID, evaluationMarks.marks, evaluationMarks.remarks, userID).then((resp) => {
                //     if (resp.status == 200) {
                //         swal("Submited", "Submitted Successfully", 'success');
                //         setComp(
                //             <Evaluation
                //                 courseID={courseID}
                //                 tenantID={tenantID}
                //                 assignID={assignID}
                //             />
                //         )
                //     }
                // }).catch((error) => {
                //     
                // })
            }
        }

        function onClickCloseButton() {
            setComp(
                <>
                    <Evaluation
                        assignID={assignId}
                        tenantID={tenantID}
                        courseID={courseID}
                    />
                </>
            )
        }


        return (
            <>
                <fieldset>
                    <div style={{ textAlign: 'center' }}>
                        {/* {//console.log(solStudFile)} */}
                        <DataTable
                            columns={studfileColumn}
                            customStyles={customStyles}
                            data={solStudFile}
                            defaultSortAsc={true}
                            striped
                            highlightOnHover
                            subHeader
                            fixedHeader
                        />
                    </div>
                    <br />
                    <br />
                    <div>
                        {
                            assignType === "reviewtype" ?
                                <>
                                </>
                                :
                                <>
                                    <form>

                                        <div className='row' style={{ marginLeft: '1px' }}>
                                            <p class="control-label" style={{ marginRight: '20px' }}>{t('mark')} </p>
                                            <input type='number' min={0} pattern="^[0-9]" placeholder="Enter Marks " name='marks' value={evaluationMarks.marks} onChange={marksHandler} />
                                        </div>
                                        <div>
                                            {
                                                evaluationMarksError.marksError
                                                    ?
                                                    <>
                                                        <div className="alert alert-danger mt-2">{evaluationMarksError.marksError}</div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </div>


                                        <br />
                                        <div className='row' style={{ marginLeft: '1px' }}>
                                            <p class="control-label">{t('remarks')}</p>
                                            <textarea class="form-control" style={{ marginRight: '1px' }} id="exampleFormControlTextarea1" rows="3"
                                                name='remarks' minLength={2} maxLength={150} value={evaluationMarks.remarks} onChange={marksHandler}></textarea>
                                        </div>
                                        {
                                            evaluationMarksError.remarksError
                                                ?
                                                <>
                                                    <div className="alert alert-danger mt-2">{evaluationMarksError.remarksError}</div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </form>
                                    <br />
                                    <br />
                                    <Row style={{ display: 'flex', margin: '0px 1px' }}>
                                        <Button variant='success' onClick={onClickSubmit}> {t('submit')} </Button>
                                    </Row>
                                </>
                        }
                    </div>
                </fieldset>

                <Modal size='lg' centered show={evaluationMarks.showRejectModal} onHide={() => {
                    setEvaluationMarks((prev) => {
                        return {
                            ...prev,
                            showRejectModal: false,
                            marks: "",
                            remarks: "",
                            row: ""
                        }
                    })
                }}>
                    <Modal.Header>
                        <h5>Please give the remark</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row' style={{ margin: '0px', width: "100%" }}>
                            <p class="control-label">{t('remarks')}</p>
                            <textarea class="form-control" id="exampleFormControlTextarea1" name='remarks' minLength={2} maxLength={150} value={evaluationMarks.remarks} onChange={marksHandler}></textarea>
                        </div>
                        {
                            evaluationMarksError.remarksError
                                ?
                                <>
                                    <div className="alert alert-danger mt-2">{evaluationMarksError.remarksError}</div>
                                </>
                                :
                                <>
                                </>
                        }
                        <Row style={{ display: 'flex', margin: '15px 0px' }}>
                            <Button variant='success' onClick={submitRejectAssignmentWithRemarks}> {t('submit')} </Button>
                            <Button variant='danger' style={{ marginLeft: "5px" }} onClick={() => {
                                setEvaluationMarks((prev) => {
                                    return {
                                        ...prev,
                                        marks: "",
                                        remarks: "",
                                        row: "",
                                        showRejectModal: false
                                    }
                                })
                            }}> Cancel </Button>
                        </Row>
                    </Modal.Body>
                </Modal>


            </>
        )
    }
}

export default FrontAssignment;