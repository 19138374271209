import React, { useEffect, useRef, useState } from 'react'
import service from '../services/service';
import swal from 'sweetalert';
import axios from 'axios';


const useNonce = () => {

    useEffect(() => {
        // Set up the nonce interceptor once
        if (!axios.defaults.nonceInterceptor) {
            axios.defaults.nonceInterceptor = axios.interceptors.request.use((config) => {
                if (config.method === 'post') {
                    //console.log("INISDE IF config.method === post", axios.defaults.nonce);
                    config.headers['User-App-Token'] = axios.defaults.nonce;
                }
                return config;
            });
        }

        return () => {
            // Eject the nonce interceptor if needed
            if (axios.defaults.nonceInterceptor) {
                axios.interceptors.request.eject(axios.defaults.nonceInterceptor);
                delete axios.defaults.nonceInterceptor;
            }
        };
    }, []);

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const insertRandomCharacters = (uuid) => {
        const prefixrandom = generateRandomString(4);
        const random1 = generateRandomString(4);
        const random2 = generateRandomString(4);
        const random3 = generateRandomString(4);
        const random4 = generateRandomString(4);

        // Insert random strings at various positions within the UUID
        return `${prefixrandom}${uuid.slice(0, 6)}${random1}${uuid.slice(6, 14)}${random2}${uuid.slice(14, 18)}${random3}${uuid.slice(18, 23)}${random4}${uuid.slice(23)}`;
    };

    const fetchAndSetNonce = async (service_name) => {
        try {
            const res = await service.fetchNonce(service_name);
            //console.log("NONCE: ", res.data);

            const modifiedNonce = insertRandomCharacters(res.data);
            //console.log("modifiedNonce: ", modifiedNonce);

            // Save the modified nonce token
            axios.defaults.nonce = modifiedNonce;
        } catch (err) {
            swal('Error', 'Error in Submitting Submit the form!', 'error');
            throw err; // Re-throw the error to be handled by the calling function
        }
    };

    return fetchAndSetNonce;
};

export default useNonce;