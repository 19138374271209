/* New Couse structure Dive css NCSD.css */
import styled from "styled-components";

export const Styles = styled.div `
/* Styles for large screens (unchanged) */

.main-content {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 20px;
    padding: 20px;
}

.left-sidebar, .right-sidebar {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-block: 2px solid green;
   
}

.left-sidebar {
    grid-column: 1 / 2;
}

.center-content {
    grid-column: 2 / 4;
}

/* Media Queries for Medium and Small Screens */

@media (max-width: 991px) { /* Medium Screens */

  
  .main-content {
    grid-template-columns: 1fr;  
    gap: 10px;               
  }

  .left-sidebar,
  .right-sidebar,
  .center-content {
    grid-column: 1;            
  }
}

@media (max-width: 767px) { /* Small Screens */

  
  .main-content {
    padding: 10px;  
  }

 
}

/* .right-sidebar {
    grid-column: 3 / 4;
  } */

.files-grid {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.file-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    flex: 1;
    cursor: pointer;
}

.file-card img {
    max-width: 50px;
}

.section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.table-section {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-bar input {
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.user-icon {
    display: flex;
    align-items: center;
}

.user-icon img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: white;
    border-left: 1px solid #ddd;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1050;
    /* Make sure the sidebar is above other content */
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.file-details p {
    margin: 5px 0;
}

.activeFolder {
    background-color: #11b67a;
    display: block;
    color: white;
    padding: 8px;
    border: 1px solid #e9e6e6;
    border-radius: 8px;
    margin-top: 8px;

    margin-right: 10px
}

.inActiveFolder {
    padding: 8px;
    display: block;
    border: 1px solid #e9e6e6;
    border-radius: 8px;
    margin-top: 8px;
    margin-right: 10px;
}

.onFolderhover:hover {

    /* box-shadow: rgba(0, 128, 0, 0.144) 0px 0px 20px; */
    cursor: pointer;
    border-right: 2px solid #11b67a;
    border-left: 2px solid #11b67a;

}

.create-button {
    background:  linear-gradient(90deg, #007bc7 0%, #003994 100%);
    color: white;
    font-size: 16px;
    font-weight: lighter;
    padding: 8px 30px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 3px;
}

.create-button:hover {
    background:  linear-gradient(90deg, #5865f283 0%, #009445 100%);
}

.create-button i {
    margin-right: 8px;
}`

