import React, { Component, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import service from '../../services/service';
import adminServices from '../../services/adminServices';
import UserService from '../../services/UserService';

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import DataTable from 'react-data-table-component';


const languages = [

  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },

  // {
  //   code: 'hi',
  //    name: 'Hindi',
  //    country_code: 'in'
  //  },
  // {
  //   code: 'te',
  //   name: 'Telugu',
  //   country_code: 'in'
  // },
  // {
  //   code: 'pu',
  //   name: 'Punjabi',
  //   country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
]

const customStyles = {
  title: {
    style: {
      fontColor: 'red',
      fontWeight: '900',
    }
  },

  rows: {
    style: {
      minHeight: '72px'
    },
  },

  headCells: {
    style: {

      widthRight: '8px',
      widthLeft: '8px',
      fontSize: '17px',

    },
  },
  cells: {
    style: {
      widthRight: '8px',
      widthLeft: '8px',
      fontSize: '15px',

    },
  },
};


function Dashboard() {

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const [getData, setData] = useState([]);
  const [instructor, setInstructor] = useState();
  const [coursePublishRequestCount, setCoursePublishRequestCount] = useState();
  const [numberOfPendingApproval, setNumberOfPendingApproval] = useState();
  const [courseEnrollementDetails, setCourseEnrollementDetails] = useState();
  const [getRegistrationData, setRegistrationData] = useState([]);
  useEffect(() => {
    getNumbers()

    service.LearnersCountForStatus()
      .then((res) =>{
        //console.log(res.data);
        setRegistrationData(res.data);
      })
      .catch((err) =>{

      })

  }, [])

  const getNumbers = async () => {
    try {
      let result = await service.getNumberOfCounts()
      let no_Of_Instructor = await adminServices.getAllInstructors();
      let courseRequestPublishCount = await service.getPublishCourseRequestCount();
      let pendingUserApproval = await service.getNumberOfUserApproval();
      let courseEnrollementDetails = await service.getCourseEnrollementDetails();
      setCourseEnrollementDetails(courseEnrollementDetails.data);
      setNumberOfPendingApproval(pendingUserApproval.data)
      setData(result.data);
      setInstructor(no_Of_Instructor.data.length);
      setCoursePublishRequestCount(courseRequestPublishCount.data);
    } catch (e) {
      
    }
  }

  const Column = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Course Name",
      selector: (row) =>
        <>
          {`${row?.courseName}`}
        </>,
      wrap: true,
      width: "250px",
    },
    {
      name: "No. Of Request Pending",
      selector: row =>
        <>
          {row?.requestCount}
        </>,
      width: "250px",
      wrap: true,
    },
    {
      name: "No. Of Enrollement",
      selector: (row) =>
        <>
          {row?.enrolledCount}
        </>,
      width: "250px",
      wrap: true,

    },
    {
      name: "Course End Date",
      selector: (row) =>
        <>
          {row?.courseEndDate}
        </>,
      width: "auto"

    },
  ];

  const conditionalRowStyles = [

    {
      when: row => row.requestCount >= 1,
      style: {
        backgroundColor: 'rgba(242, 146, 57, 0.3)',
        color: 'black',
        '&:hover': {
          cursor: "pointer",
        },
      },
    },


  ]

  return (
    <div>
      {/* <div className="proBanner">
        <div>
          <span className="d-flex align-items-center purchase-popup">
            <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
            <a href="https://www.bootstrapdash.com/product/purple-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
            <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
          </span>
        </div>
      </div> */}
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white mr-2">
            <i className="mdi mdi-home"></i>
          </span> {t('dashboard')} </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>{t('overview')} <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="row">
      <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <Link to={`${process.env.PUBLIC_URL + "/users/view-instructors"}`} style={{ textDecoration: 'none' }}>
              <div className="card-body">
                <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
                <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Total Instructors <i className="mdi mdi-account-group mdi-24px float-right"></i>
                </h4>
                <h2 className="mb-5" style={{ color: "#ffffff" }}>{instructor}</h2>
                {/* <h2 className="mb-5">{getData.instructorCount}</h2> */}
                {/* <h6 className="card-text">Increased by 5%</h6> */}
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-info card-img-holder text-white">
          <Link to={`${process.env.PUBLIC_URL + "/users/view-learners"}`} style={{ textDecoration: 'none' }}>
            <div className="card-body">
              <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
              <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Total Approved Learners <i className="mdi mdi-account-group mdi-24px float-right"></i>
              </h4>
              <h2 className="mb-5" style={{ color: "#ffffff" }}>{getRegistrationData[0]?.learnerApprovedCount}</h2>
              {/* <h6 className="card-text">Decreased by 10%</h6> */}
            </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white" style={{ backgroundImage: "linear-gradient(#F54EA2,#ff7676)" }}>
            <Link to={`${process.env.PUBLIC_URL + "/users/view-request-learner"}`} style={{ textDecoration: 'none' }}>
              <div className="card-body">
                <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
                <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Pending User For Approvals<i className="mdi mdi-account-group mdi-24px float-right"></i>
                </h4>
                <h2 className="mb-5" style={{ color: "#ffffff" }}>{numberOfPendingApproval}</h2>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <div className="card-body">
              <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
               <h4 className="font-weight-normal mb-3">{t('library_e_contents')}<i className="mdi mdi-account-group mdi-24px float-right"></i>
              </h4> 
              <h2 className="mb-5">{instructor}</h2>
               <h2 className="mb-5">{getData.instructorCount}</h2> 
               <h6 className="card-text">Increased by 5%</h6> 
            </div>
          </div>
        </div> */}
      </div>
      <div className='row'>
      <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <Link to={`${process.env.PUBLIC_URL + "/courses/view-courses"}`} style={{ textDecoration: 'none' }}>
              <div className="card-body">
                <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
                <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Total Courses<i className="mdi mdi-library-books mdi-24px float-right"></i>
                </h4>
                <h2 className="mb-5" style={{ color: "#ffffff" }}>{getData.courseCount}</h2>
                {/* <h6 className="card-text">Increased by 60%</h6> */}
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <div className="card-body">
              <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
              <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Total Enrolled Learners <i className="mdi mdi-account-group mdi-24px float-right"></i>
              </h4>
              <h2 className="mb-5" style={{ color: "#ffffff" }}>{getData.learnerCount}</h2>
              {/* <h6 className="card-text">Decreased by 10%</h6> */}
            </div>
          </div>
          </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-info card-img-holder text-white" >
            <Link to={`${process.env.PUBLIC_URL + "/courses/courses-to-publish"}`} style={{ textDecoration: 'none' }}>
              <div className="card-body">
                <img src={require("../../assets/images/dashboard/circle.svg")} className="card-img-absolute" alt="circle" />
                <h4 className="font-weight-normal mb-3" style={{ color: "#ffffff" }}>Pending Course For Publish<i className="mdi mdi-account-group mdi-24px float-right"></i>
                </h4>
                <h2 className="mb-5" style={{ color: "#ffffff" }}>{coursePublishRequestCount}</h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h1 className="page-title font-weight-bold">Course Enrollment Requests</h1>
              <DataTable
                columns={Column}
                data={courseEnrollementDetails}
                striped
                highlightOnHover
                customStyles={customStyles}
                // paginationPerPage={5}
                // onChangeRowsPerPage={5}
                conditionalRowStyles={conditionalRowStyles}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;