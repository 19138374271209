import React, { useEffect, useState } from 'react';
import '../styles/sideDrawer.css';
import { Rating } from 'react-simple-star-rating';
import service from '../../../services/service';
import swal from 'sweetalert';

const SideDrawer = ({ showDrawer, setShowDrawer, getContentId, userId, libraryId }) => {
    let tenantId = 1;
    const [getContentRatingReview, setContentRatingReview] = useState();
    const [newContentRating, setNewContentRating] = useState(0);
    const [newContentReview, setNewContentReview] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [getRating, setRating] = useState([]);
    const [ratingCount, setRatingCount] = useState(0);



    useEffect(() => {
        if (getContentId !== null && getContentId !== undefined) {
            getRatingBasedOnContentId();
            fatchRatingData()
        }
    }, [getContentId]);

    useEffect(() => {
        if (getContentRatingReview) {
            setNewContentRating(getContentRatingReview.rating);
            setNewContentReview(getContentRatingReview.reviewText);
        }
    }, [getContentRatingReview]);

    const handleClose = () => {
        setShowDrawer(false);
    };
    const [tempRating, setTempRating] = useState(null);

    useEffect(() => {
        if (showDrawer) {
            let currentRating = 0;
            const interval = setInterval(() => {
                if (currentRating < 5) {
                    currentRating += 1;
                    setTempRating(currentRating);
                } else {
                    clearInterval(interval);
                    setTimeout(() => {
                        setTempRating(null);
                    }, 500);
                }
            }, 200);

            return () => {
                clearInterval(interval);
                setTempRating(null);
            };
        }
    }, [showDrawer]);

    const getRatingBasedOnContentId = () => {
        setNewContentRating(0);
        setNewContentReview('')
        setIsLoading(true);
        service.getRatingByItemIdUserIdReviewTypeTenantId(getContentId, 1, 2)
            .then((res) => {
                //console.log(res.data[0]);
                setContentRatingReview(res.data[0]);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                swal("", "Unable to fetch Rating and review for the content", "error");
            });
    };
    const fatchRatingData = async () => {
        try {
            const res = await service.getRatingByItemIdReviewTypeTenantId(getContentId, tenantId, 2);

            setRating(res.data);
            setRatingCount(res.data.length);
;
        } catch (e) {

        }
    };
    const handleSubmitRatingAndReview = () => {
        setIsLoading(true);
        let ratingAndReview = {
            reviewId: getContentRatingReview?.reviewId,
            learnerId: userId,
            rating: newContentRating,
            reviewText: newContentReview,
            itemId: getContentId,
            reviewStatus: 'Done',
            reviewType: 2,
            tenantId: 1,
        };

        const apiCall = getContentRatingReview === null || getContentRatingReview === undefined
            ? service.createrating(ratingAndReview)
            : service.updaterating(ratingAndReview);

        apiCall
            .then((res) => {
                if (res.status === 200) {
                    swal("", getContentRatingReview ? "Updated the Review and Rating" : "Submitted the Review and Rating", "success").then(() => { handleClose(); })
                    getRatingBasedOnContentId();
                    fatchRatingData()

                }
            })
            .catch((err) => {
                swal("", `Unable to ${getContentRatingReview ? 'Update' : 'Submit'} the Review and Rating`, "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRating = (rate) => {
        setNewContentRating(rate);
    };

    const handleReviewChange = (event) => {
        setNewContentReview(event.target.value);
    };
    const convertDate = (dateFormat) => {
        let timestamp = Date.parse(dateFormat);
        let date = new Date(timestamp);
        let d = date.toLocaleString("en-IN", { hour12: false, timeZone: "IST" });

        // let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
        return d;
    }

    return (
        <>
            <div
                className={`drawer-backdrop ${showDrawer ? 'open' : ''}`}
                onClick={handleClose}
            ></div>

            <div className={`drawer ${showDrawer ? 'open' : ''}`}>
                <div className="drawer-header">
                    <h4 style={{ textAlign: 'center', fontWeight: 'bold', position: "relative" }}>
                        <span className='text-success'>Rating & </span>
                        <span className='text-secondary'>Reviews</span>
                        <span style={{ position: 'absolute', left: "20%", bottom: "-3px", width: '50%', height: '2px', backgroundColor: '#28a745' }}></span>
                    </h4>
                    <span className="drawer-close" onClick={handleClose}>
                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                </div>

                <div className="drawer-content">
                    <div style={{ overflowY: 'auto', height: '60vh' }}>
                        {getRating.map((data) => {
                            // Case 1: Reviews not allowed and not the user's own review
                            //console.log(data?.reviewAllowed !== 1, userId !== data?.learnerUsername)
                            if (data?.reviewAllowed !== 1 || userId === data?.learnerUsername) {
                                return (
                                    <div className="d-flex justify-content-between border rounded shadow-sm mb-3 p-2" key={data.id || data.creationTime}>
                                        <div>
                                            <img src={data.profilePicUrl} alt="" height={45} width={45} className='rounded-circle' />
                                        </div>
                                        <div className="pl-2" style={{ width: '100%' }}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6>{data.firstName}</h6>
                                                    <p>{convertDate(data.creationTime)}</p>
                                                    <ul className="list-unstyled list-inline">
                                                        <Rating readonly size={25} initialValue={data.rating} />
                                                    </ul>
                                                </div>
                                            </div>
                                            <div>
                                                <p>{data.reviewText}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }

                            // Case 2: Reviews allowed and it's the user's own review
                            // if (data?.reviewAllowed === 1) {
                                

                            //     return (
                            //         <div className="d-flex justify-content-between border rounded shadow-sm mb-3 p-2" key={data.id || data.creationTime}>
                            //             <div>
                            //                 <img src={data.profilePicUrl} alt="" height={45} width={45} className='rounded-circle' />
                            //             </div>
                            //             <div className="pl-2" style={{ width: '100%' }}>
                            //                 <div className="d-flex justify-content-between">
                            //                     <div>
                            //                         <h6>{data.firstName}</h6>
                            //                         <p>{convertDate(data.creationTime)}</p>
                            //                         <ul className="list-unstyled list-inline">
                            //                             <Rating readonly size={25} initialValue={data.rating} />
                            //                         </ul>
                            //                     </div>
                            //                 </div>
                            //                 <div>
                            //                     <p>{data.reviewText}</p>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     );
                            // }

                            return null; // Return null for cases that don't match either condition
                        })}
                        {
                            getRating.length === 0 && <div>No Reviews found</div>
                        }
                    </div>
                </div>

                <div className="drawer-footer">
                    <Rating
                        onClick={handleRating}
                        transition={true}
                        allowFraction={true}
                        size={30}
                        initialValue={tempRating !== null ? tempRating : newContentRating}
                    />
                    <textarea
                        placeholder="Write your review..."
                        value={newContentReview}
                        onChange={handleReviewChange}
                    />
                    <button
                        className='create-button'
                        onClick={handleSubmitRatingAndReview}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        ) : (
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default SideDrawer;