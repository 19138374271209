import React, { useEffect, useState } from 'react';
import "./ContentDeliveryContentNotes.css";
import service from '../../../../services/service';
import { Button, Modal } from 'react-bootstrap';
import { CKEditor } from 'ckeditor4-react';
import ReactHtmlParser from "react-html-parser";
import swal from 'sweetalert';

const ContentDeliveryContentNotes = ({ selectedContent, courseId, toggleNotesSidebar, notesSideBarOpen, setNotesSideBarOpen }) => {


    const [contentNotesList, setContentNotesList] = useState([]);
    const [newContentNoteText, setnewContentNoteText] = useState(null);
    const [selectedNote, setSelectedNote] = useState({});

    const [noteModalOpen, setNoteModalOpen] = useState(false);
    const [editNotesMode, setEditNotesMode] = useState(false);

    useEffect(() => {
        fetchNoteByContentId();
    }, [selectedContent])


    // const toggleNotesSidebar = () => {
    //     setNotesSideBarOpen(!notesSideBarOpen);
    // }

    const fetchNoteByContentId = () => {
        service.getAllContentNotesByContentIdCourseIdUserId(courseId, selectedContent.id)
            .then((res) => {

                setContentNotesList(res.data)
            })
            .catch((err) => {

            })
    }

    const onNoteClick = (note) => {

        setSelectedNote(note);
        setEditNotesMode(true);
        setNoteModalOpen(true);
        setnewContentNoteText(note.noteMaster.noteText);
    }

    const onAddNoteClick = () => {
        setEditNotesMode(false);
        setNoteModalOpen(true);
        setnewContentNoteText("");
    }

    const handleCreateNote = () => {

        const newNote = {
            noteText: newContentNoteText,
            contentId: selectedContent.id,
            courseId: courseId,
        };

        service.createNote(newNote)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("", "Added Note", "success");
                    fetchNoteByContentId();
                    handleModalClose();
                }
            })
            .catch((err) => {
                swal("", "Failed to Add the note", "error");
            })
    }

    const handleUpdateNote = () => {

        const updatedNote = {
            noteText: newContentNoteText,
            contentId: selectedNote.id.contentId,
            courseId: selectedNote.id.courseId,
            noteId: selectedNote.id.noteId
        };

        service.updateContentNote(updatedNote)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("", "Updated Note", "success");
                    fetchNoteByContentId();
                    handleModalClose();
                }
            })
            .catch((err) => {
                swal("", "Failed to update the note", "error");
            })
    }

    const handleDeleteNote = () => {

        const deleteNote = {
            contentId: selectedNote.id.contentId,
            courseId: selectedNote.id.courseId,
            noteId: selectedNote.id.noteId
        };

        service.deleteContentNote(deleteNote)
            .then((res) => {
                if (res.status === 200) {
                    swal("", "Note deleted", "success");
                    fetchNoteByContentId();
                    handleModalClose();
                }
            })
            .catch((err) => {
                swal("", "Failed to delete the note", "error");
            })
    }

    const handleModalClose = () => {

        setNoteModalOpen(false);
        setSelectedNote({});
        setnewContentNoteText("");
    }

    const handleEditorChange = (event) => {
        setnewContentNoteText(event.target.value);
    };





    console.log("notesSideBarOpen: ", notesSideBarOpen);

    return (
        (Object.keys(selectedContent).length !== 0) && (selectedContent?.activityProgressforDependency === 100) && (
            <div
                id='content-delivery-notes-sidebar-main'
                className='content-delivery-notes-sidebar-main'
            >
                {/* <div
                    className={`content-delivery-notes-sidebar-toggle-button ${notesSideBarOpen ? "open" : ""}`}
                    onClick={toggleNotesSidebar}
                    title={notesSideBarOpen ? "Close Sidebar" : "Notes Sidebar"}
                >
                    <i className={`fa fa-2x fa-caret-${notesSideBarOpen ? `right` : `left`}`} aria-hidden="true"></i>
                </div> */}
                <div
                    id='content-delivery-notes-sidebar-main'
                    className={`content-delivery-notes-sidebar ${notesSideBarOpen ? "open" : ""}`}
                >
                    <div
                        id='content-delivery-notes-sidebar-main'
                        className='content-delivery-notes-sidebar-contents'
                    >
                        <p className='content-delivery-notes-sidebar-title'>
                            <button
                                className='content-delivery-notes-sidebar-close-button'
                                onClick={toggleNotesSidebar}
                            >
                                <i class="fa fa-2x fa-times" aria-hidden="true"></i>
                            </button>
                            Notes
                            <button
                                className='content-delivery-notes-sidebar-add-button'
                                onClick={onAddNoteClick}
                            >
                                <i class="fa fa-2x fa-plus-circle" aria-hidden="true"></i>
                            </button>
                        </p>


                        <div className='content-delivery-notes-list-main'>
                            {contentNotesList.length > 0 ?
                                <div className='content-delivery-notes-list'>
                                    {contentNotesList?.map((note) => (
                                        <div
                                            id={note.noteId}
                                            className='content-delivery-note'
                                        >
                                            <textarea
                                                className='content-delivery-note-textarea'
                                                placeholder=''
                                                value={note.noteMaster.noteText}
                                                onClick={() => onNoteClick(note)}
                                                contentEditable={false}
                                                inputMode='none'
                                            />
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='content-delivery-notes-notfound'>
                                    <p>No Notes Found</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    centered
                    show={noteModalOpen}
                >
                    <Modal.Header>
                        {editNotesMode ? "Update Note" : "Create Note"}
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            className='content-delivery-modal-textarea'
                            style={{
                                width: '100%',
                                height: '250px',
                                marginTop: '10px',
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                resize: 'vertical',
                                color: 'black',
                                cursor: 'pointer'
                            }}
                            placeholder='Enter your Notes here'
                            value={newContentNoteText}
                            onChange={handleEditorChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            editNotesMode ? (
                                <Button
                                    size='sm'
                                    onClick={handleUpdateNote}
                                >
                                    Update
                                </Button>

                            ) : (
                                <Button
                                    size='sm'
                                    onClick={handleCreateNote}
                                >
                                    Add Note
                                </Button>
                            )
                        }
                        {
                            editNotesMode && (
                                <Button
                                    size='sm'
                                    variant='danger'
                                    onClick={handleDeleteNote}
                                >
                                    Delete
                                </Button>
                            )
                        }
                        <Button
                            size='sm'
                            variant='warning'
                            onClick={handleModalClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    )
}

export default ContentDeliveryContentNotes