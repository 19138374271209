import React, { useEffect, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    Button,
    Card,
    Modal,
    OverlayTrigger,
    Tooltip,
    TabPane,
} from "react-bootstrap";
//import { Styles } from '../../pages/instructor/instCourses/styles/coursedetails';
import { Styles } from "./styles/coursedetails.js";
import FooterTwo from "../FooterTwo";
import HeaderTwo from "../HeaderTwo";
import swal from "sweetalert";
import "./styles/styles.scss";
import Videojs from "../../pages/instructor/instCourses/video.js";
import DataTable from "react-data-table-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import PopularCourse from '../../courses/components/PopularCourse'
// import CourseTag from '../../courses/components/CourseTag'
import TreeMenu from "react-simple-tree-menu";
// import '../../../../node_modules/react-simple-tree-menu/dist/main.css';
import { Alert, Form, ListGroup, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Timer from "react-compound-timer";
import { UncontrolledCollapse } from "reactstrap";
import "../../pages/instructor/styles.css";
import "../../pages/instructor/instCourses/styles/tree.css";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { Bar, Pie } from "react-chartjs-2";
import Query from "../../pages/courses/Query/Query";
import DiscussionMain from "../../pages/discussion/DiscussionMain";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import TopicFeedback from "../../pages/instructor/instCourses/TopicFeedback";
import { BreadcrumbBox } from "../common/Breadcrumb";
import UserService from "../../services/UserService";
import instructorService from "../../services/instructorService";
import service, { URI, USER_API } from "../../services/service";
import md5 from "md5";
import ViewPdf from "../../pages/instructor/ViewPdf.js";
import CryptoJS from "crypto-js";
import MediaContentView from "../../pages/courses/components/MediaContentView.js";
import useNonce from "../../helper/Nonce.js";
import { CourseDriveModal, DrapAndDropModal, UpdateFolderOrContentDetailsModal } from "../../pages/instructor/instCourses/instCourseComponents/instCourseModals.js";
import { PreviewModal } from "../../pages/instructor/instCourses/CourseStructureDriveNew.js";
import ContentReviews from "../../pages/courses/Reviews/ContentReviews.js";
import { Circles } from "react-loader-spinner";

const customStyles = {
    title: {
        style: {
            fontColor: "red",
            fontWeight: "900",
        },
    },
    headCells: {
        style: {
            fontSize: "17px",
            fontWeight: "500",
            textTransform: "uppercase",
            // paddingLeft: '0 8px',
            // marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: "15px",
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};

const languages = [
    {
        code: "en",
        name: "English",
        country_code: "gb",
    },

    {
        code: "hi",
        name: "Hindi",
        country_code: "in",
    },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
];

function InstLibraryDetails(props) {
    useEffect(() => {
        UserService.generateToken();
    }, []);
    const fetchAndSetNonce = useNonce();
    const um_api = URI.SERVER?._USER_API;


    const [ratingCount, setRatingCount] = useState(0);
    const [selectedContent, setselectedContent] = useState({})
    const [contentRatingModalShow, setContentRatingModalShow] = useState(false);
    const [getPageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPageLoading(false);
        }, 1000); // 1 seconds timeout

        return () => clearTimeout(timeout); // Clean up timeout on component unmount
    }, []);

    let tenantId = 1;
    // var courseID = props.match.params.cId;

    let UserId = UserService.getUserid();

    // for different languages
    const currentLanguageCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    useEffect(() => {
        document.body.dir = currentLanguage.dir || "ltr";
        document.title = t("app_title");
    }, [currentLanguage, t]);

    const decipher = (salt) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) =>
            textToChars(salt).reduce((a, b) => a ^ b, code);
        return (encoded) =>
            encoded
                .match(/.{1,2}/g)
                .map((hex) => parseInt(hex, 32))
                .map(applySaltToChar)
                .map((charCode) => String.fromCharCode(charCode))
                .join("");
    };

    // let courseid = props.match.params.cId;
    // // let tenantID = props.match.params.tid;
    // let hashcode = courseid.substring(0, 10);
    // const myDecipher = decipher(`${hashcode}`);
    // courseid = courseid.substring(10);
    // let courseId = myDecipher(`${courseid}`);
    // let tenantId = myDecipher(`${tenantID}`);
    const [courseId, setDecodedCourseId] = useState(null)
    const [isDecodeValid, setIsDecodeValid] = useState("NOTVALID")

    // const [tenantId, setDecodedTenantId] = useState(null)

    const decodeHandler = () => {
        const secretKey = "cdac@123"

        const encodedCourseId = props.match.params.cId.replace(/-/g, "+").replace(/_/g, "/");
        const decodedCourseId = CryptoJS.AES.decrypt(
            encodedCourseId,
            secretKey
        ).toString(CryptoJS.enc.Utf8);

        // const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
        // const decodedTenantId = CryptoJS.AES.decrypt(
        //     encodedTenantId,
        //     secretKey
        // ).toString(CryptoJS.enc.Utf8);

        const regex = /^[0-9]+$/

        const isValidCourseId = regex.test(decodedCourseId)
        // const isValidTenantId = regex.test(decodedTenantId)

        if (isValidCourseId) {
            setIsDecodeValid("VALID")
            setDecodedCourseId(decodedCourseId)
            // setDecodedTenantId(decodedTenantId)
        }
        else {
            setIsDecodeValid("NOTVALID")

            swal({
                title: t('something_went_wrong_try_later'),
                text: t('redirecting_to_course_list'),
                timer: 5000,
            }).then(() => {
                // history.push(`${process.env.PUBLIC_URL + "/course-grid/"}`);
            })
        }

    }

    useEffect(() => {



        if (isDecodeValid !== "VALID") {
            decodeHandler()
        }

    }, [isDecodeValid])

    const [getCourseDetails, setCourseDetails] = useState([]);
    const [startDate, setStartDate] = useState();
    const [getRating, setRating] = useState([]);
    const [getServerTime, setServerTime] = useState();
    const [isActiveFolderId, setIsActiveFolderId] = useState();
    const [getColorActiveId, setColorActiveId] = useState();
    const [getYouTubeUrl, setYouTubeUrl] = useState();
    const [completionType, setCompletionType] = useState("None");
    const [restriction, setRestriction] = useState(" ");
    const [getLibraryOwnUserId, setLibraryOwnUserId] = useState();

    const publishedDate = (date) => {
        const p_date = new Date(date).getDate();
        const p_month = new Date(date).getMonth();
        const p_year = new Date(date).getFullYear();

        const pub_date = `${p_date}/${p_month}/${p_year}`;

        return pub_date;
    };

    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className="btn btn-info" onClick={onClick} ref={ref}>
            {t('publish_date_s')}: {value}
        </button>
    ));

    useEffect(() => {
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach((button) => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    const scrollToView = () => {
        const ref = document.getElementById("Tab");
        ref.scrollIntoView({
            behavior: "smooth",
            inline: "start",
        });
    };

    const initialStateId = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        gender: "",
        dob: "",
        instituteName: "",
        eduQualification: "",
        address: "",
        city: "",
        pincode: "",
        countryId: "",
        stateId: "",
        districtId: "",
        updateBy: props.match.params.updateBy,
        learnerUsername: props.match.params.learnerUsername,
        firstNameError: "",
        lastNameError: "",
        genderError: "",
        dobError: "",
        mobileError: "",
        eduQualificationError: "",
        instituteNameError: "",
        addressError: "",
        cityError: "",
        pincodeError: "",
        countryIdError: "",
        stateIdError: "",
        districtIdError: "",
        facebookId: "",
        twitterId: "",
        linkedinId: "",
        youtubeId: "",
        skypeId: "",
        facebookIdError: "",
        twitterIdError: "",
        linkedinIdError: "",
        youtubeIdError: "",
        skypeIdError: "",
    };

    const [getUserDetails, setUserDetails] = useState(initialStateId);

    useEffect(() => {
        if (isDecodeValid === "VALID") {
            setPageLoading(true)
            service
                .getUserById(UserId)
                .then((res) => {
                    setUserDetails(res.data);

                })
                .catch((err) => {

                });
        }
    }, [courseId]);

    const [getCourseStructureJson, setCourseStructureJson] = useState([]);
    const [userCount, setUserCount] = useState();
    const [announcementDetails, setAnnouncementDetails] = useState([]);
    const [getNoOfContents, setNoOfContents] = useState(0);
    const [getToggleAddContent, setToggleAddContent] = useState(false)
    const [courseDriveModal, setCourseDriveModal] = useState(false)
    const [folderData, setFolderData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getLibrary, setLibrary] = useState(true);
    const [getContentName, setContentName] = useState();
    const [getLibSelectedAccessType, setLibSelectedAccessType] = useState({})
    const [getLibAccessType, setLibAccessType] = useState()


    //folder structure modal
    const toggleCourseDriveModal = () => {

        setCourseDriveModal(prevState => !prevState)
    }
    const openModal = () => {

        setIsModalOpen(true);
    };
    const closeModal = () => {


        setIsModalOpen(false);
    };
    const ContentModal = ({
        isOpen,
        closeModal,
        folderData,
        onSelectContent,
    }) => {
        const [selectedContentId, setSelectedContentId] = useState(null);

        const saveContent = () => {
            addContentInBetween(
                newArr,
                getCourseStructureFolderId,
                selectedContentId
            );

        };

        const handleSelectContent = (contentId) => {
            setSelectedContentId(contentId);
        };

        const listItemStyle = {
            margin: '0.5rem 0',
            padding: '0.5rem',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
            display: 'flex',
            alignItems: 'center',
        };

        const buttonStyle = {
            cursor: 'pointer',
            marginRight: '1rem',
            border: 'none',
            backgroundColor: 'transparent',
            color: '#007bff',
            fontWeight: 'bold',
            transition: 'color 0.3s ease',
        };

        const buttonHoverStyle = {
            textDecoration: 'underline',
            color: '#5c5c5c',
        };

        const renderNode = (node) => (
            <div key={node.id}>
                {node.nodes && node.nodes.length > 0 && (
                    <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                        {node.nodes.map((childNode) => (
                            <li
                                key={childNode.id}
                                style={{
                                    ...listItemStyle,
                                    backgroundColor:
                                        selectedContentId === childNode.id ? '#e6e6e6' : listItemStyle.backgroundColor,
                                }}
                            >
                                <button
                                    style={buttonStyle}
                                    onClick={() => handleSelectContent(childNode.id)}
                                >
                                    <span>
                                        Add Content Above<strong> ⇧</strong>
                                    </span>
                                    <span style={buttonHoverStyle}>
                                        {childNode.label}
                                    </span>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );

        return (
            <Modal
                show={isOpen}
                onHide={closeModal}
                backdrop="static"
                size="lg"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Folder Name: {folderData && folderData.label}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{folderData && renderNode(folderData)}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={saveContent}>Add</Button>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const addContentInBetween = async (
        newContentArray,
        folderId,
        existingContentId
    ) => {
        await fetchAndSetNonce();
        if (folderId === undefined) {
            swal(`${t("select")}`, `${t("node_select")}`, "warning");
        } else {
            instructorService
                .addContentInBetween(newContentArray, existingContentId)
                .then(async (res) => {
                    if (res.data === "Content added successfully!!") {
                        closeModal();
                        await swal(`${t("success")}`, `${t("content_added")}`, "success");
                        toggleCourseDriveModal()
                        // setToggle(false);
                        instructorService.getCourseById(courseId).then((res) => {
                            //   setInactiveCertificateInCourse(res.data.certificate);
                            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                        });
                        folderStructureTreeCourse();
                    } else {
                        closeModal();
                        await swal(`error`, `ERROR`, "error");
                    }
                })
                .catch((err) => {

                });
        }
    };
    const findNodeById = (node, targetId) => {

        if (node.id === targetId) {
            return node;
        }

        if (node.nodes && node.nodes.length > 0) {
            for (const childNode of node.nodes) {
                const result = findNodeById(childNode, targetId);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };
    useEffect(() => {
        if (isDecodeValid === "VALID") {
            fatchCourseData();
            fatchRatingData();
            fatchServerTime();
            fatchUserCount();
            fatchAnnouncementData();
        }
    }, [courseId]);

    const fatchCourseData = async () => {
        try {
            const res = await instructorService.getLibraryById(courseId);
            setCourseDetails(res.data);
            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));

            JSON.parse(res.data.courseStructureJson).nodes.map((data) => {

                setNoOfContents(data.nodes.length);
            });
        } catch (e) {

        }
    };
    const fatchUserCount = async () => {
        try {
            const res = await service.userCount(courseId, tenantId);
            setUserCount(res.data.userCount);
        } catch (error) {

        }
    };

    const fatchRatingData = async () => {
        try {
            const res = await service.getoverallRating(courseId, tenantId);
            setRating(res.data);
            setRatingCount(res.data.length);

        } catch (e) {

        }
    };
    const fatchServerTime = async () => {
        try {
            const res = await instructorService.getServerTime();
            setStartDate(new Date(res.data));
        } catch (e) {

        }
    };

    const [ratingView, setRatingView] = useState(false);

    useEffect(() => {
        if (isDecodeValid === "VALID") {
            checkRatingBody();
        }
    }, [ratingCount, courseId]);

    const checkRatingBody = () => {
        if (ratingCount !== 0) {
            setRatingView(true);
        }
    };

    const fatchAnnouncementData = async () => {
        try {
            const res = await service.getAllCourseAnnouncementListByAuthor(
                UserId,
                courseId
            );
            setAnnouncementDetails(res.data);
        } catch (error) { }
    };

    const [isActive, setIsActive] = useState({
        folder: 0,
        file: 0,
    });

    useEffect(() => {
        folderStructureTreeCourse();
    }, [getColorActiveId, isActive, courseId]);

    useEffect(() => {
        if (isDecodeValid === "VALID") {

            folderStructureTree();
        }
    }, [isActiveFolderId, isActive, courseId]);

    const scrollWin = () => {
        document.getElementById("Main").scrollIntoView({ behavior: "smooth" });
        //window.scrollTo(0, 290);
    };

    const fee_validator = (fees) => {
        if (fees === 0) {
            return <p>{t('free')}</p>;
        } else {
            return <p>&#8377;{fees}</p>;
        }
    };

    const convertDate = (dateFormat) => {
        let timestamp = Date.parse(dateFormat);
        let date = new Date(timestamp);
        let d = date.toLocaleString("en-IN", { hour12: false, timeZone: "IST" });

        // let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
        return d;
    };

    /* Course Structure Create Code Start */

    const [getFolder, setFolder] = useState([]);
    const [getFolderName, setFolderName] = useState();
    const [getLoading, setLoading] = useState();
    const [errorMsg, setErrorMsg] = useState();
    useEffect(() => {
        instructorService
            .getFolderStructure(UserId)
            .then((res) => {
                setFolder(res.data);
            })
            .catch((err) => {
                swal(t("error"), t("try_sometimes"), "error");
            });
    }, [courseId]);

    const abc1 = (dirId, dirName) => {
        setIsActiveFolderId(dirId);
        setParentId(dirId);
        setFolderName(dirName);
        instructorService.contentDetails(dirId, UserId).then((res) => {

            setContentDetails(res.data);
        });
    };

    const [showRestriction, setShowRestriction] = useState(false);

    const handleOpenRestriction = (e) => {
        setRestriction(e.target.value);

        if (e.target.value == "Yes") {
            setShowRestriction(true);
        } // to show restriction modal portion
        else {
            setShowRestriction(false);
        }
    };

    const [getParentId, setParentId] = useState([]);
    const [getUploadModalState, setUploadModalState] = useState({
        show: false,
    });
    const [getModalState, setModalState] = useState({
        show: false,
    });
    const [getTopicFeedback, setTopicFeedback] = useState({
        show: false,
    });

    const handleModal2 = () => {
        setModalState({ show: false });
    };

    const handleModal = () => {
        setModalState({ show: true });
    };

    const FileUploadModalShow = () => {
        setUploadModalState({ show: true });
    };

    const FileUploadModalHide = () => {
        setUploadModalState({ show: false });
    };

    // feedback Modal show and hide

    const [itemIdForFeedback, setItemIdForFeedback] = useState();
    const [videoEncodeStatus, setVideoEncodeStatus] = useState(null);

    const handleTopicFeedback = (id) => {
        setTopicFeedback({ show: true });
        setItemIdForFeedback(id);

    };

    const TopicFeedbackModalHide = () => {
        setTopicFeedback({ show: false });
    };

    const UrlModalHide = () => {
        setUrlModal({ show: false });
    };

    const [getUrlModal, setUrlModal] = useState({
        show: false,
    });

    const alertMsg = () => {
        swal(t("select"), t("node"), "warning");
    };

    const [getContentDetails, setContentDetails] = useState([]);
    const dirClick = (dirId, dirName) => {
        var togglers = document.querySelectorAll(".caret");
        togglers.forEach((toggler) => {
            toggler.onclick = function () {
                toggler.parentElement
                    .querySelector(".nested")
                    .classList.toggle("active");
                toggler.classList.toggle("caret-down");
            };
        });
    };

    const deleteDirectory = (id) => {
        swal({
            title: t("swal_title"),
            text: t("delete_folder_structure"),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: t('yes_delete'),
            closeOnConfirm: false,
        }).then((isConfirmed) => {
            if (isConfirmed) {
                let data = { lastModifiedBy: UserId, dirParentId: id };
                instructorService.deleteDirectory(data).then(async (res) => {
                    if (res.data === "deleted successfully") {
                        await swal(t("deleted"), t("your_folder_deleted"), "success");
                        instructorService.getFolderStructure(UserId).then((res) => {
                            setFolder(res.data);
                        });
                    }
                });
            }
        });
    };

    const [items, setItems] = useState([]);
    function folderStructureTree() {
        instructorService.getFolderStructure(UserId).then((res) => {

            let menuItems = res.data.map((item, i) => {
                let menuItem = returnMenuItem(item, i);
                return menuItem;
            });
            setItems(menuItems);
        });
        const returnMenuItem = (item, i) => {
            let menuItem;
            if (item.Child.length === 0) {
                menuItem = (
                    <div className="item" key={i}>
                        <div>
                            <span
                                style={
                                    isActiveFolderId == item.Id
                                        ? {
                                            backgroundColor: "#11b67a",
                                            display: "block",
                                            color: "white",
                                            padding: "8px",
                                            border: "1px solid #d3d3d3",
                                            borderRadius: "5px",
                                            marginTop: "8px",
                                            verticalAlign: "middle",
                                            marginRight: "10px",
                                        }
                                        : {
                                            padding: "8px",
                                            display: "block",
                                            border: "1px solid #d3d3d3",
                                            borderRadius: "5px",
                                            marginTop: "8px",
                                            verticalAlign: "middle",
                                            marginRight: "10px",
                                        }
                                }
                                onClick={() => abc1(item.Id, item.Name)}
                            >
                                <i
                                    className="fas fa-folder"
                                    style={
                                        isActiveFolderId == item.Id
                                            ? { fontSize: "18px", color: "white" }
                                            : { fontSize: "18px", color: "black" }
                                    }
                                ></i>
                                <span style={{ marginLeft: "10px" }}>
                                    {item.Name} &nbsp;&nbsp;
                                </span>
                                {/* <span style={{ position: 'relative', float: 'right' }}>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                        <span className="d-inline-block">
                                            <a class="link" href="#"><i class="fas fa-trash" onClick={() => deleteDirectory(item.Id)} style={{ fontSize: "16px" }}></i></a>&nbsp;&nbsp;
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                        <span className="d-inline-block">
                                            <a class="link" href="#"><i class="fas fa-edit" onClick={() => directoryEdit(item.Id, item.Name)} style={{ fontSize: "16px" }}></i></a>
                                        </span>
                                    </OverlayTrigger>
                                </span> */}
                            </span>
                        </div>
                    </div>
                );
            } else {
                let menuItemChildren = item.Child.map((item, i) => {
                    let menuItem = returnMenuItem(item, i);
                    return menuItem;
                });
                menuItem = (
                    <div key={i} className="item">
                        <div className="toggler" id={`toggle-menu-item-${item.Id}`}>
                            <div>
                                <span
                                    style={
                                        isActiveFolderId == item.Id
                                            ? {
                                                backgroundColor: "#11b67a",
                                                display: "block",
                                                color: "white",
                                                padding: "8px",
                                                border: "1px solid #d3d3d3",
                                                borderRadius: "5px",
                                                marginTop: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "10px",
                                            }
                                            : {
                                                padding: "8px",
                                                display: "block",
                                                border: "1px solid #d3d3d3",
                                                borderRadius: "5px",
                                                marginTop: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "10px",
                                            }
                                    }
                                    onClick={() => abc1(item.Id, item.Name)}
                                >
                                    <i
                                        className="fas fa-folder"
                                        style={
                                            isActiveFolderId == item.Id
                                                ? { fontSize: "18px", color: "white" }
                                                : { fontSize: "18px", color: "black" }
                                        }
                                    ></i>
                                    <span
                                        onClick={() => abc1(item.Id, item.Name)}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {item.Name} &nbsp;&nbsp;
                                    </span>
                                    {/* <span style={{ position: 'relative', float: 'right' }}>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                            <span className="d-inline-block">
                                                <a class="link" href="#"><i class="fas fa-trash" onClick={() => deleteDirectory(item.Id)} style={{ fontSize: "16px" }}></i></a>&nbsp;&nbsp;
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                            <span className="d-inline-block">
                                                <a class="link" href="#"><i class="fas fa-edit" onClick={() => directoryEdit(item.Id, item.Name)} style={{ fontSize: "16px" }}></i></a>
                                            </span>
                                        </OverlayTrigger>
                                    </span> */}
                                </span>
                            </div>
                        </div>
                        <UncontrolledCollapse
                            className="children"
                            toggler={`#toggle-menu-item-${item.Id}`}
                        >
                            {menuItemChildren}
                        </UncontrolledCollapse>
                    </div>
                );
            }
            return menuItem;
        };
    }

    const folderColorChangeHandler = (itemId) => {
        setColorActiveId(itemId);
    };

    const [globalState, setGlobalState] = useState([]);

    const Breadcrumb = () => {
        return (
            <>
                {globalState.map((obj) => (
                    <>
                        <a class="btn btn-default">{obj}</a>
                    </>
                ))}
            </>
        );
    };
    const [activeAddModule, setActiveAddModule] = useState(false);
    const handleClickAddModule = () => {
        setActiveAddModule(!activeAddModule);
    };

    const [apiData] = useState([]);
    const [itemsCourse, setItemsCourse] = useState([]);
    function folderStructureTreeCourse() {
        let menuData = [];
        instructorService.getLibraryById(courseId).then((res) => {
            setLibraryOwnUserId(res.data.userId);

            //apiData.push([JSON.parse(res.data.courseStructureJson)]);
            menuData = [JSON.parse(res.data.courseStructureJson)];
            let menuItems = menuData.map((item, i) => {
                let menuItem = returnMenuItem(item, i);
                return menuItem;
            });
            setItemsCourse(menuItems);
        });

        const returnMenuItem = (item, i) => {
            let menuItem;
            if (item.nodes.length === 0) {
                menuItem = (
                    <div className="item" key={i}>
                        <span
                            style={
                                getColorActiveId == item.id
                                    ? {
                                        backgroundColor: "#11b67a",
                                        display: "block",
                                        color: "white",
                                        padding: "8px",
                                        border: "1px solid #d3d3d3",
                                        borderRadius: "5px",
                                        marginTop: "8px",
                                        verticalAlign: "middle",
                                        marginRight: "10px",
                                    }
                                    : {
                                        padding: "8px",
                                        display: "block",
                                        border: "1px solid #d3d3d3",
                                        borderRadius: "5px",
                                        marginTop: "8px",
                                        verticalAlign: "middle",
                                        marginRight: "10px",
                                    }
                            }
                            onClick={() => [
                                menuData.map((itemTopic) => {
                                    // if (itemTopic.id === item.id) {
                                    //     globalState.length = 0;
                                    //     globalState.push(itemTopic.label);
                                    //     setIsActive({ folder: itemTopic.label, file: item.id });
                                    //     // if(itemTopic.nodetype == "root"){
                                    //     //     handleClickAddModule();
                                    //     // }
                                    // }
                                    itemTopic.nodes.map((itemContent) => {
                                        if (itemContent.id === item.id) {
                                            globalState.length = 0;
                                            globalState.push(itemTopic.label, itemContent.label);
                                            setIsActive({ folder: itemContent.label, file: item.id });
                                            // if(itemContent.nodetype == "root"){
                                            //     handleClickAddModule();
                                            // }
                                        }
                                        // itemContent.nodes &&
                                        //     itemContent.nodes.map((itemtype) => {
                                        //         if (itemtype.id === item.id) {
                                        //             globalState.length = 0;
                                        //             globalState.push(itemTopic.label, itemContent.label, itemtype.label);
                                        //             setIsActive({ folder: itemContent.label, file: item.id });
                                        //             // if(itemtype.nodetype == "root"){
                                        //             //     handleClickAddModule();
                                        //             // }
                                        //         }
                                        //     });
                                    });
                                }),
                                folderColorChangeHandler(item.id),
                            ]}
                        >
                            <i
                                className={
                                    item.nodetype == "pdf"
                                        ? "fas fa-file-pdf fa-lg"
                                        : item.nodetype == "png" || item.nodetype == "jpg"
                                            ? "fas fa-image fa-lg"
                                            : item.nodetype == "zip"
                                                ? "fas fa-file-archive fa-lg"
                                                : item.nodetype == "scorm"
                                                    ? "fas fa-file-archive fa-lg"
                                                    : item.nodetype == "html"
                                                        ? "fab fa-html5 fa-lg"
                                                        : item.nodetype == "youtube"
                                                            ? "fab fa-youtube fa-lg"
                                                            : item.nodetype == "mp4"
                                                                ? "fas fa-video fa-lg"
                                                                : item.nodetype == "folder"
                                                                    ? "fas fa-folder fa-lg"
                                                                    : item.nodetype == "root"
                                                                        ? "fas fa-house-user fa-lg"
                                                                        : "fas fa-folder"
                                }
                                style={
                                    isActiveFolderId == item.id
                                        ? { fontSize: "18px", color: "white" }
                                        : { fontSize: "18px", color: "black" }
                                }
                            ></i>
                            <span style={{ marginLeft: "10px" }}>
                                {item.label} &nbsp;&nbsp;
                            </span>
                            {item.nodetype == "root" ? (
                                <span style={{ position: "relative", float: "right" }}>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                {t("add_content_files")}
                                            </Tooltip>
                                        }
                                    >
                                        <a class="hover-fx1" onClick={() => dmsUIVisible(item.id)}>
                                            <i
                                                className="fa fa-file"
                                                style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e", background: "rgba(2, 230, 147, 0.6)" }}
                                            // style={{
                                            //     color: "#f0ad4e",
                                            //     background: "rgba(2, 230, 147, 0.6)",
                                            // }}
                                            ></i>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Course Structure</Tooltip>}>
                                        <a className="hover-fx1" onClick={() => handleDragModalOpen(item, false)}>
                                            <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                                        </a>
                                    </OverlayTrigger>

                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Module/Topic</Tooltip>}>
                                    <a class="hover-fx1" onClick={() => { AddModuleModalShow(item.id); handleClickAddModule(); }} ><i className="fas fa-folder-plus" style={activeAddModule ? { color: "white" } : { color: "#015e3c" }}></i></a>
                                    style={activeAddModule ? {color: "white"} : {color:"#5cb85c"}
                                </OverlayTrigger> */}
                                </span>
                            ) : item.nodetype == "folder" ? (
                                <span style={{ position: "relative", float: "right" }}>
                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Youtube Video</Tooltip>}>
                                    <a class="hover-fx1" onClick={() => handleModal()}><i className="fa fa-youtube-play" style={{ color: '#f0ad4e', fontSize:'36px'}}></i></a>
                                </OverlayTrigger> */}
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                {t("add_content_files")}
                                            </Tooltip>
                                        }
                                    >
                                        <a class="hover-fx1" onClick={() => dmsUIVisible(item.id)}>
                                            <i
                                                className="fa fa-file"
                                                // style={{
                                                //     color: "#f0ad4e",
                                                //     background: "rgba(2, 230, 147, 0.6)",
                                                // }}
                                                style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e", background: "rgba(2, 230, 147, 0.6)" }}
                                            ></i>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Course Structure</Tooltip>}>
                                        <a className="hover-fx1" onClick={() => handleDragModalOpen(item, false)}>
                                            <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                                        </a>
                                    </OverlayTrigger>
                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Access Restriction</Tooltip>}>
                                    <a class="hover-fx1" onClick={() => addAccessRestriction()}><i className="fas fa-lock" style={{ color: '#f0ad4e' }}></i></a>
                                </OverlayTrigger> */}
                                    {item.userId === UserId && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>
                                            }
                                        >
                                            <a
                                                class="hover-fx1"
                                                onClick={() =>
                                                    updateFolderOrContent(
                                                        item.id,
                                                        item.label,
                                                        item.publishDate,
                                                        item
                                                    )
                                                }
                                            >
                                                <i
                                                    className="fa fa-edit"
                                                    style={{ color: "#f0ad4e" }}
                                                ></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                    {item.userId === UserId && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rating and Review</Tooltip>}>
                                        <a className="hover-fx1" onClick={() => contentReviewSelected(item)}>
                                            <i className="fa fa-star-half-o" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                                        </a>
                                    </OverlayTrigger>}
                                    {item.userId === UserId && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip-disabled">{t("remove")}</Tooltip>
                                            }
                                        >
                                            <a
                                                class="hover-fx1"
                                                onClick={() => RemoveContentOrStructure(item.id)}
                                            >
                                                <i
                                                    className="fas fa-trash-alt"
                                                    style={{ color: "#d9534f" }}
                                                ></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}

                                </span>
                            ) : (
                                <span style={{ position: "relative", float: "right" }}>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                {t("view_content")}
                                            </Tooltip>
                                        }
                                    >
                                        <a
                                            class="hover-fx1"
                                            onClick={() =>
                                                courseStructurContentView(item.nodetype, item.filePath, item.label)
                                            }
                                        >
                                            <i className="fa fa-eye" style={{ color: "#94b8b8" }}></i>
                                        </a>
                                    </OverlayTrigger>
                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Feedback</Tooltip>}>
                                    <a class="hover-fx1" onClick={() => handleTopicFeedback(item.id)}><i className="fas fa-comments" style={{ color: '#f0ad4e' }}></i></a>
                                </OverlayTrigger> */}
                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Activity Completion</Tooltip>}>
                                    <a class="hover-fx1" onClick={() => addActivityCompletion()}><i className="fas fa-clipboard-check" style={{ color: '#f0ad4e' }}></i></a>
                                </OverlayTrigger> */}
                                    {item.userId === UserId && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>
                                            }
                                        >
                                            <a
                                                class="hover-fx1"
                                                onClick={() =>
                                                    updateFolderOrContent(
                                                        item.id,
                                                        item.label,
                                                        item.publishDate,
                                                        item
                                                    )
                                                }
                                            >
                                                <i
                                                    className="fa fa-edit"
                                                    style={{ color: "#f0ad4e" }}
                                                ></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                    {item.userId === UserId && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rating and Review</Tooltip>}>
                                        <a className="hover-fx1" onClick={() => contentReviewSelected(item)} >
                                            <i className="fa fa-star-half-o" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                                        </a>
                                    </OverlayTrigger>}
                                    {item.userId === UserId && (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip-disabled">{t("remove")}</Tooltip>
                                            }
                                        >
                                            <a
                                                class="hover-fx1"
                                                onClick={() => RemoveContentOrStructure(item.id)}
                                            >
                                                <i
                                                    className="fas fa-trash-alt"
                                                    style={{ color: "#d9534f" }}
                                                ></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                </span>
                            )}
                        </span>
                    </div>
                );
            } else {
                let menuItemChildren = item.nodes.map((item, i) => {
                    let menuItem = returnMenuItem(item, i);
                    return menuItem;
                });
                menuItem = (
                    <div key={i} className="item">
                        <div>
                            <div className="toggler" id={`toggle-menu-item-${item.id}`}>
                                <span
                                    style={
                                        getColorActiveId == item.id
                                            ? {
                                                backgroundColor: "#11b67a",
                                                display: "block",
                                                color: "white",
                                                padding: "8px",
                                                border: "1px solid #d3d3d3",
                                                borderRadius: "5px",
                                                marginTop: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "10px",
                                            }
                                            : {
                                                padding: "8px",
                                                display: "block",
                                                border: "1px solid #d3d3d3",
                                                borderRadius: "5px",
                                                marginTop: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "10px",
                                            }
                                    }
                                    onClick={() => [
                                        menuData.map((itemTopic) => {
                                            if (itemTopic.id === item.id) {
                                                globalState.length = 0;
                                                globalState.push(itemTopic.label);
                                                setIsActive({ folder: itemTopic.label, file: item.id });
                                            }
                                            itemTopic.nodes.map((itemContent) => {
                                                if (itemContent.id === item.id) {
                                                    globalState.length = 0;
                                                    globalState.push(itemTopic.label, itemContent.label);
                                                    setIsActive({
                                                        folder: itemContent.label,
                                                        file: item.id,
                                                    });
                                                }
                                            });
                                        }),
                                        folderColorChangeHandler(item.id),
                                    ]}
                                >
                                    <i
                                        className={
                                            item.nodetype == "folder"
                                                ? "fas fa-folder fa-lg"
                                                : item.nodetype == "root"
                                                    ? "fas fa-house-user fa-lg"
                                                    : "fas fa-folder fa-lg"
                                        }
                                        style={
                                            isActiveFolderId == item.id
                                                ? { fontSize: "18px", color: "black" }
                                                : { fontSize: "18px", color: "black" }
                                        }
                                    ></i>
                                    <span style={{ marginLeft: "10px" }}>
                                        {item.label} &nbsp;&nbsp;
                                    </span>
                                    {item.nodetype == "folder" ? (
                                        <span style={{ position: "relative", float: "right" }}>
                                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Youtube Video</Tooltip>}>
                                        <a class="hover-fx1" onClick={() => handleModal()}><i className="fab fa-youtube" style={{ color: '#f0ad4e'}}></i></a>
                                        </OverlayTrigger> */}
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                        {t("add_content_file")}
                                                    </Tooltip>
                                                }
                                            >
                                                <a
                                                    class="hover-fx1"
                                                    onClick={() => dmsUIVisible(item.id)}
                                                >
                                                    <i
                                                        className="fa fa-file"
                                                        style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e", background: "rgba(2, 230, 147, 0.6)" }}
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Course Structure</Tooltip>}>
                                                <a className="hover-fx1" onClick={() => handleDragModalOpen(item, false)}>
                                                    <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                                                </a>
                                            </OverlayTrigger>
                                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Access Restriction</Tooltip>}>
                                            <a class="hover-fx1" onClick={() => addAccessRestriction()}><i className="fas fa-lock" style={{ color: '#f0ad4e' }}></i></a>
                                        </OverlayTrigger> */}
                                            {item.userId === UserId && (
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>
                                                    }
                                                >
                                                    <a
                                                        class="hover-fx1"
                                                        onClick={() =>
                                                            updateFolderOrContent(
                                                                item.id,
                                                                item.label,
                                                                item.publishDate,
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-edit"
                                                            style={{ color: "#f0ad4e" }}
                                                        ></i>
                                                    </a>
                                                </OverlayTrigger>
                                            )}
                                            {item.userId === UserId && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rating and Review</Tooltip>}>
                                                <a className="hover-fx1" onClick={() => contentReviewSelected(item)}>
                                                    <i className="fa fa-star-half-o" style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e" }}></i>
                                                </a>
                                            </OverlayTrigger>}
                                            {item.userId === UserId && (
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip id="tooltip-disabled">
                                                            {t("remove")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        class="hover-fx1"
                                                        onClick={() => RemoveContentOrStructure(item.id)}
                                                    >
                                                        <i
                                                            className="fas fa-trash-alt"
                                                            style={{ color: "#d9534f" }}
                                                        ></i>
                                                    </a>
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                    ) : item.nodetype == "root" ? (
                                        <span style={{ position: "relative", float: "right" }}>
                                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Module/Topic</Tooltip>}>
                                            <a class="hover-fx1" onClick={() => { AddModuleModalShow(item.id); handleClickAddModule(); }} ><i className="fas fa-folder-plus" style={activeAddModule ? { color: "white" } : { color: "#015e3c" }}></i></a>
                                            style={activeAddModule ? {color: "white"} : {color:"#5cb85c"}
                                        </OverlayTrigger> */}
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                        {t("add_content_files")}
                                                    </Tooltip>
                                                }
                                            >
                                                <a class="hover-fx1" onClick={() => dmsUIVisible(item.id)}>
                                                    <i
                                                        className="fa fa-file"
                                                        // style={{
                                                        //     color: "#f0ad4e",
                                                        //     background: "rgba(2, 230, 147, 0.6)",
                                                        // }}
                                                        style={{ color: getColorActiveId === item.id ? "white" : "#f0ad4e", background: "rgba(2, 230, 147, 0.6)" }}
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rearrange Course Structure</Tooltip>}>
                                                <a className="hover-fx1" onClick={() => handleDragModalOpen(item, false)}>
                                                    <i className="fa fa-arrows" style={{ color: getColorActiveId === item.id ? "white" : "#015e3c" }}></i>
                                                </a>
                                            </OverlayTrigger>
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </div>
                        </div>
                        <UncontrolledCollapse
                            className="children"
                            toggler={`#toggle-menu-item-${item.id}`}
                        >
                            {menuItemChildren}
                        </UncontrolledCollapse>
                    </div>
                );
            }
            return menuItem;
        };
    }

    const Tree1 = ({ data }) => (
        <>
            {/* <ul class="tree">
                {data.map(i => (
                    <>
                        <li>
                            {i.Name}
                            {i.Child && <ul>
                                {<Tree1 data={i.Child} />}
                            </ul>}
                        </li>
                    </>
                ))}
            </ul> */}
            <ul class="tree" style={{ marginLeft: "12px" }}>
                {data &&
                    data.map((item) => (
                        <li>
                            <span class="caret" onClick={() => dirClick(item.Id, item.Name)}>
                                <span
                                    style={{ textTransform: "capitalize" }}
                                    onClick={() => abc1(item.Id, item.Name)}
                                >
                                    {item.Name}&nbsp;&nbsp;
                                </span>
                            </span>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">{t("delete")}</Tooltip>}
                            >
                                <span className="d-inline-block">
                                    <a class="link" href="#">
                                        <i
                                            class="fas fa-trash"
                                            onClick={() => deleteDirectory(item.Id)}
                                            style={{ fontSize: "16px" }}
                                        ></i>
                                    </a>
                                    &nbsp;&nbsp;
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">{t("edit")}</Tooltip>}
                            >
                                <span className="d-inline-block">
                                    <a class="link" href="#">
                                        <i
                                            class="fas fa-edit"
                                            onClick={() => directoryEdit(item.Id, item.Name)}
                                            style={{ fontSize: "16px" }}
                                        ></i>
                                    </a>
                                </span>
                            </OverlayTrigger>
                            {item.Child && (
                                <ul class="nested">{<Tree1 data={item.Child} />}</ul>
                            )}
                        </li>
                    ))}
            </ul>
        </>
    );

    const Tree = ({ data }) => (
        <ul class="tree">
            {data &&
                data.map((item) => (
                    <li style={{ marginTop: "5px" }}>
                        <span class="caret" onClick={() => dirClick(item.Id, item.Name)}>
                            <span
                                style={{ textTransform: "capitalize" }}
                                onClick={() => abc1(item.Id, item.Name)}
                            >
                                {item.Name}&nbsp;&nbsp;
                            </span>
                        </span>
                        {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                        <span className="d-inline-block">
                            <a class="link" href="#"><i class="fas fa-trash" onClick={() => deleteDirectory(item.Id)} style={{ fontSize: "16px" }}></i></a>&nbsp;&nbsp;
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                        <span className="d-inline-block">
                            <a class="link" href="#"><i class="fas fa-edit" onClick={() => directoryEdit(item.Id, item.Name)} style={{ fontSize: "16px" }}></i></a>
                        </span>
                    </OverlayTrigger> */}
                        {item.Child && <ul class="nested">{<Tree data={item.Child} />}</ul>}
                    </li>
                ))}
        </ul>
    );

    /* Edit Dirctory Code Start Here*/
    const [getDirNameUpdateModal, setDirNameUpdateModal] = useState({
        show: false,
        dirName: "",
        dirParentId: "",
    });
    const FolderNameUpdateModalHide = () => {
        setDirNameUpdateModal({ show: false });
    };
    const directoryEdit = (Id, Name) => {
        setErrorMsg();
        setDirNameUpdateModal({ show: true, dirParentId: Id, dirName: Name });
    };
    const UpdateFolderName = (dirId) => {
        const fname = document.getElementById("folder_name");
        if (fname.value === "") {
            return setErrorMsg(t("enter_folder_name"));
        }
        let dirname = fname.value;
        let data = { dirName: dirname, lastModifiedBy: UserId, dirParentId: dirId };
        instructorService.folderNameUpdate(data).then(async (res) => {
            if (res.status == 200) {
                await swal(t("Updates"), t("folder_updated"), "success");
                instructorService.getFolderStructure(UserId).then((res) => {
                    setFolder(res.data);
                });
                setDirNameUpdateModal({ show: false });
            } else {
                alert("some error");
            }
        });
    };

    /* Edit Dirctory Code End Here*/

    function changeBackgroundOver(e) {
        e.target.style.background =
            "linear-gradient(90deg, #009444 0%, #11B67A 100%)";
    }

    function changeBackgroundOut(e) {
        e.target.style.background =
            "linear-gradient(90deg, #11B67A 0%, #009444 100%)";
    }

    async function save(getParentId) {
        await fetchAndSetNonce();
        const folder_name = document.getElementById("folderName");
        if (folder_name.value === "") {
            return setErrorMsg(t("enter_folder_name"));
        }
        let dirname = folder_name.value;
        if (getParentId.length == 0) {
            let register = { dirName: dirname, lastModifiedBy: UserId };
            instructorService
                .createDirectory(register)
                .then(async (response) => {
                    if (response.status == 201) {
                        await swal(t("created"), t("root_folder_created"), "success");
                        instructorService.getFolderStructure(UserId).then((res) => {
                            setFolder(res.data);
                        });
                        folderStructureTree();
                        setModalState(false);
                        setParentId([]);
                    } else {
                        alert("some error");
                    }
                })
                .catch((err) => {
                    swal(t("error"), t("try_sometimes"), "error");
                });
        } else {
            let register = {
                dirName: dirname,
                lastModifiedBy: UserId,
                dirParentId: getParentId,
            };
            instructorService
                .createChildDirectory(register)
                .then(async (response) => {
                    if (response.status == 200) {
                        await swal(t('created'), t('child_folder_created'), "success");
                        instructorService.getFolderStructure(UserId).then((res) => {
                            setFolder(res.data);
                        });
                        folderStructureTree();
                        setModalState(false);
                        setParentId([]);
                    } else {
                        alert("some error");
                    }
                })
                .catch((err) => {
                    swal(t('error_mesg'), t('try_sometimes'), "error");
                });
        }
    }

    /* File Upload Code */

    const [getabc, setabc] = useState({
        selectedFiles: undefined,
        currentFile: undefined,
        file: "",
    });
    const [checkBox, setCheckBox] = useState(false);
    const [show, setShow] = useState();
    const selectFile = (event) => {
        let fi = document.getElementById("file");
        var files = fi.files;
        for (var i = 0; i < files.length; i++) {

            if (
                files[i].type == "application/x-zip-compressed" ||
                files[i].type == "application/zip"
            ) {
                setShow(true);
            } else {
                setShow(false);
            }
            if (
                files[i].type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                files[i].type === "text/plain" ||
                files[i].type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                swal(t('warning'), t('doc_excel_test_not_support'), "warning");
            } else {
                //swal("Warning!", "Please select a valid image", "warning");
            }



        }
        setabc({
            selectedFiles: event.target.files,
        });
    };
    // const [errorMsg, setErrorMsg] = useState();
    const upload = async () => {
        await fetchAndSetNonce();

        const duration = document.getElementById("duration");
        const file_name = document.getElementById("file_name");
        if (file_name.value === "") {
            return setErrorMsg(t('pls_enter_file_name'));
        }
        if (duration.value === "") {
            return setErrorMsg(t('pls_enter_file_duration'));
        }

        let fileName = file_name.value;
        let durationMin = duration.value;
        let fi = document.getElementById("file");
        if (fi.files.length > 0) {
            for (let i = 0; i <= fi.files.length - 1; i++) {
                const fsize = fi.files.item(i).size;
                const file = Math.round(fsize / 102400);
                // The size of the file.
                if (file >= 102400) {
                    swal(t('warning'), t('file_size_exceeded'), "warning");
                }
            }
        }
        let currentFile = getabc.selectedFiles[0];
        let sig = md5(getabc.selectedFiles[0].size.toString());
        setabc({
            currentFile: currentFile,
        });
        setLoading(true);
        instructorService
            .fileUpload(
                currentFile,
                sig,
                UserId,
                getParentId,
                durationMin,
                fileName,
                checkBox,
                (event) => { }
            )
            .then(async (res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data === "double-extension-file") {
                        swal(t("error"), t("double-ext-file"), "error");
                    } else if (res.data === "file-sig-error") {
                        swal(t("error"), "Invalid File Error", "error");
                    } else if (res.data === "Uploaded Successfully") {
                        await swal(t("uploaded"), res.data, "success");
                    } else {
                        await swal(t("msg"), res.data, "info");
                    }
                    setUploadModalState({ show: false });
                    instructorService.contentDetails(getParentId, UserId).then((res) => {

                        setContentDetails(res.data);
                    });
                    folderStructureTree();
                }
            })
            .catch((err) => {
                setabc({
                    currentFile: undefined,
                });
            });

        setabc({
            selectedFiles: undefined,
        });
    };

    /* File Content Update Code Start */
    const [getFileContentUpdateModalState, setFileContentUpdateModalState] =
        useState({
            show: false,
        });
    const FileContentUpdateModalHide = () => {
        setFileContentUpdateModalState({ show: false });
    };
    const [getFileContentDetails, setFileContentDetails] = useState({
        contentId: "",
        contentName: "",
        contentDuration: "",
    });
    const contentEdit = (contentId, contentName, contentDuration) => {
        setErrorMsg();
        setFileContentDetails({
            contentId: contentId,
            contentName: contentName,
            contentDuration: contentDuration,
        });
        setFileContentUpdateModalState({ show: true });
    };
    const UpdateFileDatails = (contentId, folder_id) => {
        const duration = document.getElementById("duration");
        const file_name = document.getElementById("file_name");
        if (duration.value === "" || file_name === "") {
            return setErrorMsg(t("content_detail"));
        }
        let fileName = file_name.value;
        let durationMin = duration.value;
        let data = {
            contentId: contentId,
            contentName: fileName,
            contentDuration: durationMin,
        };
        instructorService.fileCotentDetailsUpdate(data).then(async (res) => {
            if (res.status == 200) {
                await swal(t("Updates"), t("details_updated"), "success");
                setFileContentUpdateModalState({ show: false });
                instructorService.contentDetails(folder_id, UserId).then((res) => {

                    setContentDetails(res.data);
                });
            }
        });
    };
    /* File Content Update Code End */

    /* share url code here start */
    const [getShareUrlData, setShareUrlData] = useState();
    const [ShareUrlModal, setShareUrlModal] = useState({
        show: false,
    });
    const shareUrlModalHide = () => {
        setShareUrlModal({ show: false });
    };
    const ShareUrl = (url) => {
        instructorService.contentAccess(url).then((res) => {

            setShareUrlData("https://eprabodhini.lms.muhs.ac.in/" + res.data);
            setShareUrlModal({ show: true });
            let copyText = document.querySelector(".copy-text1");
            copyText.querySelector("button").addEventListener("click", function () {
                let input = copyText.querySelector("input.text");
                input.select();
                document.execCommand("copy");
                copyText.classList.add("active");
                window.getSelection().removeAllRanges();
                setTimeout(function () {
                    copyText.classList.remove("active");
                }, 2500);
            });
        });
    };
    /* share url code here start */

    const [getUrl, setUrl] = useState();
    const [getContentType, setContentType] = useState();
    const contentView = (contentType, url, contentName, row) => {

        // alert("contentType")
        instructorService
          .contentAccess(`${URI.SERVER?._DMS_URL}${url}`)
          .then((res) => {
            if (res.data.endsWith(".mp4")) {
              setVideoEncodeStatus("pending");
            } else {
              setVideoEncodeStatus("success");
            }
            if (contentType === "pdf") {
              // This encodes the Actual URL
              const encrypted = CryptoJS.AES.encrypt(
                "/" + URI.SERVER._MEGH_API + "/" + res.data,
                "cdac@123"
              ).toString();
              const safeEncodedString = encrypted
                .replace(/\+/g, "-")
                .replace(/\//g, "_")
                .replace(/=+$/, "");
    
              const pdfDomain = URI.SERVER?._DMS;
              const finalSubDomain = pdfDomain + "/" + safeEncodedString;
    

    
              setUrl(finalSubDomain);
            } else {
              setUrl(res.data);
              if (res.data.includes('/displayview?')) { // streaming url
                setUrl(res.data);
              }
            }
            setContentType(contentType);
            setContentName(contentName);
            setUrlModal({ show: true });
          })
          .catch((err) => {
            swal(`${t("error")}`, `${err} ${t("try_sometimes")}`, "error");
          });
      };

    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 1100,
        height: 800,
        controls: true,
        sources: [
            {
                src: `https://eprabodhini.lms.muhs.ac.in/${getUrl}`,
                type: "video/mp4",
            },
        ],
    };

    const contentDelete = (contentId, folder_id) => {
        swal({
            title: t("swal_title"),
            text: t("u_want_to_delete_content"),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: t('yes_delete'),
            closeOnConfirm: false,
        }).then((isConfirmed) => {
            if (isConfirmed) {
                instructorService.contentDelete(contentId).then(async (res) => {
                    if (res.status == 200) {
                        await swal(t("deleted"), t("your_content_deleted"), "success");
                        instructorService.contentDetails(folder_id, UserId).then((res) => {

                            setContentDetails(res.data);
                        });
                    }
                });
            }
        });
    };

    // useEffect(() => { console.log("getLibSelectedAccessType", getLibSelectedAccessType) }, [getLibSelectedAccessType])

    const hadleLibContentTypeChange = (row, e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setLibSelectedAccessType((prevState) => ({
                ...prevState,
                [row.contentId]: true
            }));
        } else {
            setLibSelectedAccessType((prevState) => {
                const newState = { ...prevState };
                delete newState[row.contentId.toString()];
                return newState;
            });
        }
    };

    const columns = [
        {
            name: "Name",
            selector: "contentName",
            sortable: true,
            wrap: true,
            width: "145px",
        },
        {
            name: "Type",
            selector: "contentType",
            sortable: true,
            width: "145px",
        },
        {
            name: "Duration",
            selector: "contentDuration",
            sortable: true,
            width: "145px",
        },
        {
            name: "Preview",
            sortable: true,
            width: "145px",
            cell: (row) => (
                <a
                    class="link"
                    href="#"
                    onClick={() => contentView(row.contentType, row.shareUrl, row.contentName, row)}
                >
                    {row.contentType === "zip" ? (
                        <i
                            class="fas fa-file-archive"
                            style={{ fontSize: "25px", color: "#fdbf00" }}
                        ></i>
                    ) : row.contentType === "pdf" ? (
                        <i
                            class="fas fa-file-pdf"
                            style={{ fontSize: "25px", color: "#b30b00" }}
                        ></i>
                    ) : row.contentType === "jpg" ||
                        row.contentType === "png" ||
                        row.contentType === "jpeg" ? (
                        <i
                            class="fas fa-file-image"
                            style={{ fontSize: "25px", color: "#b2b1ff" }}
                        ></i>
                    ) : row.contentType === "html" ? (
                        <i
                            class="fab fa-html5"
                            style={{ fontSize: "25px", color: "#e54c21" }}
                        ></i>
                    ) : row.contentType === "ogg" ||
                        row.contentType === "webm" ||
                        row.contentType === "mp4" ? (
                        <i
                            class="fas fa-file-video"
                            style={{ fontSize: "25px", color: "#8cee02" }}
                        ></i>
                    ) : row.contentType === "txt" ? (
                        <i
                            class="fas fa-file-alt"
                            style={{ fontSize: "25px", color: "#2766a0" }}
                        ></i>
                    ) : row.contentType === "doc" || row.contentType === "docx" ? (
                        <i
                            class="fas fa-file-word"
                            style={{ fontSize: "25px", color: "#1e62b4" }}
                        ></i>
                    ) : row.contentType === "scorm" ? (
                        <i
                            class="far fa-file-archive"
                            style={{ fontSize: "25px", color: "green" }}
                        ></i>
                    ) : row.contentType === "youtube" ? (
                        <i
                            class="far fa-youtube"
                            style={{ fontSize: "25px", color: "green" }}
                        ></i>
                    ) : null}
                </a>
            ),
        },
        {
            name: "Share Url",
            width: "170px",
            sortable: true,
            cell: (row) => (
                <>
                    {row.contentType === "zip" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "pdf" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <CopyToClipboard text={getShareUrlData}>
                                    <a
                                        class="link"
                                        onClick={() => ShareUrl(row.previewUrl)}
                                        href="#"
                                    >
                                        <i class="fas fa-link"></i>
                                    </a>
                                </CopyToClipboard>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "jpg" ||
                        row.contentType === "png" ||
                        row.contentType === "jpeg" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "html" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "ogg" ||
                        row.contentType === "webm" ||
                        row.contentType === "mp4" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "txt" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "doc" || row.contentType === "docx" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : row.contentType === "scorm" ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">{t("click_to_copy")}</Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <a
                                    class="link"
                                    onClick={() => ShareUrl(row.previewUrl)}
                                    href="#"
                                >
                                    <i class="fas fa-link"></i>
                                </a>
                            </span>
                        </OverlayTrigger>
                    ) : null}
                </>
            ),
        },
        {
            name: "Restrict Content",
            width: "170px",
            sortable: true,
            cell: (row) => (
                <div>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="tooltip-disabled">Select As Restrict Content</Tooltip>
                        }
                    >
                        <input type="checkbox" onClick={(e) => { hadleLibContentTypeChange(row, e) }} checked={getLibSelectedAccessType[row.contentId.toString()]} />
                    </OverlayTrigger>
                </div>
            )
        }
    ];

    /* Table content Filter and Search */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] =
        React.useState(false);
    const filteredItems = getContentDetails.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );
    const [isContentChange, setIsContentChange] = useState(false);
    const [updatedCourseStructureJson, setUpdatedCourseStructureJson] =
        useState();
    const [structure, setStructure] = useState();
    const [dragModalOpen, setDragModalOpen] = useState(false);
    const [getContentOwner, setContentOwner] = useState(false);


    const handleDragModalClose = () => {
        setDragModalOpen(false);
    };
    const onCourseStructureUpdate = () => {


        instructorService
            .updatedCourseStructure(UserId, courseId, updatedCourseStructureJson)
            .then((res) => {
                swal(`${t("success")}`, res.data, "success");
                handleDragModalClose();
                fatchCourseData();
            })
            .catch((err) => {

                swal(`${t("error")}`, err.response.data, "error");
                handleDragModalClose();
            });
    };
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    /* Course Structure Create Code End */

    const courseStructurContentView = (contentType, fileUrl, label) => {

        setContentType(contentType);
        setContentName(label);


        // alert("asdfasdfa")
        if (contentType == "youtube") {
            setUrl(fileUrl)
            setYouTubeUrl(fileUrl);

            setContentName(label);

            setUrlModal({ show: true });
        } else if (contentType == "externalLink") {

            // window.location.href = fileUrl;
            window.open(fileUrl, '_blank');

            setContentName(label);

        }
        else {

            instructorService
                .contentAccess(URI.SERVER?._DMS_URL + fileUrl)
                .then((res) => {
                    if (res.data.endsWith(".mp4")) {
                        setVideoEncodeStatus("pending");
                    } else {
                        setVideoEncodeStatus("success");
                    }
                    if (contentType === "pdf") {
                        // This encodes the Actual URL
                        const encrypted = CryptoJS.AES.encrypt(
                            "/" + URI.SERVER._MEGH_API + "/" + res.data,
                            "cdac@123"
                        ).toString();
                        const safeEncodedString = encrypted
                            .replace(/\+/g, "-")
                            .replace(/\//g, "_")
                            .replace(/=+$/, "");

                        const pdfDomain = URI.SERVER?._DMS;
                        const finalSubDomain = pdfDomain + "/" + safeEncodedString;



                        setUrl(finalSubDomain);
                    } else {

                        // setUrl(`${URI?.SERVER?._DMS_URL}/${res.data}`);


                        setUrl(`${res.data}`);
                        if (res.data.includes('/displayview?')) { // streaming url
                            setUrl(res.data);
                        }
                    }


                    setUrlModal({ show: true });
                    //window.open({URI.SERVER?._DMS_URL} + res.data, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=1000,height=1000");
                })
                .catch((err) => {
                    swal("Error!", `${err} Try after sometime.`, "error");
                });
        }
    };


    const dmsUIVisible = (id) => {

        setToggleAddContent(prevState => !prevState)
        setCourseStructureFolderId(id);
        setToggle(true);
    };
    const handleDragEnd = (result) => {
        if (!result.destination) {
            // Item dropped outside the list
            return;
        }

        const { source, destination } = result;

        // Clone the structure to avoid mutating the state directly
        const updatedStructure = { ...structure };

        // Assuming a flat structure for simplicity
        const [movedItem] = updatedStructure.nodes.splice(source.index, 1);
        updatedStructure.nodes.splice(destination.index, 0, movedItem);

        setStructure(updatedStructure);
        // setUpdatedCourseStructureJson(updatedStructure)

        // If it's a content change, call the separate function
        if (isContentChange) {
            handleContentChange(updatedStructure);
        } else {
            setUpdatedCourseStructureJson(updatedStructure);
        }
    };
    const findFolderById = (structure, folderId) => {
        if (structure.id === folderId) {
            return structure;
        } else {
            for (const node of structure.nodes) {
                if (node.id === folderId) {
                    return node;
                } else if (node.nodes && node.nodes.length > 0) {
                    const foundInNestedFolder = findFolderById(node, folderId);
                    if (foundInNestedFolder) {
                        return foundInNestedFolder;
                    }
                }
            }
            return null;
        }
    };
    const handleContentChange = (modifiedStructure) => {
        // Find the folder in the updatedCourseStructureJson and replace it
        const folderId = modifiedStructure.id;
        const updatedStructureCopy = { ...updatedCourseStructureJson };
        const foundFolder = findFolderById(updatedStructureCopy, folderId);

        if (foundFolder) {
            foundFolder.nodes = modifiedStructure.nodes;
            setUpdatedCourseStructureJson(updatedStructureCopy);
        }
    };

    const handleDragModalOpen = (structure, bool) => {

        instructorService.isCourseOwner(UserId, courseId).then((res) => {

            if (res.data) {
                //course owner have all access
                setIsContentChange(bool)
                setStructure(structure);
                setDragModalOpen(true);

            }
            else {
                //alternative instructors  has access to their module only
                instructorService.isContentOwner(UserId, structure.id)
                    .then((res) => {


                        if (res.data) {
                            setContentOwner(res.data);
                            setIsContentChange(bool)
                            setStructure(structure);
                            setDragModalOpen(true);
                        }
                        else {
                            swal(`${t('warning')}`, "You're not allowed to Rearrange the Content!", "warning");

                        }
                    }).catch((err) => {
                        swal(`${t('error')}`, err.response.data, "error");

                    })

            }
        }).catch((err) => {
            swal(`${t('error')}`, err.response.data, "error");
        })



        //verify the owner
        // setIsContentChange(bool)
        // setStructure(structure);
        // setDragModalOpen(true);
    }

    const [toggle, setToggle] = useState(false);
    let [getCourseStructureFolderId, setCourseStructureFolderId] = useState();

    /* dataTable Check box Code  */

    const toDateTime = (dateFormat) => {
        var date = new Date(dateFormat);
        var str = "";
        var year, month, day, hour, min, sec;
        year = date.getFullYear();
        month = date.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        day = date.getDate();
        day = day < 10 ? "0" + day : day;
        hour = date.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        min = date.getMinutes();
        min = min < 10 ? "0" + min : min;
        sec = date.getSeconds();
        sec = sec < 10 ? "0" + sec : sec;

        str += year + "-" + month + "-" + day;
        str += " " + hour + ":" + min + ":" + sec;
        return str;
    };

    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleRowSelected = React.useCallback((state) => {
        setSelectedRows(state.selectedRows);
    }, []);

    let newArr = selectedRows.map(
        ({
            contentId,
            lastModifiedDate,
            lastUpdatedBy,
            publishStatus,
            shareUrl,
            streamingStatus,
            uploadDate,
            userId,
            ...rest
        }) => {

            // let originalUrl = rest.previewUrl;
            // let newPath = originalUrl.replace("https://eprabodhini.lms.muhs.ac.in/", "");
            rest["filepath"] = shareUrl;
            rest["publishDate"] = toDateTime(startDate);
            rest["cname"] = rest["contentName"];
            rest["topicDuration"] = rest["contentDuration"];
            rest["pcontentId"] = contentId;
            delete rest["contentName"];
            delete rest["contentDuration"];
            delete rest["previewUrl"];

            // alert("selectedRow")
            rest['accessType'] = 'open'
            if (Object.keys(getLibSelectedAccessType).includes(contentId.toString())) {
                //add the new vaiable and assingn the contenttype
                rest['accessType'] = 'restrict'
            }
            return rest;
        }
    );
    useEffect(() => { console.log("newArry", newArr, getLibSelectedAccessType) }, [newArr])

    newArr.map(
        (i) => (
            (i.categoryType = getCourseDetails.categoryName),
            (i.courseId = courseId),
            (i.userId = UserId),
            (i.itemId = getCourseStructureFolderId),
            (i.description = "Basic desc")
        )
    );

    /* dataTable Check box Code End here  */

    const AddContentToCourseStructure = (arrayData, id) => {
        if (id === undefined) {
            swal(t("select"), t("select_node_in_course_structure"), "warning");
        } else {
            setLoading(true);
            instructorService
                .addContentToCourseStructure(arrayData)
                .then(async (res) => {
                    if (res.data === "Content added successfully!!") {
                        await swal(t("success"), t("content_added"), "success");
                        setLoading(false);
                        setToggle(false);
                        setLibSelectedAccessType({})
                        instructorService.getLibraryById(courseId).then((res) => {
                            setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                        });
                        folderStructureTreeCourse();
                        toggleCourseDriveModal()
                    }
                })
                .catch((err) => {

                });
        }
    };

    // feedback content link

    /* Add Module or topic code start here */
    let a = new Date().toISOString().split(".")[0];

    const [getModuleModalState, setModuleModalState] = useState({
        show: false,
    });

    const AddModuleModalShow = (id) => {
        setCourseStructureFolderId(id);
        setModuleModalState({ show: true });
    };
    const AddModuleModalHide = () => {
        setModuleModalState({ show: false });
    };

    const AddModule = (id) => {
        setErrorMsg();
        const module_name = document.getElementById("module_name");
        if (startDate === null) {
            return setErrorMsg(t("pls_publish_date"));
        }
        if (module_name.value === "") {
            return setErrorMsg(t("enter_folder_name"));
        } else {
            let data = {
                dirParentId: id,
                dirName: module_name.value,
                publishDate: toDateTime(startDate),
                lastModifiedBy: UserId,
            };
            instructorService
                .addModuleOrTopic(data)
                .then(async (res) => {
                    if (res.status == 200) {
                        await swal(t("success"), t("fold_add"), "success");
                        setModuleModalState({ show: false });
                        setCourseStructureJson(res.data);
                        folderStructureTreeCourse();
                    }
                })
                .catch((err) => {

                });
        }
    };
    /* Add Module or topic code end here */
    const contentReviewSelected = (content) => {
        setContentRatingModalShow(true);
        setselectedContent(content);
    }

    /* Update Module or topic or content details code start here */
    const [getFolderOrContentDetUpdate, setFolderOrContentDetUpdate] = useState({
        show: false,
    });
    const [getPdate, setPdate] = useState();
    const updateFolderOrContent = (id, name, pDate, item) => {

        // alert("update fun")
        setErrorMsg("");
        setPdate(pDate);
        setFolderOrContentDetUpdate({
            show: true,
            id: id,
            name: name,
            accessType: item?.accessType
        });
    };
    const UpdateContentOrFolderModalHide = () => {
        setFolderOrContentDetUpdate({ show: false });
    };

    const updateContentOrModule = (id) => {
        const module_name = document.getElementById("module_name");
        const module_accessType = document.getElementById("module_accessType")
        const publishDate = getPdate;
        if (module_name.value === "") {
            return setErrorMsg(t('pls_publish_folder'));
        }
        if (publishDate === "") {
            return setErrorMsg(t('pls_publish_date'));
        } else {
            if (Number.isInteger(parseInt(id))) {
                let data = {
                    contentId: id,
                    courseId: courseId,
                    cname: module_name.value,
                    description: "For frontend development",
                    publishDate: toDateTime(publishDate),
                    userId: UserId,
                    topic_duration: 1,
                    accessType: module_accessType.value

                };
                instructorService.updateContentDetails(data).then(async (res) => {
                    if (res.status == 200) {
                        await swal(
                            t('success_swal'),
                            t('content_updated'),
                            "success"
                        );
                        setFolderOrContentDetUpdate({ show: false });
                        setCourseStructureJson(res.data);
                        folderStructureTreeCourse();
                    }
                });
            } else {
                let data = {
                    dirParentId: id,
                    dirName: module_name.value,
                    lastModifiedBy: UserId,
                    publishDate: toDateTime(publishDate),
                };
                instructorService.updateFolderDetails(data).then(async (res) => {
                    if (res.status == 200) {
                        await swal(t('success_swal'), t('folder_updated'), "success");
                        setFolderOrContentDetUpdate({ show: false });
                        setCourseStructureJson(res.data);
                        folderStructureTreeCourse();
                    }
                });
            }
        }
    };

    const handleAccessTypeChange = (event) => {
        setLibAccessType(event.target.value)

    }

    const removeLibRequests = (courseId, contentId) => {
        instructorService.removeLibRequests(courseId, contentId).then((res) => {

        }).catch((err) => { })
    }
    /* Update Module or topic or content details code end here */

    /* delete course content or remove structure */

    const RemoveContentOrStructure = (contentId) => {
        if (Number.isInteger(parseInt(contentId))) {
            swal({
                title: t("swal_title"),
                text: t("u_want_to_delete_content"),
                icon: "warning",
                buttons: [t('no_cancel'), t('yes_delete')],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    instructorService
                        .deleteCourseContent({
                            contentId: contentId,
                            courseId: courseId,
                            userId: UserId,
                        })
                        .then(async (res) => {
                            if (res.data === "Content deleted successfully !!") {
                                await swal(t("deleted"), t("content_deleted"), "success");
                                instructorService.getLibraryById(courseId).then((res) => {
                                    setCourseStructureJson(
                                        JSON.parse(res.data.courseStructureJson)
                                    );
                                });
                                removeLibRequests(courseId, contentId) //remove lib requests
                                folderStructureTreeCourse();


                            }
                        })
                        .catch((err) => {

                        });
                }
            });
        } else {
            swal({
                title: t("swal_title"),
                text: t("u_want_to_delete_folder"),
                icon: "warning",
                buttons: [t('no_cancel'), t('yes_delete')],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    instructorService
                        .deleteCourseStructureChild({
                            dirParentId: contentId,
                            lastModifiedBy: UserId,
                        })
                        .then(async (res) => {
                            if (res.data === "deleted successfully") {
                                await swal(
                                    t('deleted'),
                                    t('course_structure_folder_deleted'),
                                    "success"
                                );
                                instructorService.getLibraryById(courseId).then((res) => {
                                    setCourseStructureJson(
                                        JSON.parse(res.data.courseStructureJson)
                                    );
                                });
                                folderStructureTreeCourse();
                            }
                        })
                        .catch((err) => {

                        });
                }
            });
        }
    };

    /* Course Publish Function */
    const publishCourseButton = (cId) => {
        instructorService
            .LibraryPublish(cId)
            .then(async (res) => {
                // if (res.data === "Course Published Successfully!!") {
                if (res.status === 200) {
                    await swal(t('success'), t('request_send'), "success").then(
                        () => {
                            window.location.reload();
                        }
                    );
                    instructorService.getLibraryById(courseId).then((res) => {
                        setCourseDetails(res.data);
                        setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                    });
                } else {

                }
            })
            .catch((err) => {

            });
    };

    const requestforpublishLibraryButton = (cId) => {
        setLoading(true);
        instructorService
            .libraryPublishAdminRequest(cId)
            .then(async (res) => {
                // if (res.data === "Course Published Successfully!!") {
                if (res.status === 200) {
                    await swal(t("success"), t("request_send"), "success");
                    setLoading(false);
                    fatchCourseData();
                    instructorService.getLibraryById(courseId).then((res) => {
                        setCourseDetails(res.data);
                        setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                    });
                } else {
                    setLoading(false);

                }
            })
            .catch((err) => {

            });
    };

    const requestforpublishContentButton = (cId) => {
        setLoading(true);
        instructorService
            .ContentPublishAdminRequest(cId)
            .then(async (res) => {
                // if (res.data === "Course Published Successfully!!") {
                if (res.status === 200) {
                    await swal(t("success"), t("request_send"), "success");
                    setLoading(false);
                    fatchCourseData();
                    instructorService.getLibraryById(courseId).then((res) => {
                        setCourseDetails(res.data);
                        setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                    });
                } else {
                    setLoading(false);

                }
            })
            .catch((err) => {

            });
    };

    const requestforDisableLibraryButton = (cid) => {
        instructorService
            .LibraryDisableStatus(cid)
            .then(async (res) => {
                await swal(t("success"), t('library_disable_msg'), "success");
            })
            .catch((err) => {

            });
    };

    /* course unpublish funcation */
    const unPublishCourseButton = (cId) => {
        instructorService
            .libraryUnPublish(cId)
            .then(async (res) => {
                if (res.data === "Course UnPublished Successfully!!") {
                    await swal(
                        t("success"),
                        t('course_unpublished'),
                        "success"
                    ).then(() => {
                        window.location.reload();
                    });
                    instructorService.getLibraryById(courseId).then((res) => {
                        setCourseDetails(res.data);
                        setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                    });
                } else {

                }
            })
            .catch((err) => {

            });
    };

    /* course Disable funcation */
    const disableCourseButton = (cId) => {
        instructorService
            .LibraryDisable(cId)
            .then(async (res) => {
                if (res.data === "Course Disabled Successfully!!") {
                    await swal(
                        t("success"),
                        t('course_disabled'),
                        "success"
                    ).then(() => {
                        window.location.reload();
                    });
                    instructorService.getLibraryById(courseId).then((res) => {
                        setCourseDetails(res.data);
                        setCourseStructureJson(JSON.parse(res.data.courseStructureJson));
                    });
                } else {

                }
            })
            .catch((err) => {

            });
    };

    const [headerState, setHeaderState] = useState({
        id: UserService.getUserid(),
        img: um_api + "public/getprofilepic/",
    });

    /* this code is for  announcement creation and view */

    /* this code end announcement creation and view */

    /* this code is for  feedback handle submit */

    /* this code is for  feedback handle submit */

    /* this code is for  feedback question handle submit */
    if (getPageLoading) {
        return <div
            style={{
                position: 'fixed', // Ensures the div stays on top of other content
                top: 0,
                left: 0,
                width: '100vw', // Covers the entire viewport width
                height: '100vh', // Covers the entire viewport height
                backgroundColor: 'rgba(255, 255, 255, 0.562)', // Semi-transparent background
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Centers the loading indicator vertically
                alignItems: 'center', // Centers the loading indicator horizontally
                zIndex: 999, // Ensures the div is on top of other elements
            }}
        >
            <Circles
                height={40}
                width={40}
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <span style={{ color: '', marginTop: '20px' }}>Loading, please wait...</span>
        </div>;
    }

    return (
        <div
            className="main-wrapper course-details-page"
            onLoad={() => scrollWin()}
        >
            {/* Header 2 */}
            <HeaderTwo />
            {/* Breadcroumb */}
            <BreadcrumbBox
                title={getCourseDetails.courseName}
                bannerUrl={getCourseDetails.banner !== null ? `${URI.SERVER._COURSE_URL}/${getCourseDetails.banner}` : '/assets/images/breadcrumb-bg.jpg'}
            // bannerUrl={`/assets/images/breadcrumb-bg.jpg`}

            />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area" id="Main">
                    <Container>
                        <div className="course-details-top">
                            {/* <Col lg="8" md="7" sm="12"> */}

                            <div className="child1">
                                <div className="heading">
                                    <h4 style={{ textTransform: "capitalize" }}>
                                        {getCourseDetails.courseName}
                                    </h4>
                                </div>
                                <div className="course-top-overview">
                                    <div className=" overviews">
                                        <Row>
                                            {/* <Col sm='12' xs='12' lg='4' xl='4'>
                                                <div className='d-flex'>
                                                    <div className="author">
                                                        <Row>

                                                            <img src={`${headerState.img}${headerState.id}`} alt="" />
                                                            <div className="author-name">
                                                                <h6>{t('author')}</h6>
                                                                <p>{UserService.getUsername()}</p>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className="category">
                                                        <h6>{t('cat')}</h6>
                                                        <p>{getCourseDetails.categoryName}</p>
                                                    </div>
                                                </div>
                                            </Col> */}
                                            <Col sm="12" xs="12" lg="8" xl="8">
                                                <div className="course-details-banner">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            `${URI.SERVER._COURSE_URL}/${getCourseDetails.imageUrl}`
                                                        }
                                                        alt="Default"
                                                        style={{ height: "300px", width: "800px" }}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </Col>
                                            {/* <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                            {/* <div className="price">
                                            <h6>Price</h6>
                                            <p>{fee_validator(getCourseDetails.courseFee)}</p>
                                        </div> */}

                                            <Col sm="12" xs="12" lg="4" xl="4">
                                                <div className="single-details-sidbar">
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="course-details-feature">
                                                                <h5 className="title">
                                                                    {t("instructor_action")}
                                                                </h5>
                                                                {/* <div className="event-sidebar-timer text-center">
                                                    <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                        <p><Timer.Days /><span>Days</span></p>
                                                        <p><Timer.Hours /><span>Hours</span></p>
                                                        <p><Timer.Minutes /><span>Minutes</span></p>
                                                    </Timer>
                                                </div> */}
                                                                {/* <ul className="list-unstyled feature-list">
                                                    <li><i className="far fa-calendar-check"></i> {t('enrollment_start_date')}: <span>{convertDate(getCourseDetails.enrollStartDate)}</span></li>
                                                    <li><i className="far fa-calendar-check"></i> {t('enrollment_end_date')}: <span>{convertDate(getCourseDetails.enrollEndDate)}</span></li>
                                                    <li><i className="far fa-calendar-check"></i> {t('course_Publish_Date')}: <span>{convertDate(getCourseDetails.publishDate)}</span></li>
                                                    <li><i className="far fa-calendar-check"></i> Commence Date: <span>{convertDate(getCourseDetails.commenceDate)}</span></li>
                                                    <li><i className="far fa-calendar-check"></i> Closing Date: <span>{convertDate(getCourseDetails.closingDate)}</span></li>
                                                    <li><i className="far fa-clock"></i> {t('duration')}: <span>{getCourseDetails.duration == 1825 ? "Unlimited" : getCourseDetails.duration} Days</span></li>
                                                    <li><i className="fas fa-globe-asia"></i> {t('language1')}: <span>{t('english')}</span></li>
                                                    <li><i className="far fa-bookmark"></i> {t('enrolled')}: <span>{userCount}</span></li>
                                                    <li><i className="fas fa-certificate"></i> {t('certification')}: <span>{t('yes')}</span></li>
                                                </ul> */}
                                                                <ul className="feature-list">
                                                                    {getCourseDetails.status === "C" ? (
                                                                        <>
                                                                            {/* <li><button type="button" onClick={() => disableCourseButton(courseId)} className="enroll-btn">Disable Library</button></li> */}
                                                                            {getLoading !== true ? (
                                                                                <>
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforpublishLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t("request_publish_library")}
                                                                                        </button>
                                                                                    </li>
                                                                                    <div className="alert alert-danger mt-2">
                                                                                        {t("library_not_published")}
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            <div
                                                                                                class="spinner-border"
                                                                                                role="status"
                                                                                            >
                                                                                                <span class="sr-only">
                                                                                                    {t("loading")}
                                                                                                </span>
                                                                                            </div>
                                                                                        </button>
                                                                                    </li>
                                                                                </>
                                                                            )}

                                                                            {getLibraryOwnUserId === UserId &&
                                                                                getNoOfContents === 0 && (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforDisableLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t('disable_library')}
                                                                                        </button>
                                                                                    </li>
                                                                                )}
                                                                        </>
                                                                    ) : getCourseDetails.status === "P" ? (
                                                                        <>
                                                                            {/* {getLibraryOwnUserId === UserId &&
                                                                                (userCount == 0 ? (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                unPublishCourseButton(courseId)
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            UnPublish Library
                                                                                        </button>
                                                                                    </li>
                                                                                ) : null)} */}
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        requestforpublishContentButton(
                                                                                            courseId
                                                                                        )
                                                                                    }
                                                                                    className="enroll-btn"
                                                                                >
                                                                                    {t('req_to_publish')}
                                                                                </button>
                                                                            </li>

                                                                            {getLibraryOwnUserId === UserId &&
                                                                                getNoOfContents === 0 && (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforDisableLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t('disable_library')}
                                                                                        </button>
                                                                                    </li>
                                                                                )}

                                                                            <h6 style={{ textAlign: "center" }}>
                                                                                {t("library_published_on")}{" "}
                                                                                {publishedDate(
                                                                                    getCourseDetails.publishDate
                                                                                )}
                                                                            </h6>
                                                                            {/* <li><button type="button" onClick={() => disableCourseButton(courseId)} className="enroll-btn">Disable Library</button></li>
                                                                                    <li><button type="button" onClick={() => publishCourseButton(courseId)} className="enroll-btn">Request to Publish Library</button></li> */}
                                                                        </>
                                                                    ) : getCourseDetails.status === "U" ? (
                                                                        <>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        requestforpublishLibraryButton(
                                                                                            courseId
                                                                                        )
                                                                                    }
                                                                                    className="enroll-btn"
                                                                                >
                                                                                    {t('request_publish_library')}
                                                                                </button>
                                                                            </li>
                                                                            {/* <li><button type="button" onClick={() => disableCourseButton(courseId)} className="enroll-btn">Disable Library</button></li>
                                                                        <li><button type="button" onClick={() => publishCourseButton(courseId)} className="enroll-btn">Request to Publish Library</button></li> */}
                                                                            {getLibraryOwnUserId === UserId &&
                                                                                getNoOfContents === 0 && (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforDisableLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t('disable_library')}
                                                                                        </button>
                                                                                    </li>
                                                                                )}
                                                                        </>
                                                                    ) : getCourseDetails.status === "D" ? (
                                                                        <>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        requestforpublishLibraryButton(
                                                                                            courseId
                                                                                        )
                                                                                    }
                                                                                    className="enroll-btn"
                                                                                >
                                                                                    {t('request_publish_library')}
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        requestforpublishLibraryButton(
                                                                                            courseId
                                                                                        )
                                                                                    }
                                                                                    className="enroll-btn"
                                                                                >
                                                                                    {t('enable_library')}
                                                                                </button>
                                                                            </li>
                                                                        </>
                                                                    ) : getCourseDetails.status === "R" ? (
                                                                        <>
                                                                            {/* {userCount == 0 ? <li><button type="button" onClick={() => unPublishCourseButton(courseId)} className="enroll-btn">UnPublish Library</button></li> : null} */}
                                                                            {/* <li><button type="button" onClick={() => disableCourseButton(courseId)} className="enroll-btn">Disable Library</button></li> */}
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    disabled
                                                                                    className="enroll-btn"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                    }}
                                                                                >
                                                                                    {t("request_yet_to_approve_by_admin")}
                                                                                </button>
                                                                            </li>
                                                                            {getLibraryOwnUserId === UserId &&
                                                                                getNoOfContents === 0 && (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforDisableLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t('disable_library')}
                                                                                        </button>
                                                                                    </li>
                                                                                )}
                                                                        </>
                                                                    ) : getCourseDetails.status === "N" ? (
                                                                        <>
                                                                            {/* {getLibraryOwnUserId === UserId &&
                                                                                (userCount == 0 ? (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                unPublishCourseButton(courseId)
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            UnPublish Library
                                                                                        </button>
                                                                                    </li>
                                                                                ) : null)} */}
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        requestforpublishContentButton(
                                                                                            courseId
                                                                                        )
                                                                                    }
                                                                                    className="enroll-btn"
                                                                                >
                                                                                    {t('req_to_publish')}
                                                                                </button>
                                                                            </li>

                                                                            {getLibraryOwnUserId === UserId &&
                                                                                getNoOfContents === 0 && (
                                                                                    <li>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                requestforDisableLibraryButton(
                                                                                                    courseId
                                                                                                )
                                                                                            }
                                                                                            className="enroll-btn"
                                                                                        >
                                                                                            {t('disable_library')}
                                                                                        </button>
                                                                                    </li>
                                                                                )}

                                                                            <h6 style={{ textAlign: "center" }}>
                                                                                {t("library_published_on")}{" "}
                                                                                {publishedDate(
                                                                                    getCourseDetails.publishDate
                                                                                )}
                                                                            </h6>
                                                                            {/* <li><button type="button" onClick={() => disableCourseButton(courseId)} className="enroll-btn">Disable Library</button></li>
                                                                                    <li><button type="button" onClick={() => publishCourseButton(courseId)} className="enroll-btn">Request to Publish Library</button></li> */}
                                                                        </>
                                                                    ) : null}
                                                                </ul>
                                                                {/* <ul className="list-unstyled feature-list">
                                                    {getCourseStructureJson.lastPublishedDate == undefined ? null :
                                                        <li>Last Published Date: <span>{getCourseStructureJson.lastPublishedDate}</span></li>
                                                    }
                                                </ul> */}
                                                            </div>
                                                        </Col>
                                                        {/* <Col md="12">
                                            <PopularCourse />
                                        </Col>
                                        <Col md="12">
                                            <CourseTag />
                                        </Col> */}
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <div className="author">
                                            <img src={`${headerState.img}${headerState.id}`} alt="" />
                                            <div className="author-name">
                                                <h6>Author</h6>
                                                <p>{UserService.getUsername()}</p>
                                            </div>
                                        </div>
                                        <div className="category">
                                            <h6>Category</h6>
                                            <p>{getCourseDetails.categoryName}</p>
                                        </div> */}
                                        {/* <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                        {/* <div className="price">
                                            <h6>Price</h6>
                                            <p>{fee_validator(getCourseDetails.courseFee)}</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <Col lg="4" md="5" sm="12"></Col> */}
                            <div className="child2"></div>
                        </div>
                    </Container>
                </section>
                <section className="course-details-area" id="Tab">
                    <Container>
                        <div className="course-tab-list">
                            <Tab.Container defaultActiveKey="curriculum">
                                <Nav className="flex-column" onClick={scrollToView}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="curriculum">
                                            {t("add_content")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="instructor">Instructor</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="curriculum" className="curriculum-tab">
                                        <br></br>
                                        <div className="course-curriculum">
                                            <div
                                                class="btn-group btn-breadcrumb"
                                                style={{
                                                    border: "1px solid #d3d3d3",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <a class="btn btn-default">
                                                    <i class="fas fa-house-user fa-lg"></i>
                                                </a>
                                                <Breadcrumb />
                                            </div>
                                            <div className="items"> {itemsCourse}</div>
                                        </div>
                                        {getToggleAddContent ? (
                                            <div className="d-flex justify-content-between shadow-sm rounded" style={{ border: '1px solid #80808024', padding: '10px' }}>
                                                <section>
                                                    <h5 style={{ textAlign: 'center', fontWeight: 'bold', position: 'relative' }}>
                                                        <span className="text-success">Add Library Content</span>
                                                        <span className="text-secondary">
                                                            <i className="fas fa-hand-point-right ml-1" style={{ fontSize: '25px' }}></i>
                                                        </span>
                                                        <span style={{ position: 'absolute', left: '40.5%', bottom: '-4px', width: '30%', height: '2px', backgroundColor: '#28a745' }}></span>
                                                    </h5>
                                                </section>
                                                <section>
                                                    <button className="create-button" onClick={toggleCourseDriveModal}>
                                                        <i className="fa fa-file"></i>Add Content
                                                    </button>
                                                </section>
                                                {/* <section>
                                                    <button className="create-button" >
                                                        <i className="fas fa-folder-plus"></i>Assignment
                                                    </button>
                                                </section> */}
                                                {/* <section>
                                                    <button className="create-button" disabled>
                                                        <i className="fab fa-youtube"></i>YouTube Video Link
                                                    </button>
                                                </section> */}
                                                {/* <section>
                                                    <button className="create-button" disabled>
                                                        <i className="fa fa-file-video-o"></i>External Link
                                                    </button>
                                                </section> */}
                                            </div>
                                        ) : ''}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="instructor" className="instructor-tab">
                                        <h5>{t("course_instructor")}</h5>
                                        <div className="instructor-box">
                                            <div className="instructor-item">
                                                <Row>
                                                    {/* <Col md='4'> */}
                                                    <div className="instructor-img">
                                                        <img
                                                            src={`${headerState.img}${headerState.id}`}
                                                            alt=""
                                                            className="img-fluid"
                                                            style={{ width: "110px" }}
                                                        />
                                                    </div>
                                                    {/* </Col> */}
                                                    <Col md="8">
                                                        <div className="instructor-content">
                                                            <div className="instructor-box">
                                                                <div className="top-content ">
                                                                    {/* d-flex justify-content-between */}
                                                                    <div className="instructor-name">
                                                                        <h6>{UserService.getUsername()}</h6>
                                                                        <p>{getCourseDetails.inst_profile}</p>
                                                                    </div>
                                                                    <div className="instructor-social">
                                                                        <ul className="social list-unstyled list-inline">
                                                                            <li
                                                                                className="list-inline-item"
                                                                                style={{ margin: 5, marginTop: 10 }}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        "https://www.facebook.com/" +
                                                                                        getUserDetails.facebookId
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <i className="fab fa-facebook-f"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item"
                                                                                style={{ margin: 5, marginTop: 10 }}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        "https://www.twitter.com/" +
                                                                                        getUserDetails.twitterId
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <i className="fab fa-twitter"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item"
                                                                                style={{ margin: 5, marginTop: 10 }}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        "https://www.linkedin.com/in/" +
                                                                                        getUserDetails.linkedinId
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <i className="fab fa-linkedin-in"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item"
                                                                                style={{ margin: 5, marginTop: 10 }}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        "https://www.youtube.com/" +
                                                                                        getUserDetails.youtubeId
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <i className="fab fa-youtube"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="instructor-desk">
                                                                    {/* <p>A Software Engineer is an IT professional who designs, develops and maintains computer software at a company. They use their creativity and technical skills and apply the principles of software engineering to help solve new and ongoing problems for an organization.</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Container>
                    {selectedContent && (
                        <ContentReviews
                            selectedContent={selectedContent}
                            setselectedContent={setselectedContent}
                            contentRatingModalShow={contentRatingModalShow}
                            setContentRatingModalShow={setContentRatingModalShow}
                        />
                    )}
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

            <PreviewModal
                show={getUrlModal?.show}
                onHide={UrlModalHide}
                contentType={getContentType}
                url={getUrl}
                videoEncodeStatus={videoEncodeStatus}
            />

            <CourseDriveModal
                show={courseDriveModal}
                onHide={toggleCourseDriveModal}
                items={items}
                getContentDetails={getContentDetails}
                columns={columns}
                filteredItems={filteredItems}
                subHeaderComponent={subHeaderComponent}
                handleRowSelected={handleRowSelected}
                selectedRows={selectedRows}
                startDate={startDate}
                setStartDate={setStartDate}
                ExampleCustomInput={ExampleCustomInput}
                AddContentToCourseStructure={AddContentToCourseStructure}
                newArr={newArr}
                getCourseStructureFolderId={getCourseStructureFolderId}
                getCourseStructureJson={getCourseStructureJson}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                folderData={folderData}
                setFolderData={setFolderData}
                findNodeById={findNodeById}
                openModal={openModal}
                ContentModal={ContentModal}
                Breadcrumb={Breadcrumb}
                setToggle={setToggle}
                isLibrary={getLibrary}
            />
            {/* update folder or Content details model code start here */}
            <UpdateFolderOrContentDetailsModal
                getFolderOrContentDetUpdate={getFolderOrContentDetUpdate}
                UpdateContentOrFolderModalHide={UpdateContentOrFolderModalHide}
                getPdate={getPdate}
                setPdate={setPdate}
                changeBackgroundOut={changeBackgroundOut}
                changeBackgroundOver={changeBackgroundOver}
                updateContentOrModule={() => updateContentOrModule(getFolderOrContentDetUpdate?.id)}
                errorMsg={errorMsg}
                category={"library"}
                handleAccessTypeChange={handleAccessTypeChange}

            />
            <DrapAndDropModal
                dragModalOpen={dragModalOpen}
                handleDragModalClose={handleDragModalClose}
                handleDragEnd={handleDragEnd}
                structure={structure}
                onCourseStructureUpdate={onCourseStructureUpdate}
            />
            {/* Add youtube link in Course Structure code start here */}
        </div>
    );
}

export default InstLibraryDetails;
