import React, { useEffect, useState } from 'react'
import UpdateCourseSchedule from './UpdateCourseSchedule'
import UpdateCourseDuration from './UpdateCourseDuration'
import UpdateUserCourseDuration from './UpdateUserCourseDuration'
import HeaderTwo from '../../../components/HeaderTwo'
import { BreadcrumbBox } from '../../../components/common/Breadcrumb'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Container } from 'react-bootstrap'

const UpdateCourseData = () => {

    const { type, courseId } = useParams();

    const [breadcrumbTitle, setBreadcrumbTitle] = useState("");
    const [updateType, setUpdateType] = useState("");

;
;


    useEffect(() => {
        if (type === "ExtendCourseDuration") {
            setBreadcrumbTitle("Extend Course Duration");
        } else if (type === "ExtendLearnerDuration") {
            setBreadcrumbTitle("Extend Learner Duration");
        } else if (type === "UpdateCourseSchedule") {
            setBreadcrumbTitle("Update Course Schedule")
        }
        setUpdateType(type);
    }, [type]);

    return (
        <div className='main-course-data-update-page'>

            <HeaderTwo />

            <BreadcrumbBox title={breadcrumbTitle} />
            <Container>

                <div className='course-data-update-area'>
                    {updateType === "UpdateCourseSchedule" &&
                        <UpdateCourseSchedule
                            courseId={courseId}
                        />
                    }
                    {updateType === "ExtendCourseDuration" &&
                        <UpdateCourseDuration
                            courseId={courseId}
                        />
                    }
                    {updateType === "ExtendLearnerDuration" &&
                        <UpdateUserCourseDuration
                            courseId={courseId}
                        />
                    }
                </div>
            </Container>
        </div>
    )
}

export default UpdateCourseData