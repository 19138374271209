import React, { useState, useEffect } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from '../../components/FooterTwo';
import { Styles } from "./styles/AddCourseCategory.js"
import { CKEditor } from 'ckeditor4-react';
import './styles/createcourse.css'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import instructorService from '../../services/instructorService';
import UserService from "../../services/UserService";
import service from "../../services/service";
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import useNonce from '../../helper/Nonce.js';

const CloneCourse = () => {

    const { t } = useTranslation()
    const history = useHistory();
    const fetchAndSetNonce = useNonce();
    const [processing, setProcessing] = useState(false);
    const [getCourseOwner, setCourseOwner] = useState(false);

    const [serverTime, setServerTime] = useState();
    const [successCourse, setSuccessCourse] = useState("");

    const [allCourses, setAllCourses] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [courseName, setCourseName] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [enrolStartDate, setEnrolStartDate] = useState('');
    const [enrolEndDate, setEnrolEndDate] = useState('');
    const [commenceDate, setCommenceDate] = useState('');

    const [selectCourseError, setSelectCourseError] = useState("");
    const [courseNameError, setCourseNameError] = useState("");
    const [publishDateError, setPublishDateError] = useState("");
    const [enrolStartDateError, setEnrolStartDateError] = useState("");
    const [enrolEndDateError, setEnrolEndDateError] = useState("");
    const [commenceDateError, setCommenceDateError] = useState("");



    useEffect(() => {
        UserService.generateToken();

        instructorService.getServerTime()
            .then(res => {
                let serTime = res.data;
                setServerTime(serTime.replace(/\s/g, 'T'))
            })

    }, []);

    // useEffect(() => {
    //     instructorService.getServerTime()
    //         .then(res => {
    //             let serTime = res.data;
    //             setServerTime(serTime.replace(/\s/g, 'T'))
    //         })
    // }, [])

    let userId = UserService.getUserid();
    let roleId = 2;


    useEffect(() => {
        service.getCoursesByUserIdAndRoleId(userId, roleId)
            .then(res => {
                setAllCourses(res.data);
            }).catch(err => {

            })
    }, [])

    const handleSelectCourse = (courseId) => {
        setSelectedCourseId(courseId);
    }

    const onBlurCourseSelect = () => {
        if (!selectedCourseId) {
            setSelectCourseError('Please select a course');
        } else {
            setSelectCourseError('');
        }
    }

    const courseNameHandler = (courseName) => {
        setCourseName(courseName);
    }

    const onBlurCourseName = () => {
        if (courseName === "") {
            setCourseNameError(t("enter_c_name"));
        } else if (courseName.length < 5) {
            setCourseNameError("Name must be of minimum 5 characters");
        } else if (courseName.length > 100) {
            setCourseNameError("Name must be of maximum 100 characters");
        } else if (!courseName.match(/^[A-Za-z0-9 ]{2,100}$/)) {
            setCourseNameError(t('aplha_digit_max_100'))
        } else {
            setCourseNameError("");
        }
    }

    const publishDateHandler = (publishDate) => {
        setPublishDate(publishDate);
    }

    const onBlurPublishDate = () => {
        if (!publishDate) {
            setPublishDateError(`${t('publish_date')}`);
        } else if (publishDate >= serverTime) {
            setPublishDateError('');
        } else {
            setPublishDateError(`${t("valid_pub_date")}`);
        }
    }

    const enrolStartDateHandler = (startDate) => {
        setEnrolStartDate(startDate);
    }

    const onBlurEnrolStartDate = () => {
        if (!enrolStartDate) {
            setEnrolStartDateError(t("enrol_start"));
        } else if (enrolStartDate > publishDate) {
            setEnrolStartDateError('');
        } else {
            setEnrolStartDateError(t('valid_start_date'));
        }
    }

    const enrolEndDateHandler = (endDate) => {
        setEnrolEndDate(endDate);
    }

    const onBlurEnrolEndDate = () => {
        if (!enrolEndDate) {
            setEnrolEndDateError(t("enter_enrollment_end_date"));
        } else if (enrolEndDate > publishDate && enrolEndDate >= enrolStartDate) {
            setEnrolEndDateError('');
        } else {
            setEnrolEndDateError(t("enter_valid_enrollment_date"));
        }
    }

    const commenceDateHandler = (commenceDate) => {
        setCommenceDate(commenceDate);
    }

    const onBlurCommenceDate = () => {
        if (!commenceDate) {
            setCommenceDateError(t("enter_commencement_date"));
        } else if (commenceDate > publishDate && enrolStartDate < commenceDate) {
            setCommenceDateError('');
        } else {
            setCommenceDateError(t("enter_valid_commencement_date"))
        }
    }

    const toDateTime = (dateFormat) => {
        var date = new Date(dateFormat);
        var str = '';
        var year, month, day, hour, min, sec;
        year = date.getFullYear();
        month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : month;
        day = date.getDate();
        day = day < 10 ? '0' + day : day;
        hour = date.getHours();
        hour = hour < 10 ? '0' + hour : hour;
        min = date.getMinutes();
        min = min < 10 ? '0' + min : min;
        sec = date.getSeconds();
        sec = sec < 10 ? '0' + sec : sec;

        str += year + '-' + month + '-' + day;
        str += ' ' + hour + ':' + min + ':' + sec;
        return str;
    }



    const submitButton = () => {
        return (
            <>
                {
                    (processing === true) ?
                        <button disabled className="action-button" style={{ borderRadius: '5px' }} >
                            <div class="spinner-border" role="status" style={{ margin: '5px', marginTop: "3px" }}>
                                <span class="sr-only">{t('loading')}</span>
                            </div>{t('submit')}
                        </button>
                        :
                        (successCourse == "") ? (
                            <button
                                disabled={
                                    selectCourseError ||
                                    courseNameError ||
                                    publishDateError ||
                                    enrolStartDateError ||
                                    enrolEndDateError ||
                                    commenceDateError ||
                                    !selectedCourseId ||
                                    !courseName ||
                                    !publishDate ||
                                    !enrolStartDate ||
                                    !enrolEndDate ||
                                    !commenceDate
                                }
                                style={{ borderRadius: '5px' }}
                                className="next action-button"
                            >{t('submit')}
                            </button>
                        ) : null
                }
            </>
        )
    }

    const handleSubmit =async (e) => {
        e.preventDefault();
        await fetchAndSetNonce();
        setProcessing(true)

        instructorService.isCourseOwner(userId, selectedCourseId).then((res) => {
            setCourseOwner(res.data)


            if (res.data) {

                instructorService.createClone(selectedCourseId, courseName, toDateTime(publishDate), toDateTime(enrolStartDate), toDateTime(enrolEndDate), toDateTime(commenceDate), userId)
                    .then(async res => {
                        if (res.status === 400) {
                            alert(t('check_field_all'));
                            await swal(`${t('warning')}`, t('check_field_all'), "success")
                        }
                        if (/^\d+$/.test(res.data)) {
                            setProcessing(true);
                            // history.push(`${process.env.PUBLIC_URL + "/ViewCourses"}`);
                            instructorService.contentClone(res.data, selectedCourseId, userId, courseName)
                                .then(async res => {

                                    if (res.data == "Course, Course Structure and Contents Cloned Successfully") {
                                        await swal({
                                            title: `${t('success')}`,
                                            text: `${res.data}`,
                                            icon: "success",
                                        }).then(() => {

                                            // if(getVlabsInfo == 'Yes'){
                                            //     service.AddCourseIntoVlabs(courseId , getState.courseName).then(resp=>{

                                            //       })
                                            //       .catch(err =>{

                                            //       })
                                            //   }
                                            // Redirect to View Courses page after onClick of Ok
                                            history.push(`${process.env.PUBLIC_URL + "/ViewCourses"}`)
                                        });
                                        setProcessing(false);
                                        setSuccessCourse(res.data);
                                    }
                                    else {
                                        await swal(`${t('error')}`, `${res.data}`, "error")
                                        setProcessing(false);
                                        setSuccessCourse(res.data);
                                    }
                                })
                        } else {

                            setSuccessCourse(res.data);
                            setProcessing(false);
                            await swal(`${t('error')}`, `${res.data}`, "error")
                        }
                    }).catch(err => {

                        setProcessing(false)
                    })
            } else {
                swal('Unable to Clone!', "You're not the Owner of Course.", "warning");
                setProcessing(false)
            }
        }).catch((err) => {
            swal(`${t('error')}`, err.response.data, "error");
        })


    }




    return (
        <Styles>
            <div className='main-wrapper registration-page'>

                <HeaderTwo />

                <BreadcrumbBox title="Clone Course" />

                <section className="registration-area">
                    <Container>
                        <div className="registration-box" style={{ maxWidth: "1200px" }}>
                            <h3 id='heading'>{t('clone_a_published_course')}</h3>
                            <p style={{ textAlign: 'center' }}>Select the course to be cloned</p>
                            <form onSubmit={handleSubmit} className="form" id="msform">
                                <fieldset>
                                    <div class="form-card">
                                        <p className="form-control">
                                            <label for="name">Select Published Course to Clone : </label>
                                            <select
                                                id="categoryId"
                                                className="form-select selectpicker h-100"
                                                value={selectedCourseId}
                                                onChange={(e) => handleSelectCourse(e.target.value)}
                                                onBlur={onBlurCourseSelect}
                                            >
                                                <option value="">Select a course</option>
                                                {allCourses.map((course) => (
                                                    <option key={course.id} value={course.id.courseId}>
                                                        {course.courseDetails.courseName}
                                                    </option>
                                                ))}
                                            </select>
                                            {selectCourseError && <p className="form-control text-left text-danger">{selectCourseError}</p>}
                                        </p>
                                        <p className="form-control">
                                            <label class="control-label" for="name">{t('course_name')} ({t('maximun_100_character')}) : </label>
                                            <input
                                                className="form-control"
                                                minLength={5}
                                                maxLength={200}
                                                id="courseName"
                                                name="courseName"
                                                type="courseName"
                                                placeholder={`${t('enter')} ${t('course_name')}`}
                                                value={courseName}
                                                onChange={(e) => courseNameHandler(e.target.value)}
                                                onBlur={() => onBlurCourseName()}
                                            />
                                            {courseNameError && <p className="form-control text-left text-danger">{courseNameError}</p>}
                                        </p>
                                        <p className="form-control">
                                            <label class="control-label">{t('publish_date')} : </label>
                                            <a style={{ cursor: 'pointer', color: '#007bff' }}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('publish_desc')}</Tooltip>}>
                                                    <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                                                </OverlayTrigger>
                                            </a>
                                            <input
                                                type="datetime-local"
                                                class="form-control"
                                                min={publishDate?.length === 0 ? '' : publishDate}
                                                value={publishDate}
                                                onChange={(e) => publishDateHandler(e.target.value)}
                                                onBlur={() => onBlurPublishDate()}
                                                placeholder={`${t('enter')} ${t('publish_date')}`}
                                            />
                                            {publishDateError && <p className="form-control text-left text-danger">{publishDateError}</p>}
                                        </p>

                                        <p className="form-control">
                                            <label class="control-label">{t('enrolment_start')} : </label>
                                            <a style={{ cursor: 'pointer', color: '#007bff' }}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('enrolment_desc')}</Tooltip>}>
                                                    <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                                                </OverlayTrigger>
                                            </a>
                                            <input
                                                type="datetime-local"
                                                class="form-control"
                                                min={publishDate?.length === 0 ? '' : publishDate}
                                                value={enrolStartDate}
                                                onChange={(e) => enrolStartDateHandler(e.target.value)}
                                                onBlur={() => onBlurEnrolStartDate()}
                                                placeholder={`${t('enter')} ${t('enrolment_start')}`}
                                            />
                                            {enrolStartDateError && <p className="form-control text-left text-danger">{enrolStartDateError}</p>}
                                        </p>


                                        <p className="form-control">
                                            <label class="control-label">{t('enrolment_end')} : </label>
                                            <a style={{ cursor: 'pointer', color: '#007bff' }}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('enrolment_end_desc')}</Tooltip>}>
                                                    <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                                                </OverlayTrigger>
                                            </a>
                                            <input
                                                type="datetime-local"
                                                class="form-control"
                                                min={publishDate?.length === 0 ? '' : publishDate}
                                                value={enrolEndDate}
                                                onChange={(e) => enrolEndDateHandler(e.target.value)}
                                                onBlur={() => onBlurEnrolEndDate()}
                                                placeholder={`${t('enter')} ${t('enrolment_end')}`}
                                            />
                                            {enrolEndDateError && <p className="form-control text-left text-danger">{enrolEndDateError}</p>}
                                        </p>


                                        <p className="form-control">
                                            <label class="control-label">{t('commencement_date')} : </label>
                                            <a style={{ cursor: 'pointer', color: '#007bff' }}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('commencement_date_desc')}</Tooltip>}>
                                                    <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                                                </OverlayTrigger>
                                            </a>
                                            <input
                                                type="datetime-local"
                                                class="form-control"
                                                min={publishDate?.length === 0 ? '' : publishDate}
                                                value={commenceDate}
                                                onChange={(e) => commenceDateHandler(e.target.value)}
                                                onBlur={() => onBlurCommenceDate()}
                                                placeholder={`${t('enter')} ${t('commencement_date')}`}
                                            />
                                            {commenceDateError && <p className="form-control text-left text-danger">{commenceDateError}</p>}
                                        </p>


                                    </div>
                                </fieldset>
                                <div className="registration-box" style={{ maxWidth: "1200px", border: '0px', boxShadow: 'none' }}>
                                    {submitButton()}
                                    {/* {successCourse == "Course Created Successfully !!" ? null : previousButton()} */}
                                </div>
                            </form>
                        </div>
                    </Container>
                </section>

            </div>

        </Styles>
    )
}

export default CloneCourse