import React, { useState } from 'react';
import "./ContentDeliverySidebar.css";
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Truncate from 'react-truncate';
import { URI } from '../../../../services/service';
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';


const ContentDeliverySidebar = ({
    mergedCourseStructure,
    sideBarOpen,
    setSideBarOpen,
    selectedContent,
    handleNodeClick,
    tenantId,
}) => {

;

    const [expandedFolder, setExpandedFolder] = useState(null);
    const [totalDuration, setTotalDuration] = useState(0);

    const handleFolderClick = (folderId) => {
        if (expandedFolder === folderId) {
            setExpandedFolder(null);
        } else {
            setExpandedFolder(folderId);
        }
    };

    const sumOfAllContentDuration = (nodes) => {
        let totalDuration = 0;
        nodes.forEach(node => {
            if (node.nodetype === "folder") {
                totalDuration += sumOfAllContentDuration(node.nodes);
            } else {
                if (node.nodetype !== "assignment" && node.nodetype !== "finalfeedback" && node.nodetype !== "feedback" && node.nodetype !== "finalQuiz" && node.nodetype !== "practiceQuiz") {
                    totalDuration += node.duration ?? 0;
                }
            }
        });
        return totalDuration;
    };

    const calculateFolderCompletionPercentage = (nodes) => {
        let totalNodes = 0;
        let completedNodes = 0;

        const traverseNodes = (nodeList) => {
            for (const node of nodeList) {
                totalNodes++;
                if (node.activityProgress === 100) {
                    completedNodes++;
                }
                if (node.nodes && node.nodes.length > 0) {
                    traverseNodes(node.nodes);
                }
            }
        };

        traverseNodes(nodes);

        if (totalNodes === 0) {
            return 0; // Avoid division by zero
        }

        return (completedNodes / totalNodes) * 100; // Return percentage
    };

    const renderIcons = (node) => {
;
        let icon;
        switch (node.nodetype) {
            case 'youtube':
                icon = <i className="fa fa-youtube-play text-danger fa-lg  " aria-hidden="true"></i>;
                break;
            case 'vlabs':
                icon = <i className="fa fa-link text-primary fa-lg " aria-hidden="true"></i>;
                break;
            case 'mp4':
                icon = <i className="fa fa-video-camera text-success fa-lg" aria-hidden="true"></i>;
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
                icon = <i className="fa fa-picture-o text-images-color fa-lg" aria-hidden="true"></i>;
                break;
            case 'html':
                icon = <i className="fab fa-html5 fa-lg text-html-color " aria-hidden="true"></i>;
                break;
            case 'scorm':
                icon = <i className="fa fa-file-archive-o text-dark fa-lg" aria-hidden="true"></i>;
                break;
            case 'zip':
                icon = <i className="fa fa-file-archive-o text-zip-color fa-lg" aria-hidden="true"></i>;
                break;
            case 'practiceQuiz':
            case 'finalQuiz':
            case 'pretestQuiz':
                icon = <i className="fa fa-question text-warning fa-lg" aria-hidden="true"></i>;
                break;
            case 'pdf':
                icon = <i className="fa fa-file-pdf-o text-danger fa-lg" aria-hidden="true"></i>;
                break;
            case 'streaming':
                icon = <i className="fa fa-file-video-o text-info fa-lg" aria-hidden="true"></i>;
                break;
            case 'externalLink':
                icon = <i className="fa fa-external-link text-info fa-lg" aria-hidden="true"></i>;
                break;
            case 'assignment':
                icon = <i className="fa fa-book fa-lg text-info fa-lg" aria-hidden="true"></i>;
                break;
            case 'feedback':
                icon = <i className="fa fa-commenting fa-lg text-success fa-lg" aria-hidden="true"></i>;
                break;
            case 'finalfeedback':
                icon = <i className="fa fa-commenting fa-lg text-success fa-lg" aria-hidden="true"></i>;
                break;
            default:
                icon = <i className="fa fa-file fa-lg" aria-hidden="true"></i>;
        }

        return icon;
    }


    const renderContents = (nodes, folderId) => {
        if (!nodes || !expandedFolder || folderId !== expandedFolder) return null;

        let previousNode = null;

        return (
            <div className={`contents ${expandedFolder === folderId ? 'show' : ''}`}>
                {nodes.map(node => {
                    const oldNode = previousNode;
                    previousNode = node;

                    return (
                        <div
                            key={node.id}
                            className={`${selectedContent?.id === node.id ? "content-node-selected" : "content-node"}`}
                            onClick={() => {
                                handleNodeClick(node);
                                // callUpdateContentOutTimeToggler();
                            }}
                        >
                            <div className='content-icon'>
                                {renderIcons(node)}
                            </div>
                            <div className="content-details">
                                <div className="content-label">
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id="" style={{ fontSize: '18px' }}>
                                                {node.label}
                                            </Tooltip>
                                        }
                                    >
                                        <span id='label-name'>
                                            <Truncate
                                                lines={1}
                                                width={150}
                                                ellipsis={"...."}
                                            >
                                                {node.label}
                                            </Truncate>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                                {node.nodetype !== "feedback" && node.nodetype !== "assignment" && node.nodetype !== "practiceQuiz" && node.nodetype !== "finalQuiz" ?
                                    <>
                                        <div className="content-duration">{node.duration} mins</div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <div className="content-progressbar">

                                {(oldNode?.activityProgress < 100 && node?.activityProgress !== 100) ? <i className="fa fa-lock fa-lg ml-2 text-dark" aria-hidden="true"></i> :

                                    <CircularProgressbar
                                        value={node.activityProgress}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: "butt",
                                            pathColor: "#f0ad4e",
                                        })}
                                    />
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };


    const renderFolders = (nodes) => {
;
        return nodes?.filter(node => node.nodetype === "folder" || node.nodetype === "pretestQuiz" || node.nodetype === "finalQuiz" || node.nodetype === "finalfeedback").map(node => {
            const isSelected = expandedFolder === node.id;

            if (node.nodetype === "folder") {
                return (
                    <div
                        key={node.id}
                        className={`folder-and-contents`}
                    >
                        <div
                            className={`folder-node ${isSelected ? 'selected' : ''}`}
                            onClick={() => {
                                handleFolderClick(node.id);
                                // callUpdateContentOutTimeToggler();
                            }}
                        >
                            <Row style={{ marginTop: "-3px" }}>
                                <Col xs={8} lg={8}>
                                    <i className={`${isSelected ? 'fa fa-folder-open' : 'fa fa-folder'}`} aria-hidden="true"></i>
                                    <span className="folder-label">
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip
                                                    id=""
                                                    style={{ fontSize: '18px' }}
                                                >
                                                    {node.label}
                                                </Tooltip>
                                            }
                                        >
                                            <span id='label-name'>
                                                <Truncate
                                                    lines={1}
                                                    width={150}
                                                    ellipsis={"...."}
                                                >
                                                    {node.label}
                                                </Truncate>
                                            </span>
                                        </OverlayTrigger>
                                    </span>
                                </Col>
                                <Col xs={2} lg={2}>
                                    <span className="folder-duration">{sumOfAllContentDuration(node.nodes)} mins</span>
                                </Col>
                                <Col xs={2} lg={2} className="folder-progressbar">
                                    <CircularProgressbar
                                        value={calculateFolderCompletionPercentage(node.nodes).toFixed(2)}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: "butt",
                                            pathColor: "#f0ad4e",
                                        })}
                                    />
                                </Col>
                                {/* <Col xs={2} lg={2}>
                                    <span className={`dropdown-icon ${expandedFolder === node.id ? "expanded" : ""}`}>
                                        <i className={`fa fa-chevron-circle-down`}></i>
                                    </span>
                                </Col> */}
                            </Row>
                        </div>
                        <div className={`content-delivery-sidebar-folder-contents ${expandedFolder === node.id ? 'show' : ''}`}>
                            {expandedFolder === node.id && renderContents(node.nodes, node.id)}
                        </div>
                    </div>
                );
            } else if (node.nodetype === "finalfeedback") {
                return (
                    <div
                        key={node.id}
                        className="content-node non-folder-node"
                        onClick={() => {
                            handleNodeClick(node);
                        }}

                    >
                        <div className='content-icon'>
                            {renderIcons(node)}
                        </div>
                        <span className="final-feedback-label" style={{ width: "74%" }}>
                            {node.label}
                        </span>
                        <div className="non-folder-progressbar" >
                            <CircularProgressbar
                                value={node.activityProgress}
                                strokeWidth={50}
                                styles={buildStyles({
                                    strokeLinecap: "butt",
                                    pathColor: "#f0ad4e",
                                })}
                            />
                        </div>
                    </div>
                );
            }
            else if (node.nodetype === "pretestQuiz" || node.nodetype === "finalQuiz") {
                return (
                    <div
                        key={node.id}
                        className="content-node non-folder-node"
                        onClick={() => {
                            handleNodeClick(node);
                        }}
                    >
                        <span className="quiz-label">
                            {node.label}
                        </span>
                        <div className="content-progressbar" >
                            <CircularProgressbar
                                value={node.activityProgress}
                                strokeWidth={50}
                                styles={buildStyles({
                                    strokeLinecap: "butt",
                                    pathColor: "#f0ad4e",
                                })}
                            />
                        </div>
                    </div>
                );
            }

            return null;
        });
    };



    return (
        <div id='content-delivery-sidebar-main' className={`content-delivery-sidebar ${sideBarOpen ? "open" : ""}`}>
            <div id='content-delivery-sidebar-main' className='sidebar-contents'>
                {renderFolders(mergedCourseStructure?.nodes)}
            </div>
        </div>
    );
}

export default ContentDeliverySidebar