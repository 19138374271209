import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { BreadcrumbBox } from '../../components/common/Breadcrumb'
import FooterTwo from '../../components/FooterTwo'
import HeaderTwo from '../../components/HeaderTwo'
import { Styles } from './styles/dashborad.js'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import UserService from '../../services/UserService'
import service from '../../services/service'
import { useMemo } from 'react'
import FilterDataTable from '../instructor/FilterDataTable'
import DataTable from "react-data-table-component";
import Table from 'react-bootstrap/Table';
import learnerService from '../../services/learnerService'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import ProgressBar from '@ramonak/react-progress-bar';


import cookies from 'js-cookie'
import { Circles } from 'react-loader-spinner'
import { LearnerCourseWiseContentWatchStatus } from './DashboardModals.js'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]


const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};




function Dashborad() {
    useEffect(() => {
        UserService.generateToken();
    }, []);
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    const [getTimeDur, setTimeDur] = useState([]);
    const [getContentTimeDur, setContentTimeDur] = useState([]);
    let userId = UserService.getUserid();

    let data1 = getTimeDur.map((d) => {
        return d.time + "," + d.month + "," + d.year
    })

    var arr1 = [];
    var month = [];
    var myStringArray = data1;
    var arrayLength = myStringArray.length;
    for (var i = 0; i < arrayLength; i++) {
        var minutes = [];
        var hms = myStringArray[i];
        var b = hms.split(',');
        b.shift();
        var lastItem = b.pop();
        var a = hms.split(':');
        if (b == "1") {
            b = `Jan, ${lastItem}`
        } else if (b == "2") {
            b = `Feb, ${lastItem}`
        } else if (b == "3") {
            b = `Mar, ${lastItem}`
        } else if (b == "4") {
            b = `Apr, ${lastItem}`
        } else if (b == "5") {
            b = `May, ${lastItem}`
        } else if (b == "6") {
            b = `Jun, ${lastItem}`
        } else if (b == "7") {
            b = `Jul, ${lastItem}`
        } else if (b == "8") {
            b = `Aug, ${lastItem}`
        } else if (b == "9") {
            b = `Sep, ${lastItem}`
        } else if (b == "10") {
            b = `Oct, ${lastItem}`
        } else if (b == "11") {
            b = `Nov, ${lastItem}`
        } else if (b == "12") {
            b = `Dec, ${lastItem}`
        }
        month.push(b);
        minutes.push((+a[0]) * 60 + (+a[1]), b.toString())
        arr1.push(minutes)
    }

    let value = 10
    const courseProgress = [
        [value, 'Completed'],
        [100 - value, 'Not Completed'],

    ]

    const [moreDetailsDiv, setMoreDetailsDiv] = useState({
        condition: false,
        setID: '',
        courseName: '',
    });
    const [selectedState, setSelectedState] = useState();
    const reportType = [
        {
            id: 4,
            label: t('content_access_log'),
            value: t('content_access_log'),
        },
        {
            id: 3,
            label: t('time_spent_report'),
            value: t('time_spent_report'),
        }
    ]
    const defaultLeanerData = {


        reportType: reportType,
        selectedReport: null,


    }
    const [getDetails, setDetails] = useState(defaultLeanerData)

    const onSelectHandler = (selectedReport) => {
        // setSelectedState(e.target.value);
        // setMoreDetailsLoading({ isLoading: false });
        setDetails(prevState => ({
            ...prevState,
            selectedReport
        }))
    }

    /*********************** GET LIST OF ENROLLED COURSE ********************/

    useEffect(() => {
        getEnrolledCourseList();
        getUserCourseProgress();
    }, [])

    const [coursesLoader, setCoursesLoader] = useState(false);
    const [courseProgressLoader, setCourseProgressLoader] = useState(false);
    const [mergedDataLoader, setMergedDataLoader] = useState(false);
    const [showMoreDetails, hideMoreDetails] = useState(false)
    const [showSummary, hideSummary] = useState(false)

    const [enrolledCourseList, setEnrolledCourseList] = useState([]);
    const getEnrolledCourseList = () => {
        setCoursesLoader(true);
        service.getUserEnrolledCourses(UserService.getUserid(), 1).then((resp) => {
            setEnrolledCourseList(resp.data);

            setCoursesLoader(false);
        }).catch((err) => {
            setCoursesLoader(false);
        })
    }
    const toggleMoreDetails = () => {
        hideMoreDetails(prevState => !prevState)
    }
    const toggleSummary = () => {
        hideSummary(prevStat => !prevStat)
    }

    const enrolledCourseListColumn = [
        {
            name: 'S No.',
            selector: (row, index) => index + 1,
            width: '100px'
        },
        {
            name: 'Course Name',
            selector: row => row.courseDetails.courseName,
        },
        // {
        //     name: 'Course Progress',
        //     selector: row => row.progress + "%",
        // },
        {
            name: 'Course Progress',
            selector: row => row.progress,
            sortable: true,
            wrap: true,
            width: '300px',
            cell: (row) => (
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProgressBar
                                completed={row.progress || 1}
                                baseBgColor="#e0e0de"
                                maxCompleted={100}
                                bgColor={row.progress === 100 ? "#11B67A" : "#ffae4a"}
                                height='20px'
                                width='100%'
                                animateOnRender={true}
                                isLabelVisible={true}
                                transitionTimingFunction={'ease-in-out'}
                                labelColor='#ffffff'
                                labelSize='10px'
                            />
                        </Col>
                    </Row>
                </Container>
            ),
        },

        {
            name: 'Starting Date',
            selector: row => <>
                {convertTimestampToDate(row.courseStartDate)}
            </>,
        },
        {
            name: 'Closing Date',
            selector: row => <>
                {convertTimestampToDate(row.courseDetails.courseClosingDate)}
            </>,
        },
        {
            name: 'Action',
            cell: row => <>
                <Button
                    onClick={() => { toggleMoreDetails(); onClickMoreDetails(row.id.courseId, row.courseDetails.courseName) }}
                    // disabled={moreDetailsLoading.isLoading ? "true" : ""}
                    variant='success'
                    style={{ whiteSpace: "nowrap" }}>

                    {"More Details"}
                </Button>

                <Button
                    onClick={() => {
                        toggleSummary();
                        onClickGetContentSummary(row.id.courseId);
                        // setSelectedCourse(row.courseDetails);
                    }}
                    variant='info'
                    className='ml-2'
                    style={{ whiteSpace: "nowrap" }}>
                    Summary Report
                </Button>
            </>
        }

    ]

    const [moreDetailsLoading, setMoreDetailsLoading] = useState({
        isLoading: false
    })

    const onClickMoreDetails = (id, courseName) => {
        setMoreDetailsDiv({
            ...moreDetailsDiv,
            condition: true,
            setID: id,
            courseName: courseName,
        })
        setMoreDetailsLoading({ isLoading: true });
        setTimeSpendReportShow(false);
        setContentAccessLogShow(false);
        if (selectedState) {
            setMoreDetailsLoading({ isLoading: false });
            document.getElementById('select').value = 'Choose'
            setSelectedState('');
            setTimeSpendDateRange({
                ...timeSpendDateRange,
                fromTimeSpendDate: '',
                toTimeSpendDate: '',
            })
            setContentDateRange({
                ...contentDateRange,
                fromContentDateRange: '',
                toContentDateRange: '',
            })
            setTimeSpentReportData([]);
            setContentAccessLogData([]);
            setMoreDetailsLoading({ isLoading: false });
        }
        resetError();
    }

    const userContentColumn = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.cname,
            width: "350px"
        },
        // {
        //     name: "Progress",
        //     selector: row => row.activityProgress,
        //     width: "150px"

        // },
        {
            name: "Progress",
            selector: row => row.activityProgress,
            sortable: true,
            wrap: true,
            width: '300px',
            cell: (row) => (
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProgressBar
                                completed={row.activityProgress || 1}
                                baseBgColor="#e0e0de"
                                maxCompleted={100}
                                bgColor={row.activityProgress === 100 ? "#11B67A" : "#ffae4a"}
                                height='20px'

                                animateOnRender={true}
                                isLabelVisible={true}
                                transitionTimingFunction={'ease-in-out'}
                                labelColor='#ffffff'
                                labelSize='10px'
                            />
                        </Col>
                    </Row>
                </Container>
            ),

        },
        {
            name: "Type",
            selector: row => row.contentType,
            width: "150px"
        },
        {
            name: "Published Date",
            selector: row => convertTimestampToDate(row.publishDate),
            width: "250px"

        },
        {
            name: "Last Accessed on",
            selector: row => convertTimestampToDate(row.lastAccessed),
            width: "250px"
        },
        {
            name: "Status",
            selector: row => row.activityStatus,
            width: "150px"
        }
    ];

    const [userCourseContentReportList, setuserCourseContentReportList] = useState([]);
    const [filterTextContentSummary, setFilterTextContentSummary] = useState("");
    const [showSpinner, setSpinner] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState("");

    const [showCourseSummary, setShowCourseSummary] = useState(false);
    const [userCourseProgressList, setUserCourseProgressList] = useState([]);
    const [mergedCourseList, setMergedCourseList] = useState([]);

    const getUserCourseProgress = () => {
        setCourseProgressLoader(true);
        service.getUserCourseProgress(userId)
            .then((res) => {
                setUserCourseProgressList(res.data)
                setCourseProgressLoader(false);
            })
            .catch((err) => {
                setCourseProgressLoader(false);
            })
    }




    useEffect(() => {
        setMergedDataLoader(true);
        if (enrolledCourseList?.length && userCourseProgressList?.length) {
            const mergedList = mergeCourseData(enrolledCourseList, userCourseProgressList);
            setMergedCourseList(mergedList);

        }
        setMergedDataLoader(false);
    }, [enrolledCourseList, userCourseProgressList]);

    const onClickGetContentSummary = (courseId) => {
        setSelectedCourseId(courseId);
        setShowCourseSummary(true);
        setuserCourseContentReportList([]);
    }


    const mergeCourseData = (courses, progress) => {
        return courses.map((course) => {
            const progressData = progress.find(
                (progressItem) => progressItem.courseId === course.id.courseId
            );
            return {
                ...course,
                progress: progressData ? progressData.progress : null,
                progressStatus: progressData ? progressData.progressStatus : null,
                lastAccessed: progressData ? progressData.lastAccessed : null,
                certDownloadStatus: progressData ? progressData.certDownloadStatus : null,
            };
        });
    };
    const userCourseContentReportfilteredList = userCourseContentReportList?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterTextContentSummary.toLowerCase()) !== -1
    );


    const convertTimestampToDate = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    const contentSummarySearchBox = useMemo(() => {
        const handleClear = () => {
            if (filterTextContentSummary) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterTextContentSummary("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterTextContentSummary(e.target.value)}
                onClear={handleClear}
                filterTextContentSummary={filterTextContentSummary}
            />
        );
    }, [filterTextContentSummary]);

    /************************END OF GET LIST OF ENROLLED COURSE**************/

    const quizReportcolumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Exam Name",
            selector: row => row.examname,
            sortable: true,
            wrap: true,
        },
        {
            name: "Start Date",
            selector: row => row.startdate,
            sortable: true,
            wrap: true
        },
        {
            name: "End Date",
            selector: row => row.enddate,
            sortable: true,
            wrap: true
        },
        {
            name: "Marks",
            selector: row => row.marks,
            sortable: true,
            wrap: true
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            wrap: true
        }
    ];

    const assignmentReportColumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Assign ID",
            selector: row => row.assignid,
            sortable: true,
            wrap: true,
        },
        {
            name: "Submission Date",
            selector: row => row.submissiondate,
            sortable: true,
            wrap: true
        },
        {
            name: "Marks",
            selector: row => row.marks,
            sortable: true,
            wrap: true
        },
        {
            name: "Remarks",
            selector: row => row.remarks,
            sortable: true,
            wrap: true
        }
    ];

    const timeSpentColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Type",
            selector: row => row.resTitle,

        },
        {
            name: "Time Spent (HH:MM:SS)",
            selector: row => row.spentTime,
            sortable: true,
            wrap: true
        },
    ];

    const contentAccessColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Type",
            selector: row => row.resTitle,
            sortable: true,
            wrap: true,
        },
        {
            name: "Start Time",
            selector: row => row.inTime,
            sortable: true,
            wrap: true
        },
        {
            name: "End Time",
            selector: row => row.outTime,
            sortable: true,
            wrap: true
        }
    ];

    const activityLogColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Activity",
            selector: row => row.action,
            sortable: true,
            wrap: true,
        },
        {
            name: "Browser",
            selector: row => row.config_browser,
        },
        {
            name: "IP Address",
            selector: row => row.ip_address,
        },
        {
            name: "Operating System",
            selector: row => row.config_os,
            sortable: true,
            wrap: true
        },
        {
            name: "Screen Resolution",
            selector: row => row.config_resolution,
            sortable: true,
            wrap: true
        },
        {
            name: "Loging Time",
            selector: row => row.visit_last_action_time,
            sortable: true,
            wrap: true

        },
        {
            name: "Logout Time",
            selector: row => row.logout,
            sortable: true,
            wrap: true
        },
    ];

    const [getQuizReportData, setQuizReportData] = useState([
        {
            "id": 1,
            "examname": "Java Quiz",
            "startdate": "2022-10-12T09:46:00.000+05:30",
            "enddate": "2022-10-12T09:46:00.000+05:30",
            "marks": "20",
            "status": "Completed",

        },
    ])

    const [getAssignmentReportData, setAssignmentReportData] = useState([
        {
            "id": 1,
            "assignid": 123,
            "submissiondate": "2022-10-12T09:46:00.000+05:30",
            "marks": "20",
            "remarks": "Do Better",
        },
    ])

    const [getTimeSpentReportData, setTimeSpentReportData] = useState([]);
    const [timeSpendReportShow, setTimeSpendReportShow] = useState(false);
    const [timeSpendDateRange, setTimeSpendDateRange] = useState({
        fromTimeSpendDate: '',
        toTimeSpendDate: '',
    });
    const timeSpendHandler = (e) => {
        setTimeSpendDateRange({
            ...timeSpendDateRange,
            [e.target.name]: e.target.value
        })
    }
    const [timeSpendDateRangeError, setTimeSpendDateRangeError] = useState({
        fromDateError: '',
        toDateError: '',
    });

    const timeSpendDateRangeValidate = () => {
        let fromDateErr = ''
        let toDateErr = ''

        if (!timeSpendDateRange.fromTimeSpendDate) {
            fromDateErr = t('date_required')
        }
        if (!timeSpendDateRange.toTimeSpendDate) {
            toDateErr = t('date_required')
        }

        if (timeSpendDateRange.fromTimeSpendDate && timeSpendDateRange.toTimeSpendDate) {
            const from = new Date(timeSpendDateRange.fromTimeSpendDate).getTime();
            const to = new Date(timeSpendDateRange.toTimeSpendDate).getTime();
            if (from > to) {
                fromDateErr = t('should_be_less_than_todate')
            }
        }
        if (fromDateErr || toDateErr) {
            setTimeSpendDateRangeError({
                ...timeSpendDateRangeError,
                fromDateError: fromDateErr,
                toDateError: toDateErr,
            })
            return false
        }
        return true
    }

    const resetError = () => {
        setTimeSpendDateRangeError({
            ...timeSpendDateRangeError,
            fromDateError: '',
            toDateError: '',
        })

        setContentDateRangeError({
            ...contentDateRangeError,
            fromDateError: '',
            toDateError: '',
        })

        setActivityLogDateRangeError({
            ...activityLogDateRangeError,
            fromDateError: '',
            toDateError: '',
        })
    }


    const [onClickSubmit, setOnClickSubmit] = useState({
        isLoading: false
    })

    const onClickTimeSpend = () => {

        setMoreDetailsLoading({ isLoading: false });

        const condition = timeSpendDateRangeValidate();
        if (condition) {
            setOnClickSubmit({ isLoading: true });
            learnerService.getLearnerTimeSpend(userId, moreDetailsDiv.setID, timeSpendDateRange.fromTimeSpendDate, timeSpendDateRange.toTimeSpendDate).then((resp) => {
                setTimeSpendReportShow(true);
                setTimeSpentReportData(resp.data);
                resetError();

                setOnClickSubmit({ isLoading: false });
            }).catch((err) => {

            })
        }
    }


    const [getContentAccessLogData, setContentAccessLogData] = useState([]);
    const [contentAccessLogShow, setContentAccessLogShow] = useState(false);
    const [contentDateRange, setContentDateRange] = useState({
        fromContentDateRange: '',
        toContentDateRange: '',
    })

    const contentDateRangeHandler = (e) => {
        setContentDateRange({
            ...contentDateRange,
            [e.target.name]: e.target.value,
        })
    }

    const [contentDateRangeError, setContentDateRangeError] = useState({
        fromDateError: '',
        toDateError: '',
    });

    const contentDateRangeValidate = () => {
        let fromDateErr = ''
        let toDateErr = ''

        if (!contentDateRange.fromContentDateRange) {
            fromDateErr = t('Date_required')
        }
        if (!contentDateRange.toContentDateRange) {
            toDateErr = t('should_be_less_than_todate')
        }

        if (contentDateRange.fromContentDateRange && contentDateRange.toContentDateRange) {
            const from = new Date(contentDateRange.fromContentDateRange).getTime();
            const to = new Date(contentDateRange.toContentDateRange).getTime();
            if (from > to) {
                fromDateErr = t('')
            }
        }
        if (fromDateErr || toDateErr) {
            setContentDateRangeError({
                ...contentDateRangeError,
                fromDateError: fromDateErr,
                toDateError: toDateErr,
            })
            return false
        }
        return true
    }


    const [onClickContentSubmit, setOnClickContentSubmit] = useState({
        isLoading: false
    })

    const onClickContentRange = () => {

        const condition = contentDateRangeValidate();
        if (condition) {
            setOnClickContentSubmit({ isLoading: true });
            setContentAccessLogShow(true);
            learnerService.getLearnerContentAccessLog(userId, moreDetailsDiv.setID, contentDateRange.fromContentDateRange, contentDateRange.toContentDateRange).then((resp) => {
                setContentAccessLogShow(true);
                setContentAccessLogData(resp.data);
                resetError();
                setOnClickContentSubmit({ isLoading: false });

            }).catch((err) => {
                resetError();
                setOnClickContentSubmit({ isLoading: false });
            })
        }

    }


    const [activityLogTableShow, setActvityLogTableShow] = useState(false);
    const [getAccessLogData, setAccessLogData] = useState([]);
    const [activityLogDateRange, setActivityLogDateRange] = useState({
        fromDate: '',
        toDate: ''
    })

    const dateHandler = (e) => {
        setActivityLogDateRange({
            ...activityLogDateRange,
            [e.target.name]: e.target.value
        })
    }

    const [activityLogDateRangeError, setActivityLogDateRangeError] = useState({
        fromDateError: '',
        toDateError: '',
    });

    const activityLogDateRangeValidate = () => {
        let fromDateErr = ''
        let toDateErr = ''

        if (!activityLogDateRange.fromDate) {
            fromDateErr = t('date_required')
        }
        if (!activityLogDateRange.toDate) {
            toDateErr = t('date_required')
        }

        if (activityLogDateRange.fromDate && activityLogDateRange.toDate) {
            const from = new Date(activityLogDateRange.fromDate).getTime();
            const to = new Date(activityLogDateRange.toDate).getTime();
            if (from > to) {
                fromDateErr = t('should_be_less_than_todate')
            }
        }
        if (fromDateErr || toDateErr) {
            setActivityLogDateRangeError({
                ...activityLogDateRangeError,
                fromDateError: fromDateErr,
                toDateError: toDateErr,
            })
            return false
        }
        return true
    }


    const [onClickSubmitActivity, setOnClickSubmitActivity] = useState({
        isLoading: false
    })

    const onClickSubmitActivityLog = () => {

        //console.log(UserService.getUserid())

        const condition = activityLogDateRangeValidate();
        if (condition) {
            setOnClickSubmitActivity({ isLoading: true });
            setActvityLogTableShow(true);
            const userId = UserService.getUserid();
            learnerService.getLearnerActivityLog(userId, activityLogDateRange.fromDate, activityLogDateRange.toDate)
                .then((resp) => {
                    setAccessLogData(resp.data);
                    resetError();
                    setOnClickSubmitActivity({ isLoading: false });
                }).catch((err) => {

                })
        }
    }




    /* Table content Filter and Search */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const quizfilteredItems = getQuizReportData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const assignmentfilteredItems = getAssignmentReportData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const timespentfilteredItems = getTimeSpentReportData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const contentAccessfilteredItems = getContentAccessLogData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const accesslogfilteredItems = getAccessLogData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);





    return (

        <Styles>

            {/* Main Wrapper */}
            <div className="main-wrapper dashboard-page">

                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title={t('learner_dashboard')} />

                <section className="dashboard-page-area">
                    <div className='m-1 p-1 m-lg-5 p-lg-5'>
                        {
                            coursesLoader && (
                                <div
                                    style={{
                                        position: 'fixed', // Ensures the div stays on top of other content
                                        top: 0,
                                        left: 0,
                                        width: '100vw', // Covers the entire viewport width
                                        height: '100vh', // Covers the entire viewport height
                                        backgroundColor: 'rgba(255, 255, 255, 0.562)', // Semi-transparent background
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center', // Centers the loading indicator vertically
                                        alignItems: 'center', // Centers the loading indicator horizontally
                                        zIndex: 999, // Ensures the div is on top of other elements
                                    }}
                                >
                                    <Circles
                                        height={40}
                                        width={40}
                                        color="#4fa94d"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            )
                        }
                        <Row>
                            <Col lg="12" className='mt-3'>
                                <h4 style={{ textAlign: 'center', fontWeight: 'bold', position: "relative" }}>
                                    <span className='text-success'>Enrolled </span>
                                    <span className='text-secondary'>Course List</span>
                                    <span style={{ position: 'absolute', left: "45.5%", bottom: "-3px", width: '6%', height: '2px', backgroundColor: '#28a745' }}></span>
                                </h4>
                                <div className="card-body mt-4" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>

                                    <br></br>
                                    <DataTable
                                        columns={enrolledCourseListColumn}
                                        data={mergedCourseList}
                                        defaultSortAsc={true}
                                        striped
                                        pagination
                                        highlightOnHover
                                        customStyles={customStyles}
                                    // subHeader
                                    // subHeaderComponent={subHeaderComponent}

                                    />
                                </div>
                            </Col>


                            <Col lg="12 mt-5">
                                <h4 style={{ textAlign: 'center', fontWeight: 'bold', position: 'relative' }}>
                                    <span className='text-success'>ACTIVITY </span>
                                    <span className='text-secondary'>LOG</span>
                                    {/* <span style={{ position: 'absolute', left: "47%", bottom: "-3px", width: '8%', height: '3px', backgroundColor: '#40b740' }}></span> */}
                                    <span style={{ position: 'absolute', left: "47.5%", bottom: "-3px", width: '6%', height: '2px', backgroundColor: '#28a745' }}></span>

                                </h4>
                                <div className="card my-3 p-3 w-100" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>
                                    <div className='d-flex d-xm-block align-items-center w-100 p-3'>
                                        <div className='flex-grow mx-1 p-2  '>
                                            <label class="control-label" for="name">{t('from_date')} : </label>
                                            <input type="date" class="form-control p-3" step="1" id="publishDate" name="fromDate" value={activityLogDateRange.fromDate} onChange={dateHandler} />
                                            {
                                                activityLogDateRangeError.fromDateError
                                                    ?
                                                    <div className="alert alert-danger mt-2">{activityLogDateRangeError.fromDateError}</div>
                                                    :
                                                    <></>
                                            }

                                        </div>


                                        <div className='flex-grow mx-1 p-2'>
                                            <label class="control-label" for="name">{t('to_date')} : </label>
                                            <input type="date" class="form-control p-3" title="Select Course Publish Date" step="1" id="enrollSdate" name="toDate" value={activityLogDateRange.toDate} onChange={dateHandler} />
                                            {
                                                activityLogDateRangeError.toDateError
                                                    ?
                                                    <div className="alert alert-danger mt-2">{activityLogDateRangeError.toDateError}</div>
                                                    :
                                                    <></>
                                            }
                                        </div>

                                        <div className='mr-3'>
                                            <Button style={{ marginTop: "25px" }} variant='success' onClick={onClickSubmitActivityLog} disabled={onClickSubmitActivity.isLoading ? "true" : ""}>  {onClickSubmitActivity.isLoading ? (<>{t('loading')}</>) : <>{t('submit')}</>} </Button>
                                        </div>

                                    </div>

                                    {
                                        activityLogTableShow
                                            ?
                                            <>
                                                <div className="card-body">
                                                    <DataTable
                                                        columns={activityLogColumns}
                                                        // title="Access Log"
                                                        data={accesslogfilteredItems}
                                                        defaultSortField="Name"
                                                        defaultSortAsc={true}
                                                        noDataComponent={true}
                                                        striped
                                                        pagination
                                                        highlightOnHover
                                                        customStyles={customStyles}
                                                        subHeader
                                                        subHeaderComponent={subHeaderComponent}
                                                    />
                                                </div>

                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>
                            </Col>







                        </Row>
                    </div>
                </section>
                {/* Footer 2 */}
                <FooterTwo />
                {/* More details modal */}
                <Modal centered show={showMoreDetails} onHide={() => toggleMoreDetails()} backdrop="static" size="xl" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                            <h5 style={{ alignContent: "center" }}>{moreDetailsDiv.courseName}</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className='p-2'>
                            {moreDetailsDiv.condition ?
                                <>


                                    <div>
                                        <label id='#'>Report Type : </label>
                                        <Select
                                            value={getDetails?.selectedReport}
                                            onChange={onSelectHandler}
                                            options={getDetails?.reportType}
                                            placeholder="Select Report Type..."

                                        />
                                    </div>
                                </> : ''
                            }


                            {
                                getDetails?.selectedReport?.id === 1 ?
                                    <div className="card">

                                        <div className="card-body">
                                            <DataTable
                                                columns={quizReportcolumns}
                                                title="Quiz Report"
                                                data={quizfilteredItems}
                                                defaultSortField="Name"
                                                defaultSortAsc={true}
                                                striped
                                                pagination
                                                highlightOnHover
                                                customStyles={customStyles}
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            />
                                        </div>
                                    </div>
                                    : getDetails?.selectedReport?.id === 2 ? <div className="card">
                                        <div className="card-body">
                                            <DataTable
                                                columns={assignmentReportColumns}
                                                title="Assignment Report"
                                                data={assignmentfilteredItems}
                                                defaultSortField="Name"
                                                defaultSortAsc={true}
                                                striped
                                                pagination
                                                highlightOnHover
                                                customStyles={customStyles}
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            />
                                        </div>
                                    </div> : getDetails?.selectedReport?.id === 3
                                        ?
                                        <>

                                            <div className="card my-3 p-3" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>

                                                <h5 style={{ textAlign: 'center' }}>{t('time_spend_report')}</h5>
                                                {/* start here */}
                                                <div className='d-flex d-xm-block align-items-center w-100 p-3'>
                                                    <div className='flex-grow mx-1 p-2  '>
                                                        <label class="control-label" for="name">{t('from_date')} :</label>
                                                        <input type="date" class="form-control" step="1" id="publishDate" name="fromTimeSpendDate" value={timeSpendDateRange.fromTimeSpendDate} onChange={timeSpendHandler} />
                                                        {
                                                            timeSpendDateRangeError.fromDateError
                                                                ?
                                                                <div className="alert alert-danger mt-2">{timeSpendDateRangeError.fromDateError}</div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>


                                                    <div className='flex-grow mx-1 p-2'>
                                                        <label class="control-label" for="name">{t('to_date')} : </label>
                                                        <input type="date" class="form-control" title={t('select_course_publish_date')} step="1" id="enrollSdate" name="toTimeSpendDate" value={timeSpendDateRange.toTimeSpendDate} onChange={timeSpendHandler} />
                                                        {
                                                            timeSpendDateRangeError.toDateError
                                                                ?
                                                                <div className="alert alert-danger mt-2">{timeSpendDateRangeError.toDateError}</div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>

                                                    <div className='mr-3'>

                                                        <Button style={{ marginTop: "25px" }} variant='success' onClick={onClickTimeSpend} disabled={onClickSubmit.isLoading ? "true" : ""}> {onClickSubmit.isLoading ? (<>{t('loading')}</>) : <>{t('submit')}</>} </Button>
                                                    </div>

                                                </div>

                                                <br></br>
                                                {
                                                    timeSpendReportShow
                                                        ?
                                                        <>
                                                            <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                                                                <DataTable
                                                                    columns={timeSpentColumns}
                                                                    data={timespentfilteredItems}
                                                                    defaultSortField="Name"
                                                                    defaultSortAsc={true}
                                                                    striped
                                                                    pagination
                                                                    highlightOnHover
                                                                    customStyles={customStyles}
                                                                    subHeader
                                                                    subHeaderComponent={subHeaderComponent}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                            </div>
                                        </>

                                        : getDetails?.selectedReport?.id === 4
                                            ?
                                            <>
                                                <div className="card my-3 p-3" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>

                                                    <h5 style={{ textAlign: 'center' }}>{t('content_access_log')} dsfadsf</h5>

                                                    {/* start here */}
                                                    <div className='d-flex d-xm-block align-items-center w-100 p-3'>
                                                        <div className='flex-grow mx-1 p-2  '>
                                                            <label class="control-label" for="name">{t('from_date')} : </label>
                                                            <input type="date" class="form-control" step="1" id="publishDate" name="fromContentDateRange" value={contentDateRange.fromContentDateRange} onChange={contentDateRangeHandler} />
                                                            {
                                                                contentDateRangeError.fromDateError
                                                                    ?
                                                                    <div className="alert alert-danger mt-2">{contentDateRangeError.fromDateError}</div>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>


                                                        <div className='flex-grow mx-1 p-2'>
                                                            <label class="control-label" for="name">{t('to_date')} : </label>
                                                            <input type="date" class="form-control" title={t('select_course_publish_date')} step="1" id="enrollSdate" name="toContentDateRange" value={contentDateRange.toContentDateRange} onChange={contentDateRangeHandler} />
                                                            {
                                                                contentDateRangeError.toDateError
                                                                    ?
                                                                    <div className="alert alert-danger mt-2">{contentDateRangeError.toDateError}</div>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                        <div className='mr-3'>
                                                            <Button style={{ marginTop: "25px" }} variant='success' onClick={onClickContentRange} disabled={onClickContentSubmit.isLoading ? "true" : ""} >  {onClickContentSubmit.isLoading ? (<> {t('loading')}</>) : <>{t('submit')}</>} </Button>
                                                        </div>

                                                    </div>

                                                    <br></br>
                                                    {
                                                        contentAccessLogShow
                                                            ?
                                                            <>
                                                                <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                                                                    <DataTable
                                                                        columns={contentAccessColumns}
                                                                        data={contentAccessfilteredItems}
                                                                        defaultSortField="Name"
                                                                        defaultSortAsc={true}
                                                                        striped
                                                                        pagination
                                                                        highlightOnHover
                                                                        customStyles={customStyles}
                                                                        subHeader
                                                                        subHeaderComponent={subHeaderComponent}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </div>
                                            </>
                                            : getDetails?.selectedReport?.id === 5 ? <div className="card">
                                                <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                                                    <DataTable
                                                        columns={activityLogColumns}
                                                        //title="Activity Log"
                                                        data={accesslogfilteredItems}
                                                        defaultSortField="Name"
                                                        defaultSortAsc={true}
                                                        striped
                                                        pagination
                                                        highlightOnHover
                                                        customStyles={customStyles}
                                                        subHeader
                                                        subHeaderComponent={subHeaderComponent}
                                                    />
                                                </div>
                                            </div>
                                                :
                                                <>
                                                    <div className="card my-3 p-3" style={{ border: "1px solid #f1ecec", borderRadius: "8px", pointerEvents: "auto", opacity: "0.5", cursor: "not-allowed", backgroundColor: "#f1ecec" }}>

                                                        <h5 style={{ textAlign: 'center' }}>Report</h5>

                                                        {/* start here */}
                                                        <div className='d-flex d-xm-block align-items-center w-100 p-3'>
                                                            <div className='flex-grow mx-1 p-2  '>
                                                                <label class="control-label" for="name">{t('from_date')} : </label>
                                                                <input type="date" class="form-control" step="1" id="publishDate" name="fromContentDateRange" value={contentDateRange.fromContentDateRange} onChange={contentDateRangeHandler} />
                                                                {
                                                                    contentDateRangeError.fromDateError
                                                                        ?
                                                                        <div className="alert alert-danger mt-2">{contentDateRangeError.fromDateError}</div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>


                                                            <div className='flex-grow mx-1 p-2'>
                                                                <label class="control-label" for="name">{t('to_date')} : </label>
                                                                <input type="date" class="form-control" title={t('select_course_publish_date')} step="1" id="enrollSdate" name="toContentDateRange" value={contentDateRange.toContentDateRange} onChange={contentDateRangeHandler} />
                                                                {
                                                                    contentDateRangeError.toDateError
                                                                        ?
                                                                        <div className="alert alert-danger mt-2">{contentDateRangeError.toDateError}</div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>

                                                            <div className='mr-3'>
                                                                <Button style={{ marginTop: "25px" }} variant='success' onClick={onClickContentRange} disabled={onClickContentSubmit.isLoading ? "true" : ""} >  {onClickContentSubmit.isLoading ? (<> {t('loading')}</>) : <>{t('submit')}</>} </Button>
                                                            </div>

                                                        </div>

                                                        <br></br>
                                                        {
                                                            contentAccessLogShow
                                                                ?
                                                                <>
                                                                    <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                                                                        <DataTable
                                                                            columns={contentAccessColumns}
                                                                            data={contentAccessfilteredItems}
                                                                            defaultSortField="Name"
                                                                            defaultSortAsc={true}
                                                                            striped
                                                                            pagination
                                                                            highlightOnHover
                                                                            customStyles={customStyles}
                                                                            subHeader
                                                                            subHeaderComponent={subHeaderComponent}
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </div>
                                                </>
                            }


                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "right" }}>
                        <Button variant="danger" onClick={toggleMoreDetails}>
                            {t('cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Summary report modal */}
                {/* <Modal centered show={showSummary} onHide={() => toggleSummary()} backdrop="static" size="xl" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                            <h5 style={{ textAlign: 'center', fontWeight: 'bold', position: "relative" }}>
                                <span className='text-success'>Content </span>
                                <span className='text-secondary'>Details</span>
                                <span style={{ position: 'absolute', left: "38.5%", bottom: "-3px", width: '30%', height: '2px', backgroundColor: '#28a745' }}></span>
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            userCourseContentReportList.length > 0 && (
                                <div className="card my-3 p-3" style={{ border: "1px solid #f1ecec", borderRadius: "8px" }}>
                                    <div className="card-body" style={{ borderBlock: "3px solid green" }}>
                                        <DataTable
                                            columns={userContentColumn}
                                            // title="Content Summary"
                                            data={userCourseContentReportfilteredList}
                                            defaultSortField="Name"
                                            defaultSortAsc={true}
                                            striped
                                            pagination
                                            highlightOnHover
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={contentSummarySearchBox}

                                        />
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "right" }}>
                        <Button variant="danger" onClick={toggleSummary}>
                            {t('cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal> */}

            </div>

            <LearnerCourseWiseContentWatchStatus
                userId={userId}
                courseId={selectedCourseId}
                modalStatus={showCourseSummary}
                setModalStatus={setShowCourseSummary}

            />
        </Styles>

    )
}
export default Dashborad