import { Form, Tabs } from "react-bootstrap";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }

  .nav-item {
    margin-bottom: -2px;
    
  }

   .nav-link {    
    border: 1px solid transparent;
    border-radius: 0.25rem 0.25rem 0 0;
    color: green;
    background-color: transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.15s ease-in-out;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #009444;
      transition: all 0.3s ease;
    }

    &:hover {
      color: #009444;
      border-color: transparent;

      &::after {
        left: 0;
        width: 100%;
      }
    }

    &.active {
      color: #009444;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;

      &::after {
        left: 0;
        width: 100%;
      }
    }
  }
`;

export const TabContent = styled.div`
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0 0 0.25rem 0.25rem;
`;



export const StyledFileInput = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: white;
  padding: 1%;
  border-radius : 5px;
  input[type="file"] {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #c5ccd378;
   
    margin-right: 0.5%;
  }

  .custom-file-upload {
    border: 1px solid #ced4da;
    display: inline-block;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    background-color: #fff;
    color: #495057;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: #e9ecef;
      border-color: #ced4da;
    }
  }
  .create-button {
    background:  linear-gradient(90deg, #007bc7 0%, #003994 100%);
    color: white;
    font-size: 15px;
    font-weight: lighter;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 3px;
    i {
        margin-right: 8px;
    }
    &:hover{
        background:  linear-gradient(90deg, #5865f283 0%, #009445 100%);
    }
}

`;
