import React, { useEffect, useState } from 'react';
import StickyMenu from '../../components/common/StickyMenu';
import instructorService from '../../services/instructorService';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import { Col } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import UserService from '../../services/UserService';
import CryptoJS from "crypto-js";
import { COURSE_URL, URI } from '../../services/service';
import './styles/AdminViewCourses.css';  // Import updated custom styles
import { Circles } from 'react-loader-spinner';
import GlobalModal from '../shared/GlobalModal';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
];

function AdminViewCourses(props) {
    useEffect(() => {
        UserService.generateToken();
    }, []);

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    const history = useHistory();

    const [postsPerPage] = useState(10);
    const [getCourseData, setCourseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);

    const imageUrls = (url) => {
        if (url == null) {
            return '';
        } else {
            let imagepath = url.replace(/\\/g, "\\\\");
            let imageurl = `${URI.SERVER?._COURSE_URL}/${imagepath}`;
            return imageurl;
        }
    }

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentCourseState = getCourseData.slice(0, 1000);

    const usersPerPage = 8;
    const pagesVisited = pageNumber * usersPerPage;

    const pageCount = Math.ceil(currentCourseState.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [submitHandlerLoading, setSubmitHandlerLoading] = useState({
        isLoading: false
    });
    const [getPageLoading, setPageLoading] = useState(false);


    const submitHandler = (courseId) => {
        setSubmitHandlerLoading({ isLoading: true });
        CourseDetails(courseId, 1);
    }

    const CourseDetails = (id, tid) => {
        const secretKey = "cdac@123";

        const encodedCourseId = CryptoJS.AES.encrypt(
            `${id}`,
            secretKey
        ).toString();
        const safeEncodedCourseId = encodedCourseId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        const encodedTenantId = CryptoJS.AES.encrypt(
            `${tid}`,
            secretKey
        ).toString();
        const safeEncodedTenantId = encodedTenantId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        history.push(`${process.env.PUBLIC_URL + "/admin-course-details/"}${safeEncodedCourseId}/${safeEncodedTenantId}`);
    }

    useEffect(() => {
        courseData();
    }, []);

    const courseData = async () => {
        setPageLoading(true)
        try {
            let result = await instructorService.getAllCourses()
            setCourseData(result.data);
            setPageLoading(false)
        } catch (e) {
            console.error(e);
            setPageLoading(false)
        }
    }

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                {
                    getPageLoading &&
                    <div className='modal-loader'>
                        <GlobalModal showModal={getPageLoading} />
                    </div>

                }
                
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">
                                {t('courses')}
                            </h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('courses')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('view_courses')}</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="row admin-view-courses-container">
                                    {currentCourseState.slice(pagesVisited, pagesVisited + usersPerPage).map((data, i) => {
                                        return (
                                            <div key={i} className='col-md-3 mb-4'>
                                                <div className="card admin-course-card">
                                                    <div className='overflow'>
                                                        <img src={imageUrls(data.imageUrl)} alt="" className="card-img-top course-img" />
                                                    </div>
                                                    <div className="card-body">
                                                        <h4 className="card-title course-title">{data.courseName}</h4>
                                                        <p className="card-text text-secondary">
                                                            <a href='#' className='btn course-btn' onClick={() => submitHandler(data.courseId)} disabled={submitHandlerLoading.isLoading ? "true" : ""}>
                                                                {submitHandlerLoading.isLoading ? t('loading') : t('course_details')}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="pagination-container">
                                    {currentCourseState.length > 0 && (
                                        <Col md="12" className="text-center">
                                            <ReactPaginate
                                                previousLabel={t('previous')}
                                                nextLabel={t('next')}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </Col>
                                    )}
                                </div>
                            </div>
                        </div>

                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AdminViewCourses;
